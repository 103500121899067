<template>
  <div id="Draw" :class="{ home: home === true, drawHome : home === true}" >
    <div v-if="home === true">
      <b-container class="homeFullWidth">
        <b-row class="homeLeader">
          <b-col>
            <b-img class="courseLogo" :src="data.event_sponsor_logo"></b-img>
            <h3 class="homeHead">{{ data.short_name }} {{ liveOn }} - {{ title
          }}</h3>
            <div class="homecourseDates">{{data.course_dates}}</div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-row v-else class="title">
      <b-col>
        <h3>
          {{ data.full_name }} - {{ title
          }}
        </h3>
      </b-col>
      <b-col
      >
      <div v-if="data.alt_heading == 'Y'">
        <span class="AltText">
          <span v-if="data.alt_heading_text_1.length">{{ data.alt_heading_text_1.length }}</span>
          <span v-if="data.alt_heading_text_2.length">{{ data.alt_heading_text_2.length }}</span>
          <span v-if="data.alt_heading_text_3.length">{{ data.alt_heading_text_3.length }}</span>
        </span>
      </div>
      </b-col>
      <b-col
      >
        <div class="sponLogo">
          <i v-b-toggle.collapse-1 class="fa-solid fa-circle-info"></i>
        </div>
      </b-col>
    </b-row>
    <div>
    <template v-if="show_info_web == 'Y'">
      <b-collapse id="collapse-1" class=" informationCLass" visible>
        <Information 
          :data="data"
          :previousData="previousData"
          :home="home"
          :title='title'
          :config="config"
          :team="team"
          :match="match"
          :code="data.code"
          :report="report"
          :live="live"
          :season="data.season"
          :takeover="takeover"
          :bbTeamEvent="data.bball_team"
          :roundsplayed="data.rounds_played"
          :showGender="showGender"
          :Stableford="Stableford"
        />
      </b-collapse>
    </template>
    <template v-else>
      <b-collapse id="collapse-1" class=" informationCLass">
        <Information 
          :data="data"
          :previousData="previousData"
          :home="home"
          :title='title'
          :config="config"
          :team="team"
          :match="match"
          :code="data.code"
          :report="report"
          :live="live"
          :season="data.season"
          :takeover="takeover"
          :bbTeamEvent="data.bball_team"
          :roundsplayed="data.rounds_played"
          :showGender="showGender"
          :Stableford="Stableford"
        />
      </b-collapse>
    </template>
    </div>
    <table>
      <thead>
        <tr v-if="home === false">
          <template v-if="tablePick === 'Draw'">
            <th v-if="data.match_play === 'Y'">
              Group
            </th>
            <th v-else>
              Match
            </th>
            <th class="Timecenter">
              Time
            </th>
            <th v-if="data.multi_course === 'Y'">
              Course
            </th>
            <th v-if="data.match_play_team === 'Y'">
              Team
            </th>
            <th>
              Tee
            </th>
            <th style="padding-left: 50px;">
              Players
            </th>
          </template>
        </tr>
      </thead>
        <template v-for="(tabledata, index) in Eds">
          <tr @click.stop="rowClicked(index)" :key="index" :class="{ trTableHome: home === true}" >
            <template v-if="tablePick === 'Draw' && data.match_play === 'N'"> 
              <td>{{tabledata[0].match}}</td>
              <td style="text-align:center">{{tabledata[0].time}}</td>
              <template v-if="data.multi_course === 'Y'">
                <td>
                  <span :class='tabledata[0].course_colour'>
                  {{ tabledata[0].course }}
                  </span>
                </td>
              </template>
              <template v-if="data.multi_course === 'Y'">
                <td class="multiTee">{{tabledata[0].tee}}</td>
              </template>
              <template v-else>
                <td>{{tabledata[0].tee}}</td>
              </template>
              <td class="PlayerPad">
                <p v-for="(player, index2) in tabledata" :key="index2" class="noMargin">

                  <template v-if="bbTeamEvent === 'Y'">
                    <div @click='toggle = !toggle' class="DrawTeamName">
                      {{ player.name }}
                    </div>
                    <div class="pairName" v-show='toggle'>
                      <span class="namePair" v-if="player.pair_1_name.length > 0">
                        {{player.pair_1_name}}
                      </span>
                      <span class="namePair" v-if="player.pair_2_name.length > 0">
                        {{player.pair_2_name}}
                      </span>
                      <span class="namePair" v-if="player.pair_3_name.length > 0">
                        {{player.pair_3_name}}
                      </span>
                      <span class="namePair" v-if="player.pair_4_name.length > 0">
                        {{player.pair_4_name}}
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="player.profile === 'Y'">
                      <a :href="'/player-profiles/' + player.playerno" target="_blank" class="linkPlayerProfile">
                        {{ player.name}}
                      </a>
                        <span v-if="player.pro_ind === 'Am'">(a)</span>
                        <span class="arrowLink">
                          <a :href="'/player-profiles/' + player.playerno" target="_blank" class="linkPlayerProfile">
                            <span class="iconRight">
                              <font-awesome-icon
                                class="icon"
                                :icon="['fa', 'chevron-right']"
                              />
                            </span>
                          </a>
                        </span>
                    </template>
                    <template v-else>
                      {{ player.name}}
                      <span v-if="player.pro_ind === 'Am'">(a)</span>
                    </template>
                  </template>
                  <span
                    v-if="showGender === 'Y'"
                    class="multiDot-size-reportgender"
                    :class="{female : player.gender === 'F', male : player.gender === 'M'}"
                  >•</span>
                </p>
              </td>
            </template>
            <template v-if="tablePick === 'Draw' && data.match_play === 'Y'">
              <td>
                {{ tabledata[0].match }}
              </td>
              <td class="centerTime">
                {{ tabledata[0].time }}
              </td>
              <td v-if="data.match_play_team == 'Y'">
                <div>
                  <span
                    class="teamcolourPad"
                    :style="
                    'background-color:' +
                    tabledata[0].tmp_colour_1
                  ">
                    {{tabledata[0].tmp_nat_1}}
                  </span>
                </div>
                <div>
                  <span
                    class="teamcolourPad"
                    :style="
                    'background-color:' +
                    tabledata[0].tmp_colour_2
                  ">{{tabledata[0].tmp_nat_2}}</span>
                </div>
              </td>
              <td class="">
                {{ tabledata[0].tee }}
              </td>
              <td class="noPadLeft">
                <div>
                  {{ tabledata[0].name_1 }}
                </div>
                <div>
                  {{ tabledata[0].name_2 }}
                </div>
              </td>
            </template>
          </tr>
        </template>
      <!-- <tbody>
        <template v-for="(tabledata, index) in Eds">
          <tr
            @click.stop="rowClicked(index)"
            :key="index"
            v-bind:class="{
              'homeTd-size': home === true,
              drawHome: home === true && tablePick === 'Draw',
            }"
          >
            <template v-if="tablePick === 'Draw' && match === 'N' && data.bball_team === 'N' && data.team_event === 'N'">
              <td
                class="posnum drawPos"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                <span>
                  {{ tabledata.match }}
                </span>
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
                class="noPos"
              >
                <span>.</span>
              </td>
              <td
                class="centerTime"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                {{ tabledata.time }}
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
              ></td>
              <template v-if="data.multi_course === 'Y'">
                <td
                  :class="{
                    odd: tabledata.match % 2 === 1,
                    even: tabledata.match % 2 === 0,
                  }"
                  v-if="tabledata.place == 1"
                >
                  {{ tabledata.course }}
                </td>
                <td
                  :class="{
                    odd: tabledata.match % 2 === 1,
                    even: tabledata.match % 2 === 0,
                  }"
                  v-else
                ></td>
              </template>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                {{ tabledata.tee }}
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
              ></td>
              <td
                class="drawName"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
              >
                <a
                  :href="'/player-profiles/' + tabledata.playerno"
                  :class="{ homePlayername: home === true }"
                >
                  {{ tabledata.name }}</a
                >
                <span v-if="tabledata.pro_ind === 'Am'">(a)</span>
                <span
                  v-if="showGender === 'Y'"
                  class="multiDot-size-reportgender"
                  :class="{female : tabledata.gender === 'F', male : tabledata.gender === 'M'}"
                  >•</span>
              </td>
            </template>
            <template v-if="tablePick === 'Draw' && data.match_play === 'N' && data.bball_team === 'Y' || data.team_event === 'Y'">
              <td
                class="posnum drawPos teamDraw"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                <span>
                  {{ tabledata.match }}
                </span>
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
                class="noPos"
              >
                <span>.</span>
              </td>
              <td
                class="centerTime teamDraw"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                <span>
                  {{ tabledata.time }}
                </span>
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
              ></td>
              <template v-if="data.multi_course === 'Y'">
                <td
                  :class="{
                    odd: tabledata.match % 2 === 1,
                    even: tabledata.match % 2 === 0,
                  }"
                  v-if="tabledata.place == 1"
                >
                  {{ tabledata.course }}
                </td>
                <td
                  :class="{
                    odd: tabledata.match % 2 === 1,
                    even: tabledata.match % 2 === 0,
                  }"
                  v-else
                ></td>
              </template>
              <td
                class="Tee"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-if="tabledata.place == 1"
              >
                <span>
                  {{ tabledata.tee }}
                </span>
              </td>
              <td
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
                v-else
              ></td>
              <td
                class="drawName"
                :class="{
                  odd: tabledata.match % 2 === 1,
                  even: tabledata.match % 2 === 0,
                }"
              >
                <div @click='toggle = !toggle' class="DrawTeamName">
                  {{ tabledata.name }}
                </div>
                <div class="pairName" v-show='toggle'>
                  <span class="namePair" v-if="tabledata.pair_1_name.length > 0">{{tabledata.pair_1_name}}</span>
                  <span class="namePair" v-if="tabledata.pair_2_name.length > 0">{{tabledata.pair_2_name}}</span>
                  <span class="namePair" v-if="tabledata.pair_3_name.length > 0">{{tabledata.pair_3_name}}</span>
                  <span class="namePair" v-if="tabledata.pair_4_name.length > 0">{{tabledata.pair_4_name}}</span>
                </div>
              </td>
            </template>
            <template v-if="tablePick === 'Draw' && data.match_play === 'Y'">
              <td>
                {{ tabledata.match }}
              </td>
              <td>
                {{ tabledata.time }}
              </td>
              <td>
                {{ tabledata.tee }}
              </td>
              <td>
                <p :class="{ homePlayername: home === true }">
                  {{ tabledata.name_1 }}
                </p>
                <p :class="{ homePlayername: home === true }">
                  {{ tabledata.name_2 }}
                </p>
              </td>
            </template>
          </tr>
        </template>
      </tbody> -->
    </table>
    <div v-if="home === true">
      <b-container class="homeFullWidth">
        <b-row class="homeLeader drawLead">
          <b-col>
            <b-nav-item
              class="fullNav"
              :to="{
                name: 'reports-page',
                query: {
                  url: this.report,
                  id: season,
                  code: code,
                  title: title,
                },
              }"
            >
              <h3 class="FullLeaderboard">Full Leaderboard</h3>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import Information from "@/components/information.vue";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import _ from 'lodash'

export default {
  name: "Draw",
  components: {
    Information
  },
  componentss: [
    lodash,
    VueLodash,
    _
  ],
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "report",
    "code",
    "season",
    "takeover",
    "config",
    "team",
    "match",
    "live",
    "RTCDS",
    "showGender",
    "bbTeamEvent",
    "show_info_web",
    "hide_score_card"
  ],
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      cardID: [],
      playerCardData: [],
      toggle: false
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClicked: function(index) {
      if (this.tablePick === "Score" || this.tablePick === "Live")
        this.clickedRow = index;
      else this.clickedRow = -1;
    },
  },
  computed: {
    tablePick: function(picked) {
      if (this.title.includes("Draw")) picked = "Draw";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry;
          // console.log("reportData")
          // console.log(reportData)
        return  _.groupBy(reportData, 'match');
      } else {
        if (this.title.includes("Draw"))
          reportData = this.data.draw.draw_entry.slice(0, 6);
        return  _.groupBy(reportData, 'match');
      }
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
/* Home Styles */
::v-deep tr:hover {
  background-color: #f6f5f5!important;
  cursor: pointer;
}
.iconRight:hover {
  color: #7ba639;
}
.homeFullWidth {
  max-width: 100%;
}
::v-deep .informationCLass {
  margin-top: 0px!important;
}
.PlayerPad {
  padding-left: 50px;
}
.linkPlayerProfile {
  color: #212529;
}
.linkPlayerProfile:hover {
  color: #7ba639;
  text-decoration: none;
}
.arrowLink {
  float: right;
  margin-right: 30px;
}
.AltText {
  display: block;
  margin-top: 12px;
}
.noPadLeft {
  padding-left: 0;
}
.teamcolourPad {
  color: #fff!important;
  padding: 1px 10px;
  text-align: center;
}
.centerTime {
  text-align: center;
}
.row.homeLeader.drawLead {
  padding-bottom: 0px!important;
}
.trTableHome > td {
  font-size: 10pt;
  padding-bottom: 5px;
  padding-top: 5px;
}
.trTableHome > td > p > a{
  color: #95c93d;
}
.trTableHome {
  background-color: #fff!important;
  color: #95c93d;
  border-top: 1px solid #95c93d!important
}
span.R {
  color:#bb0000
}
p.noMargin {
  margin-bottom: 5px;
}
td {
  vertical-align: middle;
  height: 110px;
}
span.multiDot-size-reportgender  {
  font-size: 30px!important;
  line-height: 0px !important;
  position: relative;
  top: 6px;
}
.female {
  color: #e33;
}
.male {
  color: #037
}
.DrawTeamName {
  color: #007bff;
  cursor: pointer;
}
td.Tee {
  vertical-align: top;
}
td.Tee > span {
  padding-top: 8px;
  display: inline-block;
}
td.teamDraw {
  vertical-align: top;
}
td.teamDraw > span {
  padding-top: 8px;
  display: inline-block;
}
.teamDraw {
  top: 0px!important;
}
.pairName {
  padding-top: 10px;
  padding-bottom: 10px;
}
span.namePair {
  display: block;
}
div#Draw {
  display: contents;
}
.hideRepresentsHome {
  display: none;
}
p.homePlayername {
  color: #95c93d;
}
td.homePlayername {
  color: #95c93d;
}
a.homePlayername {
  color: #95c93d;
}
a.homeResult {
  color: #95c93d !important;
}
th.representsText {
  text-align: left !important;
}
td.representsText {
  text-align: left !important;
}
tr.homeTd-size {
  background-color: #fff !important;
  color: 95c93d;
  border-top: 1px solid #95c93d !important;
}
tr.drawHome {
  border-top: 0px solid #95c93d !important;
}
tr.drawHome > td.drawPos {
  top: 0px;
}
tr.drawHome > td > a {
  color: #95c93d !important;
}
tr.homeTd-size > td {
  font-size: 11pt;
  color: #212529;
}
h3.FullLeaderboard {
  font-size: 14pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
}
.natNone {
  display: none;
}
.fullNav {
  padding-top: 0 !important;
  display: block !important;
}
.fullNav > .nav-link {
  padding: 0 1rem;
  color: white;
}
.table-responsive {
  margin-bottom: 0;
}
.courseLogo {
  width: 35%;
  padding-top: 25px;
}

.homeLeader {
  text-align: center !important;
  background-color: #95c93d !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  padding-bottom: 10px!important;
}

.homeHead {
  font-size: 11pt;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  padding-bottom: 0px;
}
.homecourseDates {
  font-size: 11pt;
  padding-bottom: 15px;
}
.homeHide {
  display: none;
}
tr:nth-child(odd) {
  background-color: white;
}

.home {
  /* width: 350px; */
  width: 300px;
  position: absolute;
  top: 30px;
  /* top: -80px;  */
  right: 30%;
}
.homeCell {
  padding: 0.75rem;
  font-size: 10pt;
}
.homeCell > a {
  color: #055498;
}
.posCell {
  width: 2%;
}
.scoreCell {
  width: 2%;
}
.sponsorHome {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
/* Main Styles */
th.PadLeft {
  padding-left: 10px;
}
img.titliestLogo {
  height: 12px;
  margin-bottom: 1px;
  margin-left: 10px;
}
th.playersName-score {
  padding-left: 10px;
}
td.centerTime {
  text-align: center;
}
th.Timecenter {
  text-align: center;
}
td.drawName > a {
  color: #000;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
  position: relative;
  top: 3px;
}
.title {
  text-align: center;
  background-color: #7ba639;
  color: white;
  margin-left: 0;
  margin-right: 0;
}
.title > .col > h3 {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize;
  background-color: #7ba639;
  text-align: left;
  font-weight: 400;
}
.tLogo {
  width: 65px;
  margin-top: -5px;
  margin-right: 15px;
}
td.catD {
  background: #95c93d;
  color: #fff;
}
.sponLogo {
  margin-top: 15px;
  float: right;
  padding-right: 27px;
  color: white;
  font-size: 12pt;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  color: white !important;
  position: sticky;
  top: 0;
  background-color: #7ba639;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: center;
}
td:nth-child(3) {
  text-align: center;
}
.odd {
  background-color: white !important;
  padding-bottom: 0;
  padding-top: 0;
}
.even {
  background-color: rgba(0,0,0,.05) !important;
  padding-bottom: 0;
  padding-top: 0;
}
tr:nth-child(even) {
  background-color: rgba(0,0,0,.05);
}
.titlest {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
td {
  font-size: 1rem;
  padding: 0.75rem;
}
td > a {
  /* color: #055498; */
  color: #000;
}
tr > :nth-child(2) {
  /* color: #337ab7; */
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 10px;
  display: block;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .home {
    /* width: 350px; */
    width: 100% !important;
    position: inherit !important;
    top: 30px;
    /* top: -80px;  */
    right: 30%;
  }
  .home > div > div {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .home {
    position: inherit;
    width: 100%;
  }
  .homeLeader {
    margin-left: -40px;
  }
}
@media only screen and (max-width: 480px) {
  .tableMob {
    width: 275%;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  ::v-deep tr > :nth-child(2) {
    font-size: 10pt;
  }
  ::v-deep th {
    font-size: 10pt;
  }
  ::v-deep td {
    font-size: 10pt;
    padding: 0.75rem;
  }
  ::v-deep .dropdown {
    width: 100%;
    padding-right: 12px;
  }
  ::v-deep .HideMovResult {
    display: none;
  }
}
</style>
