<template>
  <div class="home">
    <Ads class='mobHide' :page="'solheim'" :adNum="'top'" :mob='false' :side='false'/>
    <div id="banner">
      <b-img class="splashImg" :src="wpData.acf.top_banner"></b-img>
    </div> 

    <b-tabs nav-wrapper-class="container">
      <b-tab title="News" active>
        <b-container>
          <div id="feature">
            <Post />
          </div>
          <div>
            <a href="https://solheimcup2023.eu/" target="_blank">
              <b-img
                class="splashImg"
                :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2021/02/solhiem2023.png'"
              />
            </a>
          </div>
          <b-row class="blockTitle">
            <b-col>
              <b-button class="socialTitle">SOCIAL</b-button>
            </b-col>
          </b-row>
        </b-container>
        <div id="social">
          <b-container>
            <b-row>
              <b-col lg="4">
                <b-button class="social">TWITTER</b-button>
                <Twitter :config="config"/>
              </b-col>
              <b-col lg="4">
                <b-button class="social">INSTAGRAM</b-button>
                <InstaFeed :config="config" />
              </b-col>
              <b-col lg="4">
                <b-button class="social">FACEBOOK</b-button>
                <FaceBook :config="config"/>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div id="flickr">
          <Flickr :config="config" />
        </div>
        <div id="social">
          <b-container>
            <YouTube :id="config.VUE_APP_YOUTUBE_PLAYLIST" :config="config"/>
          </b-container>
        </div>
      </b-tab>
      <template #tabs-end>
        <b-nav-item href="http://www.solheimcup.com/" target="_blank">Solheim Cup</b-nav-item>
      </template>
      <b-tab title="Team Europe">
        <b-container>
          <div class="sTable">
            <div class="standing">
              Team Europe As It Stands
              <span class="asIt">{{ wpData.acf.as_it_stands }}</span>
            </div>
            <span v-html="wptable[0].acf.table"></span>
          </div>
          <div class="downRow">
            <b-row>
              <b-col>
                <div class="downloadC">
                  <a
                    :href="wpData.acf.download_qualification_criteria_pdf"
                    target="_blank"
                  >
                    {{ wpData.acf.download_text }}
                  </a>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                  <b-nav-item class="solLink" :href="wpData.acf.let_solheim_cup_points_link" target="_blank">
                    {{ wpData.acf.let_solheim_cup_points }}
                  </b-nav-item>
              </b-col>
              <b-col>
                <b-nav-item class="solLink" :href="wpData.acf.rolex_rankings_points_link" target="_blank">
                  {{ wpData.acf.rolex_rankings_points }}
                </b-nav-item>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </b-tab>
      <b-tab title="Event Information">
        <b-container class="eventInfo">
          <div class="eventPad">
            <b-img
              class="splashImg"
              :src="wpData.acf.event_information_image_one"
            />
          </div>
          <div class="eventPad">
            <b-img
              class="splashImg"
              :src="wpData.acf.event_information_image_two"
            />
          </div>
          <div class="eventPad">
            <span v-html="wpData.acf.event_information_block_paragraph" />
          </div>
          <div class="eventPad">
            <b-row>
              <b-col>
                <a :href="wpData.acf.event_information_href_banner_one">
                  <b-img
                    class="splashImg"
                    :src="wpData.acf.event_information_banner_one"
                  />
                </a>
              </b-col>
              <b-col>
                <a :href="wpData.acf.event_information_href_banner_one">
                  <b-img
                    class="splashImg"
                    :src="wpData.acf.event_information_image_three"
                  />
                </a>
              </b-col>
            </b-row>
          </div>
          <div class="eventPad">
            <span v-html="wpData.acf.event_information_block_paragraph_two" />
          </div>
          <div class="eventPad">
            <b-img
              class="splashImg"
              :src="wpData.acf.event_information_image_two"
            />
          </div>
        </b-container>
      </b-tab>
      <b-tab title="Event History">
        <b-container>
          <div class="sTable">
            <div class="standing">
              PREVIOUS RESULTS
            </div>
            <span v-html="wptableTwo[0].acf.table"></span>
          </div>
        </b-container>
      </b-tab>
      <b-tab title="Ping Junior SC">
        <b-container>
          <b-row>
            <b-col> </b-col>
            <b-col>
              <div class="pingSocial">
                <b-nav-item class="socailLink">
                  <font-awesome-icon class="icon" :icon="['fab', 'facebook']" />
                </b-nav-item>
                <b-nav-item class="socailLink">
                  <font-awesome-icon
                    class="icon"
                    :icon="['fab', 'instagram']"
                  />
                </b-nav-item>
              </div>
            </b-col>
          </b-row>
          <a :href="wpData.acf.pjsc_image_link">
            <b-img class="pjscLogo" :src="wpData.acf.pjsc_image"></b-img>
          </a>
          <div class="eventPad">
            <span v-html="wpData.acf.pjsc_block"></span>
          </div>
          <div class="sTable">
            <div class="standing">
              PREVIOUS RESULTS
            </div>
            <span v-html="wptableTwo[0].acf.table"></span>
          </div>
        </b-container>
      </b-tab>
    </b-tabs>
    <div class="bigLBlue">
      <b-container>
        <span class="sponsorTitle">
          <span class="partnersTitle">
            {{ wpData.acf.global_parters_title }}
          </span>
          <b-row>
            <b-col>
              <a :href="wpData.acf.global_partners_image_one_link" target="_blank">
                <b-img
                  class="ping"
                  :src="wpData.acf.global_partners_image_one"
                />
              </a>
            </b-col>
            <b-col>
              <a :href="wpData.acf.global_partners_image_two_link" target="_blank">
                <b-img
                  class="supLogo"
                  :src="wpData.acf.global_partners_image_two"
                />
              </a>
            </b-col>
            <b-col>
              <a :href="wpData.acf.global_partners_image_theree_link" target="_blank">
                <b-img
                  class="supLogo"
                  :src="wpData.acf.global_partners_image_three"
                />
              </a>
            </b-col>
          </b-row>
        </span>
      </b-container>
    </div>
    <div class="bigDBlue">
      <b-container>
        <span class="sponsorTitle">
          {{ wpData.acf.team_europe_supplier_title }}

          <b-row>
            <b-col>
              <a :href="wpData.acf.team_europe_image_one_link" target="_blank">
                <b-img
                  class="Skechers"
                  :src="wpData.acf.team_europe_image_one"
                />
              </a>
            </b-col>
            <b-col>
              <a :href="wpData.acf.team_europe_image_two_link" target="_blank">
                <b-img class="ping" :src="wpData.acf.team_europe_image_two" />
              </a>
            </b-col>
            <b-col>
              <a :href="wpData.acf.team_europe_image_theree_link" target="_blank">
                <b-img
                  class="supLogo"
                  :src="wpData.acf.team_europe_image_three"
                />
              </a>
            </b-col>
          </b-row>
        </span>
      </b-container>
    </div>
    <Ads class='mobHide' :page="'solheim'" :adNum="'foot'" :mob='false' :side='false'/>
  </div>
</template>

<script>
import Ads from "@/components/ads.vue";
import Post from "@/components/posts.vue";
import Twitter from "@/components/twitterFeed.vue";
import InstaFeed from "@/components/instaFeed.vue";
import FaceBook from "@/components/faceBook.vue";
import Flickr from "@/components/flickr.vue";
import YouTube from "@/components/youTube.vue";
import axios from "axios";
export default {
  name: "teamEurope",
  props: ["course", "season", "report", "config"],
  components: {
    Ads,
    Post,
    Twitter,
    InstaFeed,
    FaceBook,
    Flickr,
    YouTube,
  }, // Everything below on every /view/<Page Name>.vue for meta info and all ACF data
  data: function() {
    return {
      title: [], //Page Title
      description: [], //Meta Description
      homepage: true,
      posts: this.config.VUE_APP_WPAPI_URL+"wp/v2/posts?categories=6",
      sponsor: this.config.VUE_APP_WPAPI_URL+"wp/v2/",
      componentKey: 0,
      wpData: [],
      wptable: [],
      wptableTwo: [],
    };
  },
  methods: {
    onTabChanged() {
      window.location.href = 'http://www.solheimcup.com/';
    }
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/162124")
      .then((response) => {
        this.wpData = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+"wp/v2/teameurope"
        );
      })
      .then((response) => {
        this.wptable = response.data;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+"wp/v2/eventhistory"
        );
      })
      .then((response) => {
        this.wptableTwo = response.data;
      });
  },
};
</script>
<style scoped>
img.Skechers {
  margin-top: 28px;
  width: 210px;
}
.partnersTitle {
  padding-bottom: 20px;
  display: block;
}
#banner {
  overflow: hidden;
  height: 600px;
}
.splashImg {
  width: 100%;
}
::v-deep .nav-tabs {
  border-bottom: 0px solid #181797;
  background: #181797;
  padding: 10px;
  margin-top: -2.28em;
  z-index: 99;
  position: relative;
}
::v-deep .nav-tabs > li {
  background: #181797;
  margin: auto;
  padding-top: 0;
}
::v-deep .nav-tabs > li > a {
  color: #fff;
  width: 150px;
  display: block;
  font-size: 1rem;
  text-align: center;
  border-radius: 0;
  padding: 0;
}
.blockTitle {
  margin: auto;
  margin-top: 5em;
  text-align: right;
  position: relative;
  right: 0;
}

.social {
  background-color: #181797;
  border-radius: 0;
  padding: 11px 30px;
}
.socialTitle {
  background: #181797;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 0;
}

#feature {
  padding: 50px 0 50px 0;
  background-color: white;
}
#social {
  background-color: #f2f2f2;
  padding: 50px 0;
}
::v-deep .nav-item {
  display: flex;
  align-items: center;
  padding-top: 7px;
}
::v-deep .col-md-10 {
  display: flex;
  align-items: center;
  padding-top: 7px;
}
::v-deep .fa-instagram {
  color: #cc4666;
}
.title {
  color: #133f7b;
}
.bigLBlue {
  background-color: #181797;
  padding-bottom: 30px;
}
.bigDBlue {
  background-color: #002878;
  padding-bottom: 30px;
}
.sponsorTitle {
  color: #ffffff;
  text-align: center;
  display: block;
  font-size: 20pt;
  padding-top: 20px;
  padding-bottom: 20px;
}
.supLogo {
  width: 130px;
  margin-top: 10px;
}
.ping {
  margin-top: 28px;
  width: 180px;
}
.standing {
  background-color: #181797;
  color: #fff;
  padding-left: 9px;
  font-size: 18pt;
  padding-top: 8px;
  padding-bottom: 8px;
}
.asIt {
  display: block;
  font-size: 10pt;
  padding-top: 3px;
}
::v-deep table {
  width: 100%;
}
::v-deep th {
  padding: 8px;
  background-color: #ffcc03;
  font-weight: 400;
  vertical-align: middle;
  color: #181797;
  font-family: "Oswald", Helvetica, Arial !important;
  text-transform: initial;
  font-size: 1rem;
}
::v-deep tr:nth-child(even) {
  background-color: #dddddd;
}
::v-deep td {
  padding: 8px;
  border: none;
  background: 0 0;
  text-align: left;
  float: none !important;
  font-weight: 400;
  font-size: 1rem;
}
.sTable {
  padding: 50px 0;
}
.downloadC {
  background: #181897;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 1em;
}
.downloadC > a {
  color: white;
}
.solLink {
  background: #181897;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 1em;
  color: #ffffff;
}
.solLink > a {
  margin: auto;
  color: white;
}
.downRow {
  padding-bottom: 50px;
}
.eventInfo {
  padding: 50px 0;
}
.eventPad {
  padding: 25px 0;
}
.pingSocial {
  float: right;
  margin-top: 15px;
}
.pingSocial > .socailLink {
  display: inline-block;
}
.icon {
  font-size: 30px;
  color: #191996;
}
.pjscLogo {
  width: 200px;
  text-align: center;
  margin: auto;
  display: block;
  margin-top: 1em;
}
.mobHide {
  display: block;
}
.mobShow {
  display: none;
}
@media only screen and (max-width: 768px) {
  #banner{
    height: 300px;
  }
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
  }
}
@media only screen and (max-width: 425px) {
  #banner{
    height: 150px;
  }
  ::v-deep .nav-tabs {
    border-bottom: 0px solid #181797;
    background: #181797;
    padding: 10px;
    margin-top: -2.28em;
    z-index: 99;
    position: relative;
    display: grid;
  }
  ::v-deep .nav-tabs > li {
    background: #181797;
    margin: auto;
    padding-top: 0;
    width: 100%;
    text-align: center;
  }
  ::v-deep .nav-tabs > li > a {
    color: #fff;
    width: 100%;
    display: block;
    font-size: 1rem;
    text-align: center;
    border-radius: 0;
    padding: 0;
  }
  ::v-deep .eventInfo {
    padding: 15px;
  }
}

</style>
