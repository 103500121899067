var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ home: _vm.home === true, takeOver: _vm.takeover == true, Matchwidth :  _vm.data.match_play == 'Y'}},[(_vm.data.match_play == 'Y')?[(_vm.data.match_play_team == 'Y')?[_c('b-row',{staticClass:"overallSection"},[_c('b-col',{staticClass:"Left",style:('background-color:' +
          _vm.data.tmp_teamcolour_1),attrs:{"cols":"5"}},[_vm._v(_vm._s(_vm.data.tmp_teamname_1))]),(_vm.data.overall.overall_entry[0].team == 'USA')?[_c('b-col',{staticClass:"Middle",attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.data.overall.overall_entry[1].points)+" - "+_vm._s(_vm.data.overall.overall_entry[0].points))])]:[_c('b-col',{staticClass:"Middle",attrs:{"cols":"2"}},[_vm._v(_vm._s(_vm.data.overall.overall_entry[0].points)+" - "+_vm._s(_vm.data.overall.overall_entry[1].points))])],_c('b-col',{staticClass:"Right",style:('background-color:' +
          _vm.data.tmp_teamcolour_2),attrs:{"cols":"5"}},[_vm._v(_vm._s(_vm.data.tmp_teamname_2))])],2)]:void 0]:[(_vm.home === true)?_c('div',[_c('b-container',[_c('b-row',{staticClass:"homeLeader"},[_c('b-col',[_c('b-img',{staticClass:"courseLogo",attrs:{"src":_vm.data.event_sponsor_logo}}),_c('h3',{staticClass:"homeHead"},[_vm._v(_vm._s(_vm.data.short_name)+" "),(_vm.data.live_scoring == 'Y')?_c('span',[_vm._v(" - Live Scoring is on")]):_vm._e()]),_c('div',{staticClass:"homecourseDates"},[_vm._v(_vm._s(_vm.data.course_dates))])],1)],1)],1)],1):_c('b-row',{staticClass:"title"},[_c('b-col',[_c('h3',[_vm._v(" "+_vm._s(_vm.data.full_name)+" - "+_vm._s(_vm.title)+" ")])]),(
          _vm.tablePick === 'Score' || _vm.tablePick === 'Final' || _vm.tablePick === 'Live'
        )?_c('b-col',[_c('div',{staticClass:"sponLogo"},[_c('i',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticClass:"fa-solid fa-circle-info"})])]):_vm._e()],1)],(_vm.title.includes('Scoreboard'))?_vm._l((_vm.roundsArray.reverse()),function(round,index){return _c('table',{key:index},[_c('thead',{staticClass:"MPRound_Name"},[_c('th',{attrs:{"colspan":"11"}},[_vm._v(" "+_vm._s(round.round_text)+" - "+_vm._s(_vm.data['stmp_format_name_R' + round.round_no],)+" ")])]),_c('thead',[(_vm.home === false)?_c('tr',[_c('th',{staticClass:"LeftAligned"},[_vm._v(" Name ")]),_c('th',{staticClass:"centerAligned"},[_vm._v(" Score ")]),_c('th',{staticClass:"centerAligned"},[_vm._v(" Thru ")]),_c('th',{staticClass:"centerAligned"},[_vm._v(" Score ")]),_c('th',{staticClass:"RightAligned"},[_vm._v(" Name ")])]):_vm._e()]),_vm._l((_vm.Eds),function(tabledata,index){return _c('tbody',{key:index},[[(round.round_no == tabledata.round)?_c('tr',{key:index,class:{
              'homeTd-size': _vm.home === true,
              },on:{"click":function($event){$event.stopPropagation();return _vm.rowClicked(index)}}},[(
                  (_vm.tablePick === 'Score') ||
                  (_vm.tablePick === 'Final')
              )?[_c('td',{staticClass:"LeftAligned",class:{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  wineurope: tabledata.status_1 == 'W',
                  }},[(_vm.data.match_play_team == 'Y')?[_vm._v(" "+_vm._s(tabledata.name_1)+" ")]:[_vm._v(" "+_vm._s(tabledata.initials_1)+" "+_vm._s(tabledata.surname_1)+" ")]],2),(tabledata.status_1 === 'W')?_c('td',{staticClass:"centerAligned"},[_vm._v(" "+_vm._s(tabledata.full_score_1)+" ")]):_c('td',{staticClass:"centerAligned"},[(tabledata.full_score_1.includes('DOWN'))?void 0:[_vm._v(" "+_vm._s(tabledata.full_score_1)+" ")]],2),_c('td',{staticClass:"centerAligned"},[(tabledata.holes === '')?_c('span',[_vm._v(" "+_vm._s(tabledata.time)+" ")]):(tabledata.holes < 18)?_c('span',[_vm._v(" "+_vm._s(tabledata.holes)),(tabledata.tee_R1 > 1)?_c('span',[_vm._v("*")]):_vm._e()]):_c('span',[_vm._v(" F"),(tabledata.tee_R1 > 1)?_c('span',[_vm._v("*")]):_vm._e()])]),(tabledata.status_2 === 'W')?_c('td',{staticClass:"centerAligned"},[_vm._v(" "+_vm._s(tabledata.full_score_2)+" ")]):_c('td',{staticClass:"centerAligned"},[(tabledata.full_score_2.includes('DOWN'))?void 0:[_vm._v(" "+_vm._s(tabledata.full_score_2)+" ")]],2),_c('td',{staticClass:"RightAligned",class:{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  winusa: tabledata.status_2 == 'W',
                  }},[(_vm.data.match_play_team == 'Y')?[_vm._v(" "+_vm._s(tabledata.name_2)+" ")]:[_vm._v(" "+_vm._s(tabledata.initials_2)+" "+_vm._s(tabledata.surname_2)+" ")]],2)]:_vm._e()],2):_vm._e()]],2)})],2)}):_vm._l((_vm.roundsArray),function(round,index){return _c('table',{key:index},[_c('thead',{staticClass:"MPRound_Name"},[_c('th',{attrs:{"colspan":"11"}},[_vm._v(" "+_vm._s(round.round_text)+" - "+_vm._s(_vm.data['stmp_format_name_R' + round.round_no],)+" ")])]),_c('thead',[(_vm.home === false)?_c('tr',[_c('th',{staticClass:"LeftAligned"},[_vm._v(" Name ")]),_c('th',{staticClass:"centerAligned"},[_vm._v(" Score ")]),_c('th',{staticClass:"centerAligned"},[_vm._v(" Thru ")]),_c('th',{staticClass:"centerAligned"},[_vm._v(" Score ")]),_c('th',{staticClass:"RightAligned"},[_vm._v(" Name ")])]):_vm._e()]),_vm._l((_vm.Eds),function(tabledata,index){return _c('tbody',{key:index},[[(round.round_no == tabledata.round)?_c('tr',{key:index,class:{
              'homeTd-size': _vm.home === true,
              },on:{"click":function($event){$event.stopPropagation();return _vm.rowClicked(index)}}},[(
                  (_vm.tablePick === 'Score') ||
                  (_vm.tablePick === 'Final')
              )?[_c('td',{staticClass:"LeftAligned",class:{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  wineurope: tabledata.status_1 == 'W',
                  }},[(_vm.data.match_play_team == 'Y')?[_vm._v(" "+_vm._s(tabledata.name_1)+" ")]:[_vm._v(" "+_vm._s(tabledata.initials_1)+" "+_vm._s(tabledata.surname_1)+" ")]],2),(tabledata.status_1 === 'W')?_c('td',{staticClass:"centerAligned"},[_vm._v(" "+_vm._s(tabledata.full_score_1)+" ")]):_c('td',{staticClass:"centerAligned"},[(tabledata.full_score_1.includes('DOWN'))?void 0:[_vm._v(" "+_vm._s(tabledata.full_score_1)+" ")]],2),_c('td',{staticClass:"centerAligned"},[(tabledata.holes === '')?_c('span',[_vm._v(" "+_vm._s(tabledata.time)+" ")]):(tabledata.holes < 18)?_c('span',[_vm._v(" "+_vm._s(tabledata.holes)),(tabledata.tee_R1 > 1)?_c('span',[_vm._v("*")]):_vm._e()]):_c('span',[_vm._v(" F"),(tabledata.tee_R1 > 1)?_c('span',[_vm._v("*")]):_vm._e()])]),(tabledata.status_2 === 'W')?_c('td',{staticClass:"centerAligned"},[_vm._v(" "+_vm._s(tabledata.full_score_2)+" ")]):_c('td',{staticClass:"centerAligned"},[(tabledata.full_score_2.includes('DOWN'))?void 0:[_vm._v(" "+_vm._s(tabledata.full_score_2)+" ")]],2),_c('td',{staticClass:"RightAligned",class:{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  winusa: tabledata.status_2 == 'W',
                  }},[(_vm.data.match_play_team == 'Y')?[_vm._v(" "+_vm._s(tabledata.name_2)+" ")]:[_vm._v(" "+_vm._s(tabledata.initials_2)+" "+_vm._s(tabledata.surname_2)+" ")]],2)]:_vm._e()],2):_vm._e()]],2)})],2)}),(_vm.home === true)?_c('div',[_c('b-container',[_c('b-row',{staticClass:"homeLeaderHome FUllLeaderboard"},[_c('b-col',{staticClass:"DrawCol"},[_c('b-nav-item',{staticClass:"fullNav",attrs:{"to":{
              name: 'reports-page',
              query: {
                url: 'https://info.letaccess.com/tic/tmdraw.cgi?tourn=' + _vm.code + '~season=' + _vm.season + '~round=' + _vm.data.round + '~',
                code: _vm.code,
                id: _vm.season,
                title: 'Round ' + _vm.data.round + ' Draw',
              },
            }}},[_c('h3',{staticClass:"drawtext"},[_vm._v("Draw")])])],1),_c('b-col',{staticClass:"LeadCol pulse"},[_c('b-nav-item',{staticClass:"fullNav",attrs:{"to":{
              name: 'reports-page',
              query: {
                url: this.report,
                id: _vm.season,
                code: _vm.code,
                title: _vm.title,
              },
            }}},[_c('h3',{staticClass:"FullLeaderboard"},[_vm._v("Full Leaderboard")])])],1)],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }