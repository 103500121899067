<template>
  <div id="slider">
    <div class="outer">
      <b-link :to="homeSlide[0].slug">
        <img
          class="splash"
          :src="homeSlide[0].acf.main_large_article_image"
        >
      </b-link>
    </div>
    <b-row class="titleRow" >
      <b-col>
        <template v-if="homeSlide[0].acf.left_position">
          <b-link :to="homeSlide[0].slug">
            <h1 class="mainTitle" v-html="homeSlide[0].acf.left_position">
            </h1>
          </b-link>
        </template>
        <template v-else-if="homeSlide[0].acf.left_position_smaller_text">
          <b-link :to="homeSlide[0].slug">
            <h1 class="mainTitle" v-html="homeSlide[0].acf.left_position_smaller_text">
            </h1>
          </b-link>
        </template>
        <template v-else-if="homeSlide[0].acf.middle_position">
          <b-link :to="homeSlide[0].slug">
            <h1 class="mainTitle" v-html="homeSlide[0].acf.middle_position">
            </h1>
          </b-link>
        </template>
        <template v-else-if="homeSlide[0].acf.right_position">
          <b-link :to="homeSlide[0].slug">
            <h1 class="mainTitle" v-html="homeSlide[0].acf.right_position">
            </h1>
          </b-link>
        </template>
        <template v-else-if="homeSlide[0].acf.right_position_smaller_text">
          <b-link :to="homeSlide[0].slug">
            <h1 class="mainTitle" v-html="homeSlide[0].acf.right_position_smaller_text">
            </h1>
          </b-link>
        </template>
        <template v-else-if="homeSlide[0].acf.top_left_position">
          <b-link :to="homeSlide[0].slug">
            <h1 class="mainTitle" v-html="homeSlide[0].acf.top_left_position">
            </h1>
          </b-link>
        </template>
        <template v-else>
          <b-link :to="homeSlide[0].slug">
            <h1 class="mainTitle" v-html="homeSlide[0].acf.top_position">
            </h1>
          </b-link>
        </template>

        <b-button class="rMore" :to="homeSlide[0].slug">
          <hr class="hrline-right" /> 
            Read More
          <font-awesome-icon class="icon" :icon="['fa', 'chevron-right']" />
        </b-button>
      </b-col>
      <b-col>
        <MiniLeader class='mobHide' :season='season' :course='course' :report='report' :title='title' :takeover='takeover' :config="config"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <MiniLeader class='mobShow' :season='season' :course='course' :report='report' :title='title' :takeover='takeover' :config="config"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MiniLeader from "@/components/miniLeader.vue";
import axios from "axios";

export default {
  name: "slider",
  components: {
    MiniLeader,
  },
  props: ["season", "posts", "course", "report",'title','takeover', 'config'],
  data() {
    return {
      slide: 0,
      sliding: false,
      homeSlide: [],
    };
  },
  computed: {
    titlePos: function(pos) {
      if (this.homeSlide[0].acf.left_position.length) pos = "left";
      else if (this.homeSlide[0].acf.middle_position.length) pos = "middle";
      else if (this.homeSlide[0].acf.right_position.length) pos = "right";
      else if (this.homeSlide[0].acf.top_left_position.length) pos = "topLeft";
      else if (this.homeSlide[0].acf.top_position.length) pos = "top";
      return pos;
    },
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = this.img;
    },
  },
  mounted() {
    axios //add API Call
      .get(this.posts)
      .then((response) => (
        this.homeSlide = response.data
      ));
  },
};
</script>

<style scoped>
::v-deep .btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5);
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(130, 138, 145, 0.5);
}
.col.takeOver {
  margin-top: 2em;
}
.col.takeOver > h1 {
  font-size: 2rem!important;
}
::v-deep .svg-inline--fa.fa-w-10 {
  width: 0.425em;
  margin-left: 20px;
}
.splash {
  width: 100%;
  opacity: .7;
}
.outer {
  overflow: hidden;
  /* height: 600px; */
  background-color: black;
  object-fit: cover;
}
.takeOver>.mainTitle.topLeft{
  top: 85px!important;
}
.takeOver.outer{
  /* height: 486px; */
}
.takeOver.titleRow{
  margin-top: -26em;
  margin-bottom: 24em;
  position: inherit;
}
.titleRow {
  /* top: 240px; */
  top: 120px;
  /* top: 250px; */
  position: absolute;
  width: 100%;
}

.mainTitle.left {
  top: 25px;
  left: 3em;
  width: 770px;
  font-weight: 500;
}
.mainTitle.middle {
  top: 70px;
  left: 13.5em;
  width: 500px;
}
.mainTitle.topLeft {
  left: 25px;
  width: 550px;
}
.mainTitle {
  position: absolute;
  color: #fff;
  padding: 30px;
  text-align: left;
  text-transform: capitalize;
  z-index: 9;
  font-size: 3rem;
  line-height: 1.1667;
  width: 520px;
}
.rMore {
  position: absolute;
  top: 9em;
  color: #fff;
  padding: 30px;
  text-align: left;
  /* width: 500px; */
  text-transform: capitalize;
  font-weight: 500 !important;
  z-index: 9;
  font-size: 2rem;
  line-height: 1.1667;
  -ms-flex: 0 0 23.75rem;
  flex: 0 0 23.75rem;
  background-color: transparent;
  border: none;
  font-weight: 400!important;
}
.rMore.left {
  text-align: left;
  left: 4.5em;
}
.rMore.middle{
  text-align: left;
  left: 20.3em;
}
.rMore.topLeft{
  left: 0.7em;
  top: 9em;
}
hr.hrline-right {
  /* position: absolute; */
  /* top: 9em; */
  color: #fff;
  /* padding: 30px; */
  /* width: 200px; */
  width: 100%;
  text-transform: capitalize;
  z-index: 9;
  font-size: 2rem;
  line-height: 1.1667;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 23.75rem;
  flex: 0 0 23.75rem;
  border-top: 2px solid;
}
hr.hrline-right.left {
  text-align: left;
  left: 5.5em;
}
hr.hrline-right.middle {
  text-align: left;
  left: 21.3em;
}
hr.hrline-right.topLeft{
  left: 0;
  /* right: 9em; */
  right: 11.5em;
}
.mobShow{
  display: none;
}
@media only screen and (max-width: 1300px) {
  .mobShow{
    display: block;
  }
  .mobHide{
    display: none;
  }
}
@media only screen and (max-width: 1024px) {
  .outer{
    /* height: 430px; */
    object-fit: cover;
  }
  .mobHide{
    display: none;
  }
  hr.hrline-right.topLeft{
    right: 4em;
    top: 7em;
  }
  .rMore.topLeft{
    top: 7em;
  }
}
@media only screen and (max-width: 768px) {
  .outer{
    /* height: 330px; */
    object-fit: cover;
  }
  .hrline-right{
    display: none;
  }
  .rMore{
    display: none;
  }
  .mainTitle {
    position: absolute;
    color: #fff;
    padding: 30px;
    text-align: left;
    text-transform: capitalize;
    z-index: 9;
    font-size: 3rem;
    line-height: 1.1667;
    margin-top: -2em;
  }
}
@media only screen and (max-width: 600px) {
  .mainTitle {
    position: absolute;
    color: #fff;
    padding: 30px;
    text-align: left;
    text-transform: capitalize;
    z-index: 9;
    font-size: 1.5rem;
    line-height: 1.1667;
  }
}
@media only screen and (max-width: 425px) {
  .mainTitle{
    width: 200px!important;
    font-size: 1.0rem;
    top: -1em;
  }
  .outer{
    /* height: 180px; */
    object-fit: cover;
  }
}

</style>