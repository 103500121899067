<template>
  <div id="news">
    <b-container class="NewsPage">
      <b-row>
        <b-col>
          <div class="searchBlock">
            <b-form-input
              v-on:keyup.enter="onEnter"
              v-model="text"
              placeholder="Search"
            ></b-form-input>
            <b-button
              @click="onEnter()"
              class="sIcon"
            >
              <span class="searchBtn BTNSEARCH">Search</span>
            </b-button>
          </div>
        </b-col>
        <b-col>
          <b-form-select
            style="background-color: #95c93d;"
            v-on:change="getSelectedItem"
            v-model="selected"
            :options="options"
            placeholder="Select Month"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <template v-for="(postCard, index) in displayedPosts">
          <!-- <b-col class="col-lg-4 col-md-6 col-12" :key="index" v-if="value.includes(postCard.date)"> -->
          <b-col class="col-lg-4 col-md-6 col-12" :key="index">
            <template v-if="postCard.better_featured_image == null">
              <b-link :to="postCard.slug" class="noUnderline">
                <b-card
                  img-src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/sites/3/2020/10/letas.png"
                  img-alt="Image"
                  img-top
                  tag="article"
                  footer-tag="footer"
                  class="mb-2"
                >
                  <a :href="postCard.slug" class="noUnderline"
                    ><h5 class="pt" v-html="postCard.title.rendered"></h5
                  ></a>
                </b-card>
              </b-link>
            </template>
            <template v-else>
              <b-link :to="postCard.slug" class="noUnderline">
                <b-card
                  :img-src="postCard.better_featured_image.source_url"
                  img-alt="Image"
                  img-top
                  tag="article"
                  footer-tag="footer"
                  class="mb-2"
                >
                  <a :href="postCard.slug" class="noUnderline"
                    ><h5 class="pt" v-html="postCard.title.rendered"></h5
                  ></a>
                </b-card>
              </b-link>
            </template>
          </b-col>
        </template>
      </b-row>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <button
              type="button"
              class="page-link pagenation"
              v-if="page != 1"
              @click="page--"
            >
              Previous
            </button>
          </li>
          <template v-if="isMobile(true)">
            <li class="page-item">
              <button
                :class="{ activepage: page == pageNumber }"
                type="button"
                class="page-link pagenation"
                v-for="(pageNumber, index) in pages.slice(page - 1, page + 2)"
                @click="page = pageNumber"
                :key="index"
              >
                {{ pageNumber }}
              </button>
            </li>
          </template>
          <template v-else>
            <li class="page-item">
              <button
                :class="{ activepage: page == pageNumber }"
                type="button"
                class="page-link pagenation"
                v-for="(pageNumber, index) in pages"
                @click="page = pageNumber"
                :key="index"
              >
                {{ pageNumber }}
              </button>
            </li>
          </template>
          <li class="page-item">
            <button
              type="button"
              @click="page++"
              v-if="page < pages.length"
              class="page-link pagenation"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "posts",
  data() {
    return {
      value: "",
      posts: [],
      selectedMonth: 0, // Default to All
      pages: [],
      selected: "Select Month",
      options: [],
      filterType: "",
      monthValue: "",
      page: 1,
      perPage: 6,
      monthStart: null,
      monthEnd: null,
      test: '',
      search: ''
    };
  },
  methods: {
    onEnter: function() {
      window.location.href = "/search?search=" + this.text;
    },
    // goSearch() {
    //   this.$router.push({ name: "search", query: { search: this.text } });
    // },
    getSelectedItem: function() {
      this.monthValue = this.selected;
      console.log("this.monthValue");
      console.log(this.monthValue);
      if (this.monthValue == "Select Month") {
        return this.getNews();
      }
      const year = moment(this.monthValue).year();
      const month = moment(this.monthValue).month();

      this.monthStart = moment()
        .set({ year: year, month: month })
        .startOf("month")
        .toISOString();
      this.monthEnd = moment()
        .set({ year: year, month: month })
        .endOf("month")
        .toISOString();
      this.getNews(true);
    },
    createMonths() {
      const getMonths = (start, end) =>
        Array.from({ length: end.diff(start, "month") + 1 }).map((_, index) =>
          moment(start)
            .add(index, "month")
            .format("MMMM YYYY")
        );
      const end_of_month = moment()
        .endOf("month")
        .format("MM.YYYY");
      const months = getMonths(
        // moment("01.2019", "MM.YYYY"),
        // moment("12.2018", "MM.YYYY"),
        moment("02.2012", "MM.YYYY"),
        moment(end_of_month, "MM.YYYY")
      );
      this.options = months.reverse();
      this.options.unshift("Select Month");
    },
    getNewsDate: function() {
      // let arr = [];
      this.posts.forEach(value => {
        this.getSelectedItem();
        // value.date_gmt
        var jsonDate = value.date;
        jsonDate = jsonDate.replace(/^.{5}/g, "");
        jsonDate = jsonDate.slice(0, -12);
        var dateStr = JSON.stringify(jsonDate);
        console.log("dateStr");
        console.log(dateStr);
        if (dateStr == this.monthValue) {
          this.showArticle == "Y";
        } else {
          this.showArticle == "N";
        }
      });
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.posts.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(posts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return posts.slice(from, to);
    },
    getNews(month_filter) {
      let news_request = this.setDev + "?page=10&per_page=100";
      if (month_filter === true) {
        console.log(month_filter);
        news_request +=
          "&after=" + this.monthStart + "&before=" + this.monthEnd;
      }
      axios
        .get(news_request)
        .then(
          response => ((this.posts = response.data), (this.loading = true))
        );
    }
  },
  watch: {
    posts() {
      this.setPages();
    }
  },
  created() {
    this.getPosts();
  },
  mounted() {
    this.getNews();
    this.createMonths();
  },
  computed: {
    setDev: function() {
      return process.env.VUE_APP_WPAPI_URL + "wp/v2/posts?_embed";
    },
    displayedPosts() {
      return this.paginate(this.posts);
    },
    filteredPosts() {
      return this.$options.filters.filterByMonth(
        this.posts,
        this.selectedMonth
      );
    }
  }
};
</script>

<style scoped>
::v-deep .btn-secondary {
  color: #fff;
  background-color: #95c93d;
  border-color: #95c93d;
}
.noUnderline {
  text-decoration: none;
}
button.page-link.pagenation.activepage {
  background: #95c93d;
  color: #fff;
}
::v-deep .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #95c93d;
  background-color: #fff;
  border: 1px solid #dee2e6;
  font-size: 13pt;
}
li.page-item {
  display: flex;
  margin-top: 50px;
}
.custom-select {
  display: inline-block;
  width: 283px;
  float: right;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 37px;
  color: #fff;
  border-radius: 5px;
  background: #fff url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/DownArrow.png') right 0.75rem center/12px 7px no-repeat;
  border: none;
  border-radius: 0rem;
  /* padding: 10px; */
  color: #fff;
  font-size: 1rem;
}
.searchBlock {
  display: inline-flex;
  padding-bottom: 2em;
}
a.btn.sIcon.btn-secondary {
  background: #95c93d;
}
.form-control {
  display: block;
  width: 283px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d4d6;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
span.searchBtn {
  background-color: #95c93d;
}
.NewsPage {
  margin-top: 2em;
}
#news {
  background-color: #fff;
  padding-bottom: 5%;
}
.more {
  text-align: center;
}
.show {
  padding-bottom: 2%;
}
.logo {
  text-align: center;
  display: block;
  margin: auto;
  float: right;
  width: 120px;
  margin-top: 35px;
  margin-right: 15px;
}
.card-img-top {
  width: 100%;
  height: auto;
  min-height: 183px;
  /* border-radius: 10px 10px 0 0 !important; */
  /* max-height: 180px; */
}
.card-body {
  padding: 15px;
  padding-left: 0px;
  padding-top: 25px;
}
.card-footer {
  border-radius: 0 0 10px 10px !important;
  background-color: #133f7b;
  color: white;
  text-align: center;
}
#title {
  padding-top: 5%;
  padding-bottom: 5%;
}
.card {
  background-color: transparent;
  border: 0;
}
.exre {
  font-size: 12pt;
  color: #b4b4b4;
}
.pt {
  color: #212529;
  font-size: 13pt;
  padding-left: 0px;
  font-weight: 400;
}
.pt:hover {
  color: #015699;
  font-size: 13pt;
  padding-left: 0px;
  font-weight: 400;
  text-decoration: none;
}
::v-deep .nav-link {
  color: white;
  background-color: #95c93d;
  margin-top: -11%;
}

.space {
  padding-bottom: 2%;
  border-bottom: 1px solid lightgrey;
  padding-top: 2%;
}
.developGreenText {
  color: #76c3bd !important;
}
.developGreen > .card-footer {
  background-color: #76c3bd !important;
}
.developGreen > .card-footer > li > .nav-link {
  background-color: #76c3bd !important;
}
.developGreen > .card-body {
  min-height: 347px;
}

@media only screen and (max-width: 768px) {
  ::v-deep .nav-link {
    text-align: center;
    margin-top: -6%;
  }
}

@media only screen and (max-width: 480px) {
  .col {
    flex-basis: auto;
  }
  ::v-deep .nav-link {
    text-align: center;
    margin-top: -6%;
  }
  ::v-deep .row {
    padding-bottom: 4%;
  }
  ::v-deep .form-control {
    display: block;
    /* width: 283px; */
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2d4d6;
    border-radius: 0rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-right: 10px;
  }
  ::v-deep a.btn.sIcon.btn-secondary {
    background: #01477b;
    border-radius: 0;
    border-color: #01477b;
  }
  ::v-deep .custom-select {
    width: 100%;
  }
  ::v-deep .searchBlock {
    display: flex;
    padding-bottom: 2em;
  }
}
</style>
