import { render, staticRenderFns } from "./youTube.vue?vue&type=template&id=46bcbc62&scoped=true"
import script from "./youTube.vue?vue&type=script&lang=js"
export * from "./youTube.vue?vue&type=script&lang=js"
import style0 from "./youTube.vue?vue&type=style&index=0&id=46bcbc62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46bcbc62",
  null
  
)

export default component.exports