<template>
  <span>
    <b-container>
      <b-row v-if="this.$route.name === 'home'">
        <b-col>
          <b-link :to="'/news'">
            <b-button class="allNews">See All News</b-button>
          </b-link>
        </b-col>
      </b-row>
      <b-row v-if="this.$route.name === 'home'">
        <b-col
          lg="4"
          id="mobilePost"
          v-for="(posts, index) in allposts.slice(0, 6)"
          :key="index"
          class="HomePosts"
        >
          <b-nav-item :to="posts.slug">
            <div class="outerImg">
              <b-img
                :class="postImg"
                :src="posts.better_featured_image.source_url"
                fluid
                alt="Responsive image"
              />
            </div>
            <div class="miniWrap homeLetMini">
            </div>
            <h3 v-html="posts.title.rendered" class="homeNews"></h3>
          </b-nav-item>
        </b-col>
      </b-row>
      <b-row class="tourNewsGrid" v-if="this.$route.name === 'Tournament'" v-bind:class="{'tourArticles' : this.$route.name === 'Tournament'}">
        <b-col
          lg="4"
          class="tourCol"
          id="mobilePost"
          v-for="(posts, index) in allposts"
          :key="index"
        >
          <b-nav-item class="tourLink" :to="posts.slug">
            <b-img
              :class="postImg"
              :src="posts.better_featured_image.source_url"
              fluid
              alt="Responsive image"
            />
            <div class="miniWrapTour">
              <h3 v-html="posts.title.rendered" class="tourNews"></h3>
            </div>
          </b-nav-item>
        </b-col>
      </b-row>
      <b-row class="tourNewsGrid" v-if="this.$route.name === 'player-profiles'">
        <b-col
          lg="4"
          class="tourCol"
          id="mobilePost"
          v-for="(posts, index) in allposts"
          :key="index"
        >
          <b-nav-item class="playerLink" :to="posts.slug">
            <b-img
              :class="postImg"
              :src="posts.better_featured_image.source_url"
              fluid
              alt="Responsive image"
            />
            <div class="miniWrapTour">
              <h3 v-html="posts.title.rendered" class="tourNews"></h3>
            </div>
          </b-nav-item>
        </b-col>
      </b-row>

    </b-container>
    <b-row v-if="this.$route.name === 'team-europe'">
      <b-col
        lg="4"
        id="mobilePostEuro"
        v-for="(posts, index) in allposts.slice(0, 6)"
        :key="index"
      >
        <b-nav-item :to="posts.slug">
          <div class="outerImg">
            <b-img
              :class="postImg"
              :src="posts.better_featured_image.source_url"
              fluid
              alt="Responsive image"
            />
          </div>
          <div class="miniWrap">
            <h3 v-html="posts.title.rendered" class="euroNews"></h3>
          </div>
        </b-nav-item>
      </b-col>
    </b-row>
    <b-container v-if="this.$route.name === 'develop'">
      <b-row>
      <b-col
        lg="4"
        id="mobilePostEuro"
        v-for="(posts, index) in allposts.slice(0, 6)"
        :key="index"
      >
        <b-nav-item :to="posts.slug">
          <div class="outerImg">
            <b-img
              :class="postImg"
              :src="posts.better_featured_image.source_url"
              fluid
              alt="Responsive image"
            />
          </div>
          <div>
            <h3 v-html="posts.title.rendered" class='develop'></h3>
          </div>
        </b-nav-item>
      </b-col>
    </b-row>      
    </b-container>
  </span>
</template>

<script>
import axios from "axios";
export default {
  name: "posts",
  props: ["tag"],
  data() {
    return {
      allposts: [],
      postsRow: "posts",
      postImg: "postImg",
    };
  },
  computed: {
    tourOrHome(url) {
      if (this.$route.name === "Tournament" || this.$route.name === 'player-profiles')
        url =
          process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?tags=" + this.tag;
      else if (this.$route.name === "team-europe")
      url = process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?categories=130&per_page=99"
      else if (this.$route.name === "develop")
      url = process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?categories=21&per_page=99"
      else
        url =
          process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?randomadd=" +
          new Date().getTime();
      return url;
    },
  },
  mounted() {
    axios //add API Call
      .get(this.tourOrHome)
      .then((response) => (this.allposts = response.data));
  },
};
</script>

<style scoped>
.nav-link {
  display: block;
  padding: 0.9rem 0.5rem;
}
iframe#_ytid_82193 {
  width: 100%;
}
.homeLetMini {
  bottom: 12px!important;
  background: rgba(149, 201, 61, 1)!important;
  opacity: 0.8;
  height: 75px;
}
.HomePosts {
  padding-bottom: 20px;
}
.HomePosts > li > a {
  padding: 0px;
}
.tourArticles {
  margin-top: 25px;
}
li{
  list-style: none;
}
.allNews {
  float: right;
  background-color: #95c93d;
  border-radius: 0;
  padding: 11px 30px;
  margin-bottom: 8px;
  border-color: #95c93d;
}
.outerImg {
  height: 210px;
  overflow: hidden;
  border: 1px solid rgba(0,0,0,.125);
  /* border-radius: 0.25rem; */
}
.mobilePost .firstImg {
  width: 100%;
}
.miniWrapTour {
  position: absolute;
  bottom: -8px;
  width: 95.5%;
}
.tourNewsGrid {
  padding: 0 0 50px 0;
}
.tourCol {
  padding-left: 0px;
  padding-right: 0px;
}
#mobilePostEuro{
  padding: 0;
}
.tourLink > a {
  padding-bottom: 0%;
}
.playerLink>a{
  padding: 0;
}
.miniWrap {
  position: absolute;
  bottom: 41px!important;
  width: 80%;
}
.tourNews {
  color: #fff;
  font-size: 10pt;
  margin-top: 0;
  padding: 10px;
  background-color: #95c93d;
  font-weight: 400;
  height: 55px;
}

.homeNews {
  color: white;
  /* background: rgba(149, 201, 61, 1)!important; */
  z-index: 99;
  font-size: 11pt;
  opacity: 0.9;
  height: 82px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 14px 14px 15px 17px;
  vertical-align: middle;
  text-transform: capitalize;
  margin-top: -70px;
  width: 285px;
}
.euroNews {
  color: white;
  background: rgba(24,23,151,1)!important;
  z-index: 99;
  font-size: 11pt;
  opacity: 0.8;
  height: 82px;

  overflow: hidden;
  text-overflow: ellipsis;
  padding: 14px 14px 15px 17px;
  vertical-align: middle;
  text-transform: capitalize;

  margin-top: -42px;
}
.develop{
  color: #95c93d;
    font-size: 13pt;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    vertical-align: middle;
    line-height: 1.5;
    text-transform: uppercase;
}
@media only screen and (max-width: 1024px) {
  .homeNews {
    font-size: 1em;
  }
  .outerImg{
    height: 170px;
  }
  .homeNews{
    font-size: 11pt;
  }
}
@media only screen and (max-width: 768px) {
  .outerImg{
    height: 100%;
  }
  .miniWrap {
    margin-top: -104px;
  }
  .homeNews {
    height: 155px;
  }
  .homeLetMini {
    bottom: 29px!important;
  }
  .homeNews {
    height: 70px!important;
  }
  .col-md-8.col {
    min-width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .miniWrap {
    margin-top: -46px;
  }
  .homeNews {
    height: 52px;
  }
}
</style>
