<template>

    <table>
      <thead>
        <tr v-if="home === false" class="favSec">
          <template
            v-if="
              (tablePick === 'Score' && data.match_play === 'N') ||
                (tablePick === 'Live' && data.match_play === 'N')
            "
          >
            <th :class="{favScores : this.title.includes('Only')}">
              Fav
            </th>
            <th :class="{posScores : this.title.includes('Only')}">
              Pos
            </th>
            <template v-if="tablePick === 'Live' && RTCDS > 0" >
              <th>
                OOM
              </th>
            </template>
            <th class="playersName-score" :class="{playerName : this.title.includes('Only')}">
              Player Name
            </th>
            <th class="noPadLeft" v-if="data.multi_course == 'Y'">
              Crs
            </th>
            <th class="noPadLeft" :class="{NatScores : this.title.includes('Only')}">
              Nat.
            </th>
            <th
              class="PadLeft"
              :class="{parScores : this.title.includes('Only')}"
              v-if="
                data.score_type === 'P' ||
                  (data.tt_type === 'P' && data.stableford === 'N')
              "
            >
              Par
            </th>
            <th
              class="PadLeft"
              v-if="
                data.score_type === 'P' ||
                  (data.tt_type === 'P' && data.stableford === 'Y')
              "
            >
              Points
            </th>
            <template v-if="this.title.includes('Scoreboard')">
              <th
                v-for="(i, roundIt) in range(1, roundsPlayed)"
                :key="roundIt"
                class="hideOnMob"
              >
                <span v-if="roundsPlayed === 1 && tablePick === 'Score'">
                  Score
                </span>
                <span v-else> R{{ i }} </span>
              </th>
              <template v-if="data.scores.scores_entry[0].score_found === 'Y'">
                <th v-if="roundsPlayed > 1">
                  Score
                </th>
              </template>
              <template v-if="roundsPlayed > 1 && tablePick === 'Score'">
                <th
                  class=""
                  v-if="
                    config.VUE_APP_PLAYER_ICON === 'Y' &&
                      data.scores.scores_entry[0].score_found === 'Y' &&
                      data.last_round_complete === 'Y'
                  "
                ></th>
              </template>
            </template>
            <template
              v-if="
                data.scores.scores_entry[0].score_found === 'N' ||
                  data.round_head == 'Latest Scores'
              "
            >
              <th>
                Thru
              </th>
            </template>
            <template v-else-if="data.scores.scores_entry[0].score_found === 'Y' && this.title.includes('Scores Only') || this.title.includes('Latest Scores')">
              <th>Score</th>
            </template>
            <th></th>
          </template>
          <template
            v-if="
              (tablePick === 'Score' && data.match_play === 'Y') ||
                (tablePick === 'Live' && data.match_play === 'Y')
            "
          >
            <th>
              Name
            </th>
            <th>
              Score
            </th>
            <th>
              Thru.
            </th>
            <th>
              Score
            </th>
            <th>
              Name
            </th>
          </template>
          <template> </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(tabledata, index) in compare">
          <tr
            class="compareBk"
            @click.stop="rowClickedCompare(index)"
            :key="index"
            v-bind:class="{
              'homeTd-size': home === true,
              drawHome: home === true && tablePick === 'Draw',
            }"
          >
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'N') ||
                  (tablePick === 'Live' && data.match_play === 'N')
              "
            >
              <td >
                <img class="favStar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/05/Star_Selected.png">
              </td>
              <td class="cut" colspan="11" v-if="tabledata.pos === 'CUT'">
                {{ tabledata.cut_round }}
              </td>
              <td
                class="posnum"
                v-else-if="
                  index !== 0
                "
              >
                <span>
                  {{ tabledata.tied}} {{ tabledata.pos }}
                </span>
              </td>
              <td class="posnum" v-else-if="index === 0">
                <span>
                  {{ tabledata.tied}} {{ tabledata.pos }}
                </span>
              </td>
              <td v-else class="noPos"><span>.</span></td>
              <td v-if="RTCDS > 0 && tabledata.pos !== 'CUT'">
                <span
                  :class="{up:table.oom_diff_pos > 0, down:table.oom_diff_pos < 0}"
                  class="scores-pos"
                  v-if="tabledata.oom_pos.length > 0 && tabledata.oom_pos !=='0'"
                >
                  {{ value.oom_pos }}
                  <font-awesome-icon v-if="table.oom_diff_pos > 0" class="icon" :icon="['fa', 'arrow-up']" />
                  <font-awesome-icon v-if="table.oom_diff_pos < 0" class="icon" :icon="['fa', 'arrow-down']" />
                </span>
              </td>
              <td
                class="PlayerName"
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'N'
                "
                @click="playerCard"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                <a
                  :href="'/player-profiles/' + tabledata.member_no"
                  :class="{ homePlayername: home === true }"
                >
                  {{ tabledata.name }} <span v-if="tabledata.pro_ind == 'Am'">(a)</span>
                </a>
                <span v-if="tabledata.sponsored === 'Y' && home !== true">
                  <img
                    class="titliestLogo"
                    src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"
                  />
                </span>
                <span
                  v-if="showGender === 'Y'"
                  class="multiDot-size-reportgender"
                  :class="{female : tabledata.gender === 'F', male : tabledata.gender === 'M'}"
                  >•</span>
              </td>
              <td
                class="PlayerName"
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.pos !== 'CUT' &&
                    team === 'Y'
                "
                @click="playerCard"
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                }"
              >
                {{ tabledata.name }} <span v-if="tabledata.pro_ind == 'Am'">(a)</span>
                <span v-if="tabledata.sponsored === 'Y' && home !== true">
                  <img
                    class="titliestLogo"
                    src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png"
                  />
                </span>
                <span
                  v-if="showGender === 'Y'"
                  class="multiDot-size-reportgender"
                  :class="{female : tabledata.gender === 'F', male : tabledata.gender === 'M'}"
                  >•</span>
              </td>
              <td v-if="data.multi_course === 'Y' && tabledata.pos !== 'CUT'">
                <span
                  v-if="data.multi_course === 'Y'"
                  :class="tabledata['course_colour_R' + data.pft_round]"
                  class="multiDot-size-report"
                  >•</span
                >
              </td>
              <td
                v-bind:class="{ natNone: home === true }"
                class="mobHide"
                v-if="tabledata.pos !== 'CUT'"
              >
                <span v-if="tabledata.nationality === ''"></span>
                <span v-else>
                  <img
                    class="flag"
                    :src="
                      (config.VUE_APP_FLAG_URL + tabledata.nationality)
                        | lowercase
                    "
                  />
                </span>
              </td>
              <template
                v-if="
                  data.score_type === 'P' ||
                    (data.tt_type === 'P' && data.stableford === 'N')
                "
              >
                <td v-if="tabledata.vspar < 0" class="up">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.vspar > 0" class="down">
                  {{ tabledata.vspar }}
                </td>
                <td v-else-if="tabledata.pos !== 'CUT'" :class="{'nonScore': tabledata.vspar == 'RTD'}">
                  {{ tabledata.vspar }}
                </td>
              </template>
              <template
                v-if="
                  data.score_type === 'P' ||
                    (data.tt_type === 'P' && data.stableford === 'Y')
                "
              >
                <td v-if="tabledata.pos !== 'CUT'">
                  {{ tabledata.score }}
                </td>
              </template>
              <template
                v-if="
                  title.includes('Scoreboard') &&
                    tabledata.pos !== 'CUT' &&
                    data.stableford === 'Y'
                "
              >
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone hideOnMob"
                >
                  <!-- <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up"
                    >{{ tabledata.score }} </span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1">{{
                    tabledata.score
                  }}</span> -->
                  <span v-if="tabledata['score_R' + i] > 0" class="plus">{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span v-else-if="tabledata['score_R' + i] < 0" class="minus">
                    {{ tabledata["score_R" + i] }}
                  </span>
                  <span v-else class="zero">
                    {{ tabledata["score_R" + i] }}
                  </span>
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class=""
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template
                v-if="
                  title.includes('Scoreboard') &&
                    tabledata.pos !== 'CUT' &&
                    data.stableford === 'N'
                "
              >
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone hideOnMob"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up" 
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1" >{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up" >{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else :class="{'nonScore': tabledata.vspar == 'RTD'}">{{ tabledata["score_R" + i] }}</span>
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class="down"
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template v-else-if="data.round_head == 'Latest Scores' || tabledata.score_found == 'Y'">
                <td
                  v-for="(i, roundIt) in range(1, roundsPlayed)"
                  :key="roundIt"
                  class="mobnone hideOnMob"
                >
                  <span
                    v-if="roundsPlayed === 1 && tabledata.vspar < 0"
                    class="up" 
                    >{{ tabledata.score }}</span
                  >
                  <span
                    v-else-if="roundsPlayed === 1 && tabledata.vspar > 0"
                    class="down"
                    >{{ tabledata.score }}</span
                  >
                  <span v-else-if="roundsPlayed === 1" >{{
                    tabledata.score
                  }}</span>
                  <span v-else-if="tabledata['vspar_R' + i] < 0" :class="up" >{{
                    tabledata["score_R" + i]
                  }}</span>
                  <span
                    v-else-if="tabledata['vspar_R' + i] > 0"
                    :class="down"
                    >{{ tabledata["score_R" + i] }}</span
                  >
                  <span v-else :class="{'nonScore': tabledata.vspar == 'RTD'}">{{ tabledata["score_R" + i] }}</span>
                </td>
                <template v-if="tabledata.score_found === 'Y'">
                  <td v-if="tabledata.vspar < 0 && roundsPlayed > 1" class="up">
                    {{ tabledata.score }}
                  </td>
                  <td
                    v-else-if="tabledata.vspar > 0 && roundsPlayed > 1"
                    class="down"
                  >
                    {{ tabledata.score }}
                  </td>
                  <td v-else-if="roundsPlayed > 1">
                    {{ tabledata.score }}
                  </td>
                </template>
              </template>
              <template
                v-if="
                  data.round_head == 'Latest Scores' ||
                    tabledata.score_found == 'N'
                "
              >
                <td style="color: #000;" v-if="tabledata.pos !== 'CUT'">
                  <template
                    v-if="tabledata.holes && tabledata.holes.length > 0"
                  >
                    <span v-show="tabledata.holes != '18'"
                      >{{ tabledata.holes
                      }}<span v-if="tabledata['tee_R' + data.pft_round] > 1"
                        >*</span
                      ></span
                    >
                    <span v-show="tabledata.holes == '18'">F</span>
                  </template>
                  <template v-else>
                    {{ tabledata["time_R" + data.pft_round] }}
                  </template>
                </td>
              </template>
              <td
                v-if="
                  tabledata.playerno !== 'Cat' &&
                    tabledata.playerno !== 'EOF' &&
                    tabledata.pos !== 'CUT'
                "
              >
                <a :href="'/player-profiles/' + tabledata.member_no" v-if="tabledata.profile == 'Y'" target="_blank">
                  <span class="iconRight">
                    <font-awesome-icon
                      class="icon"
                      :icon="['fa', 'chevron-right']"
                    />
                  </span>
                </a>
              </td>
            </template>
            <template
              v-if="
                (tablePick === 'Score' && data.match_play === 'Y') ||
                  (tablePick === 'Final' && data.match_play === 'Y')
              "
            >
              <td
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_1 == 'W',
                }"
              >
                {{ tabledata.initials_1 }} {{ tabledata.surname_1 }}
              </td>
              <td v-if="tabledata.status_1 === 'W'" class="win">
                {{ tabledata.full_score_1 }}
              </td>
              <td v-else></td>
              <td>
                <span v-if="tabledata.holes === ''">
                  {{ tabledata.time }}
                </span>
                <span v-else-if="tabledata.holes < 18">
                  {{ tabledata.holes
                  }}<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
                <span v-else>
                  F<span v-if="tabledata.tee_R1 > 1">*</span>
                </span>
              </td>
              <td v-if="tabledata.status_2 === 'W'" class="win">
                {{ tabledata.full_score_2 }}
              </td>
              <td v-else></td>
              <td
                :class="{
                  'text-up': tabledata.posChang == 'up',
                  'text-down': tabledata.posChang == 'down',
                  win: tabledata.status_2 == 'W',
                }"
              >
                {{ tabledata.initials_2 }} {{ tabledata.surname_2 }}
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
</template>

<script>
// import axios from "axios";
export default {
  name: "favplayer",
  props: [
    "compare",
    "data",
    "previousData",
    "home",
    "title",
    "report",
    "code",
    "season",
    "takeover",
    "config",
    "team",
    "match",
    "live",
    "RTCDS",
    "bbTeamEvent",
    "roundsplayed",
    "showGender",
    "Stableford",
  ],
  data() {
    return {
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      clickedRowCompare: -1,
      cardID: [],
      playerCardData: [],
      
    };
  },
  methods: {
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClickedCompare: function(index) {
      if (this.home == true) {
        console.log("TRUE");
        this.clickedRowCompare = this.reportTitle;
      } else {
        if (
          this.tablePick === "Score" ||
          (this.tablePick === "Live" && this.clickedRowCompare !== index)
        )
          this.clickedRowCompare = index;
        else this.clickedRowCompare = -1;
      }
    },
    rowClicked: function(index) {
      if (this.home == true) {
        console.log("TRUE");
        this.clickedRow = this.reportTitle;
      } else {
        if (
          this.tablePick === "Score" ||
          (this.tablePick === "Live" && this.clickedRow !== index)
        )
          this.clickedRow = index;
        else this.clickedRow = -1;
      }
    },
    playerCard: function(playerNo) {
      var playerData = {};
      // var cardID = event.target.getAttribute("title");
      console.log("playerData");
      console.log(playerData);
      var cardID = playerNo;
      console.log("cardID");
      console.log(cardID);
      for (var i of this.data.scores.scores_entry) {
        console.log("in scores");
        if (i.member_no == playerNo) {
          this.playerData = i;
          console.log("playerData 998");
          console.log(this.playerData);
          break;
        }
      }
      for (var t of this.data.team_scores.team_scores_entry) {
        console.log("in scores");
        if (t.member_no == playerNo) {
          this.playerDataTeam = t;
          console.log("playerData 998");
          console.log(this.playerDataTeam);
          break;
        }
      }
    }
  },
  computed: {
    tablePick: function(picked) {
      if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    Eds(reportData) {
      if (this.home === false) {
        if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry;
        return reportData;
      } else {
        if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry.slice(0, 5);
        return reportData;
      }
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
.favScores {
  width: 8%;
}
.posScores {
  width: 8.9%;
}
.playerName {
  width: 55%;
}
.NatScores {
  width: 10.5%;
}
.parScores {
  width: 9%;
}
/* Home Styles */
/* ::v-deep .custom-checkbox .custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: transparent;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/05/Star_Deselected.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 19px;
  height: 19px;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: transparent;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/05/Star_Selected.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 19px;
  height: 19px;
} */
::v-deep .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  display: none;
}
::v-deep .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
    display: none;
}
span.favtextHead {
  font-size: 13pt;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 20px;
  display: block;
  margin-bottom: 20px;
  color: #03477b;
}
tr.favSec >th {
  color: white !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edb503;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
  padding-left: 10px;
}
h3.drawtext {
  font-size: 10pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
  text-align: center;
  color: #fff!important;
  padding-top: 10px;
  font-size: 1rem;
}
.DrawCol.col {
  background-color: #015598;
  padding: 0;
}
.LeadCol {
  padding: 0;
}
td.PlayerName {
  cursor: pointer;
}
td.PlayerName:hover {
  text-decoration: underline;
}
tr.compareBk {
  /* background: #03477b!important;
  color: #fff; */
}
tr.compareBk > td {
  /* color: #fff!important; */
}
tr.compareBk > td > a {
  /* color: #fff!important; */
}
::v-deep .home.takeOver {
  width: 300px;
  position: absolute;
  top: 4.55em;
  right: -0.94em;
}
.custom-control {
  position: relative;
  z-index: 0;
  display: block;
  min-height: 1.5rem;
  padding-left: 0.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
img.favStar {
  width: 19px;
  cursor: pointer;
}
.noPadLeft {
  /* padding-left: 0px; */
}
.hideRepresentsHome {
  display: none;
}
p.homePlayername {
  color: #055498;
}
td.homePlayername {
  color: #055498;
}
a.homePlayername {
  color: #055498;
}
a.homeResult {
  color: #055498 !important;
}
th.representsText {
  text-align: left !important;
}
td.representsText {
  text-align: left !important;
}
tr.homeTd-size {
  background-color: #fff !important;
  color: #055498;
  border-top: 1px solid #055498 !important;
}
tr.drawHome {
  border-top: 0px solid #055498 !important;
}
tr.drawHome > td.drawPos {
  top: 0px;
}
tr.drawHome > td > a {
  color: #055498 !important;
}
tr.homeTd-size > td {
  font-size: 10pt;
  color: #055498;
  padding-top: 0px;
  padding-bottom: 0px;
}
tr.homeTd-size > td.posnum {
  margin-top: 5px;
  margin-bottom: 4px;
}
h3.FullLeaderboard {
  font-size: 14pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
  text-align: center;
  background: #FF5C39;
  color: #fff!important;
  /* padding-bottom: 10px; */
  padding-top: 10px;
  font-size: 1rem;
}
.natNone {
  display: none;
}
.fullNav {
  padding-top: 0 !important;
  display: block !important;
}
.fullNav > .nav-link {
  padding: 0 1rem;
  color: white;
}
.table-responsive {
  margin-bottom: 0;
}
.courseLogo {
  width: 35%;
  padding-top: 25px;
}

.homeLeader {
  text-align: center !important;
  background-color: #035599 !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  font-size: 11pt;
  padding-bottom: 18px!important;
}
.homeLeaderHome {
  background-color: #FF5C39;
  text-align: center !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  font-size: 11pt;
}
.female {
  color: #e33;
}
.male {
  color: #037
}
span.multiDot-size-reportgender  {
  font-size: 30px!important;
  line-height: 0px !important;
  position: relative;
  top: 6px;
}
span.multiDot-size-report {
  /* float: right; */
  font-size: 60px!important;
  line-height: 0px !important;
  position: relative;
  top: 12px;
}
.homeHead {
  font-size: 11pt;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  padding-bottom: 0px;
}
.homeHide {
  display: none;
}
tr:nth-child(odd) {
  background-color: white;
}

.home {
  width: 300px;
  position: absolute;
  top: 15px;
  right: 15%;
}
.homeCell {
  padding: 0.75rem;
  font-size: 10pt;
}
.homeCell > a {
  color: #055498;
}
.posCell {
  width: 2%;
}
.scoreCell {
  width: 2%;
}
.sponsorHome {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
/* Main Styles */
td.up.blackTee {
  color: #000;
}
td.down.blackTee {
  color: #000;
}
th.PadLeft {
  padding-left: 10px;
}
img.titliestLogo {
  height: 12px;
  margin-bottom: 1px;
  margin-left: 10px;
}
th.playersName-score {
  padding-left: 10px;
}
td.centerTime {
  text-align: center;
}
th.Timecenter {
  text-align: center;
}
td.drawName > a {
  color: #000;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
  position: relative;
  top: 3px;
}
.title {
  text-align: center;
  background-color: #95c93d;
  color: white;
  margin-left: 0;
  margin-right: 0;
}
.title > .col > h3 {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize;
  background-color: #95c93d;
  text-align: left;
  font-weight: 400;
}
.tLogo {
  width: 65px;
  margin-top: -5px;
  margin-right: 15px;
}
td.catD {
  background: #95c93d;
  color: #fff;
}
.sponLogo {
  margin-top: 15px;
  float: right;
  padding-right: 27px;
  color: white;
  font-size: 12pt;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  color: white !important;
  position: sticky;
  top: 0;
  background-color: #7ba639;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
  padding-left: 10px;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  text-align: left;
}
td:nth-child(3) {
  text-align: left;
}
.odd {
  background-color: white !important;
  padding-bottom: 0;
  padding-top: 0;
}
.even {
  background-color: #c8e49a !important;
  padding-bottom: 0;
  padding-top: 0;
}
tr:nth-child(even) {
  background-color: #c8e49a;
}
.titlest {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
td {
  font-size: 1rem;
  padding: 0.75rem;
}
td > a {
  /* color: #055498; */
  color: #000;
}
tr > :nth-child(2) {
  /* color: #337ab7; */
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 10px;
  display: block;
  max-width: 58px;
  /* margin-left: auto; */
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .home {
    /* width: 350px; */
    width: 100% !important;
    position: inherit !important;
    top: 30px;
    /* top: -80px;  */
    right: 30%;
  }
  .home > div > div {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .home {
    position: inherit;
    width: 100%;
  }
  .homeLeader {
    margin-left: -40px;
  }
}
@media only screen and (max-width: 480px) {
  span.multiDot-size-reportgender {
    font-size: 30px!important;
    line-height: 0px !important;
    position: relative;
    top: 8px;
  }
  .tableMob {
    width: 275%;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  ::v-deep tr > :nth-child(2) {
    font-size: 10pt;
  }
  ::v-deep th {
    font-size: 10pt;
  }
  ::v-deep td {
    font-size: 10pt;
    padding: 0.75rem;
  }
  ::v-deep .dropdown {
    width: 100%;
    padding-right: 12px;
  }
  ::v-deep .HideMovResult {
    display: none;
  }
}
</style>