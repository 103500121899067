<template>
  <div>
        <!-- <div class="schedule_block">
          <b-form-select v-model="id" class="schedule-dropdown" v-on:change="changeYear(id)">
            <b-form-select-option 
              class="opt-arrow"
              v-for="(value, index) in data.slice().reverse()"
              :key="index"
              :value="value.code"
            >{{value.desc}}</b-form-select-option>
          </b-form-select>
        </div> -->
    <b-row>
      <b-col lg='6' md='12' class="statPad">
        <div>
          <b-row class="statRank">
            <b-col class="outerBox" lg="4" sm="4">
              <div class="statImgBack">
                <b-img
                  v-if="didLoad"
                  class="playerImg"
                  :src="
                    config.VUE_APP_PLAYER_IMAGE + 'media/photos/' +
                    statdata.oom.oom_entry[0].playerno + '.jpg'
                  "
                 @error="pictureLoadingError"
                ></b-img>
                <b-img v-else :src="backupSrc" class="dummyImage"></b-img>
                <div class="rankOne">
                  Rank {{ statdata.oom.oom_entry[0].pos }}
                </div>
              </div>
            </b-col>
            <b-col class="rOneInfo" lg="8" sm="8">
              <h3>
                {{ statdata.oom.oom_entry[0].forename }}
                {{ statdata.oom.oom_entry[0].surname }}
                <span>
                  <img
                    class="flag rOneFlag"
                    :src="
                      (config.VUE_APP_FLAG_URL +
                        statdata.oom.oom_entry[0].nationality)
                        | lowercase
                    "
                  />
                </span>
              </h3>
              <h3 class="sPoints">
                {{ statdata.oom.oom_entry[0].oom_value }}
              </h3>
              <h3 class="rTitle">
                {{ statdata.full_name }}
              </h3>
              <h3 class="playerInfo">
                <b-nav-item :to="'/player-profiles/' + statdata.oom.oom_entry[0].playerno + '/'" class="playerLink">
                  More about this player
                  <font-awesome-icon
                    class="icon"
                    :icon="['fa', 'chevron-right']"
                  />
                </b-nav-item>
              </h3>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col lg='6' md='12'>
        <b-row>
          <b-col class="fullRank">
            <b-nav-item class="fullLink" :to="{
                name: 'fullStats',
                query: { 
                  id: statdata.season_code, 
                  full: false,
                  statdata: statdata.oom_code
                },
              }"
            > View Full Ranking
              <font-awesome-icon class="icon" :icon="['fa', 'chevron-right']"
            /></b-nav-item>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="blockList">
              <h3 class="rTag">
                {{ statdata.full_name }}
              </h3>
            </div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(sTable, index) in statdata.oom.oom_entry.slice(0, 4)"
                  :key="index"
                >
                  <td>
                    {{ sTable.pos }}
                  </td>
                  <td>
                    <b-nav-item :to="'/player-profiles/' + sTable.playerno + '/'" class="Stable-name">{{ sTable.name }}</b-nav-item>
                  </td>
                  <td>
                    <span>
                      <img
                        class="flag"
                        :src="
                          (config.VUE_APP_FLAG_URL +
                            sTable.nationality)
                            | lowercase
                        "
                      />
                    </span>
                  </td>
                  <td class="oomValue">
                    {{ sTable.oom_value }}
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "miniStat",
  props: ['config', "statcode", "id", "year", "data"],
  data() {
    return {
      backupSrc: 'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/09/Avatar_Female.jpeg',
      didLoad: true,
      statdata: []
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
  methods: {
    pictureLoadingError() {
      this.didLoad = false;
    },
    changeYear: function(id) {
      axios
        .get(
          process.env.VUE_APP_TIC_BASE +
            id +
            "/" +
            id +
            "-stats-stats-" +
            this.statcode +
            ".json?randomadd=" +
            new Date().getTime()
        )
        .then((response) => {
          this.statdata = response.data;
        })
    }
  },

  mounted() {
    return axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.id +
          "/" +
          this.id +
          "-stats-stats-" +
          this.statcode +
          ".json?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.statdata = response.data;
        // console.log( this.statdata)
      })
  }
};
</script>

<style scoped>
img.dummyImage {
  width: 100%;
  height: 200px;
}
td.oomValue {
  font-size: 1rem;
}
li.Stable-name > a {
  font-size: 1rem;
}
li.playerLink > a:hover {
  text-decoration: underline;
}
.flag {
  width: 30px;
  box-shadow: 0 0 1px #555;
  position: relative;
  top: -3px;
}
li {
  list-style: none;
}
tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.05);
}
td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0 solid #dee2e6;
}
table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.nav-link {
  color: black;
  padding: 0;
}
.blockList {
  background-color: #7ba639;
  padding: 0 0 0 10px;
  margin-top: 3px;
}
.rTag {
  color: #fff;
  padding: 15px 0 15px 0;
  font-size: 13pt;
  margin-top: 4px;
  margin-bottom: 0;
  font-weight: 500;
}
.fullRank {
  text-align: right;
  padding-right: 15px;
  padding-bottom: 10px;
}
.fullLink > a {
  color: #7ba639;
  font-size: 1rem;
}
.statRank {
  margin-top: 37px;
  margin-bottom: 3em;
}
.outerBox {
  background-color: #eeb500;
  padding: 0;
}
.statImgBack {
  background-color: #96c93d;
  height: 200px;
}
.playerImg {
  width: 190px;
  position: absolute;
  top: -53px;
  left: 0;
  min-height: 253px;
}
.rankOne {
  color: #fff;
  position: absolute;
  bottom: 17px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15pt;
}
.rOneInfo {
  background-color: #f2f2f2;
}
.rOneFlag {
  margin-top: 10px;
  float: right;
}
h3 {
  clear: both;
  color: #24292e;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 15px;
}
.sPoints {
  font-size: 44pt;
  font-weight: 500;
  color: #7ba639;
}
.rTitle {
  font-size: 15pt;
  color: #999;
  font-weight: 400;
}
.playerInfo {
  font-size: 15pt;
}
.playerLink > a {
  color: #7ba639;
  font-weight: 400;
}
@media only screen and (max-width: 990px) { 
  .statPad {
    padding-left: 30px;
    padding-right: 30px;
  }
  .playerImg {
    width: 130px;
    position: absolute;
    top: 26px;
    left: 0;
  }
  img.dummyImage {
    width: 200px;
    height: 200px;
  }  
  .rankOne {
    color: #fff;
    position: absolute;
    top: -30px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15pt;
    background: #edb503;
    width: 100%;
    height: 30px;
    
  }
}

@media only screen and (max-width: 768px) {
  .playerImg {
    width: 27%;
  }
}
@media only screen and (max-width: 550px) {
  ::v-deep .playerImg {
    width: 70%!important;
    top: -15px;
    left: 45px;
  }
}
</style>
