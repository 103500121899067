<template>
  <div>
    <div id="oomPage">
      <div>
        <div>
          <template v-if="$route.fullPath.includes('letas-order-of-merit')">
            <b-img
              class="splash"
              :src="
                'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/07/July.png'
              "
            ></b-img>
          </template>
          <template v-else>
            <b-img
              class="splash"
              :src="config.VUE_APP_WP_DIGITAL_OCEAN + '2020/01/oom-1.png'"
            ></b-img>
          </template>
        </div>
        <div
          v-if="om === 'LO' || om === 'LK' || om === 'LR'"
          class="slider mobHide"
        >
          <b-container>
            <b-row>
              <b-col>
                <OomSlider
                  :oomKey="om"
                  :oom="currentOom"
                  :season="Year"
                  :config="config"
                />
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
      <div v-if="om === 'WR'" class="rolexTop"></div>
      <b-container class="topSec">
        <div>
          <b-row class="threeDropdown">
            <b-col>
              <b-dropdown
                v-if="cTab === 'RANKING'"
                class="year"
                :class="{ PTB: this.om === 'PT' && Year >= 2020 }"
                :text="currentOom.full_name"
              >
                <b-dropdown-item
                  v-on:click="pickStat"
                  v-for="(stat, index) in statCodes"
                  :key="index"
                  :title="stat.code"
                  >{{ stat.full_name }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col>
              <div v-if="cTab === 'RANKING'"
                class="schedule-dropdown year"
                :class="{ PTB: this.om === 'PT' && Year >= 2020 }"
              >
                <b-form-select  
                  v-model="Year" 
                  v-on:change="changeYear(Year)"
                >
                  <b-form-select-option 
                    class="opt-arrow"
                    v-for="(value,
                    index) in seasons.seasons.seasons_entry.slice().reverse()"
                    :key="index"
                    :value="value.code"
                  >{{value.desc}}</b-form-select-option>
                </b-form-select>
              </div>
            </b-col>
            <b-col>
              <b-dropdown
                v-if="om === 'PT' && Year >= 2020"
                class="year"
                :class="{ PTB: this.om === 'PT' && Year >= 2020 }"
                :text="cTab"
              >
                <b-dropdown-item
                  v-on:click="changePage"
                  v-for="(rank, index) in tabThree"
                  :key="index"
                  :title="rank.title"
                  >{{ rank.title }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
          <span v-if="om === 'WR'">
            <b-nav-item
              href="https://www.rolexrankings.com/rankings"
              target="_blank"
              class="viewFull"
              >View Full Rankings</b-nav-item
            >
          </span>
        </div>
        <span v-if="cTab === 'RANKING'">
          <b-img
            v-if="om === 'PT' && Year >= 2020"
            class="costaImg"
            :src="
              config.VUE_APP_WP_DIGITAL_OCEAN +
                '/2021/03/LET20-LOGO-Race-to-Costa-del-Sol-Official-Rankings-Full-Color.png'
            "
          />
          <h2 class="racetocosta-text" v-if="om === 'PT' && Year >= 2020">
            The Order of Merit is a season-long competition to crown the
            Ladies European Tour’s number one player.
          </h2>
        </span>
      </b-container>
      <b-container v-if="cTab === 'RANKING'">
        <template v-if="currentOom !== null && noData == 'N'">
          <div class="main" :class="{ PT: this.om === 'PT' && Year >= 2020 }">
            <h3>
              {{ currentOom.full_name }}
              <span class="following-text">{{
                currentOom.last_tournament
              }}</span>
            </h3>
          </div>
          <table class="tableWidth">
            <thead class="nonPT" :class="{PTCOLOR: om === 'PT' && Year >= 2020}">
              <tr>
                <th>Pos</th>
                <th>Name</th>
                <th>Nat</th>
                <template v-if="om == 'WR'">
                  <th>Avg.Pts</th>
                  <th>Tot.Pts</th>
                </template>
                <template v-else>
                  <th>{{value_2_head}}</th>
                </template>
                <th>Played</th>
                <th class="hideMob"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(oom, index) in currentOom.oom.oom_entry" :key="index">
                <td>{{oom.pos}}</td>
                <template v-if="oom.profile == 'Y'">
                    <a :href="'/player-profiles/' + oom.playerno" target="_blank" class="oomName" :class="{PTCOLOR: om === 'PT' && Year >= 2020}">
                      <td class="oomName">{{oom.name}}</td>
                    </a>
                </template>
                <template v-else>
                  <td>{{oom.name}}</td>
                </template>
                <td>
                  <span>
                    <img
                      :class="flag"
                      :src="
                        (config.VUE_APP_FLAG_URL + oom.nationality)
                          | lowercase
                      "
                    />
                  </span>
                </td>
                <template v-if="om == 'WR'">
                  <td>{{oom.oom_value[1]}}</td>
                  <td>{{oom.oom_value[0]}}</td>
                </template>
                <template v-else>
                  <td>{{oom.oom_value}}</td>
                </template>
                <td>{{oom.tournaments_played}}</td>
                <td class="hideMob">
                  <template v-if="oom.profile == 'Y'">
                    <a :href="'/player-profiles/' + oom.playerno" target="_blank">
                      <span class="iconRight">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fa', 'chevron-right']"
                        />
                      </span>
                    </a>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else-if="previousOom !== null && noData == 'N'">
          <table class="tableWidth">
            <thead class="nonPT" :class="{PTCOLOR: om === 'PT' && Year >= 2020}">
              <tr>
                <th>Pos</th>
                <th>Name</th>
                <th>Nat</th>
                <template v-if="om == 'WR'">
                  <th>Avg.Pts</th>
                  <th>Tot.Pts</th>
                </template>
                <template v-else>
                  <th>{{value_2_head}}</th>
                </template>
                <th>Played</th>
                <th class="hideMob"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(oom, index) in previousOom.oom.oom_entry" :key="index">
                <td>{{oom.pos}}</td>
                <template v-if="oom.profile == 'Y'">
                    <a :href="'/player-profiles/' + oom.playerno" target="_blank" class="oomName" :class="{PTCOLOR: om === 'PT' && Year >= 2020}">
                      <td class="oomName">{{oom.name}}</td>
                    </a>
                </template>
                <template v-else>
                  <td>{{oom.name}}</td>
                </template>
                <td>
                  <span>
                    <img
                      :class="flag"
                      :src="
                        (config.VUE_APP_FLAG_URL + oom.nationality)
                          | lowercase
                      "
                    />
                  </span>
                </td>
                <template v-if="om == 'WR'">
                  <td>{{oom.oom_value[1]}}</td>
                  <td>{{oom.oom_value[0]}}</td>
                </template>
                <template v-else>
                  <td>{{oom.oom_value}}</td>
                </template>
                <td>{{oom.tournaments_played}}</td>
                <td class="hideMob">
                  <template v-if="oom.profile == 'Y'">
                    <a :href="'/player-profiles/' + oom.playerno" target="_blank">
                      <span class="iconRight">
                        <font-awesome-icon
                          class="icon"
                          :icon="['fa', 'chevron-right']"
                        />
                      </span>
                    </a>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <h2 class="noDataAvailable">No data Available at this time</h2>
        </template>
      </b-container>
      <b-container v-if="cTab === 'NEWS'">
        <b-row>
          <b-col
            lg="4"
            id="mobilePost"
            v-for="(posts, index) in news"
            :key="index"
          >
            <b-nav-item :to="posts.slug">
              <div class="outerImg">
                <b-img
                  :class="postImg"
                  :src="posts.better_featured_image.source_url"
                  fluid
                  alt="Responsive image"
                />
              </div>
              <div class="miniWrap">
                <h3 v-html="posts.title.rendered" class="homeNews"></h3>
              </div>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-if="cTab === 'ABOUT'">
        <span v-html="about.content.rendered"></span>
      </b-container>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import OomSlider from "@/components/oomSlider.vue";

export default {
  name: "oom",
  components: { OomSlider },
  props: ["config"],
  data() {
    return {
      currentOom: [],
      previousOom: [],
      seasons: [],
      Year: this.$route.query.id,
      year: parseInt(this.$route.query.id) - 1,
      flag: "flag",
      id: parseInt(this.$route.query.id) - 1,
      om: this.$route.query.oom,
      PT: "PT",
      wpPage: [],
      cTab: "RANKING",
      statCodes: [],
      rolex: { value: "WR", title: "Rolex Ranking" },
      tabThree: [{ title: "Rankings" }, { title: "News" }, { title: "About" }],
      news: [],
      about: [],
      value_2_head: '',
      noData: ''
    };
  },
  computed: {
    currentStat: function() {
      return this.statCodes.filter(om => !om.value.indexOf(this.om));
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    }
  },
  methods: {
    changePage: function(event) {
      var cTab = event.target.getAttribute("title");
      if (cTab === "NEWS")
        return (
          (this.cTab = cTab),
          axios
            .get(
              process.env.VUE_APP_WPAPI_URL +
                "wp/v2/posts?categories=249&per_page=99"
            )
            .then(response => (this.news = response.data))
        );
      else if (cTab === "RANKING") return (this.cTab = cTab);
      else if (cTab === "ABOUT")
        return (
          (this.cTab = cTab),
          axios
            .get(process.env.VUE_APP_WPAPI_URL + "wp/v2/pages/168421")
            .then(response => (this.about = response.data))
        );
    },
    pickStat: function(event) {
      var om = event.target.getAttribute("title");
      return (
        (this.om = om),
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              this.Year +
              "/" +
              this.Year +
              "-ooms-oom-" +
              om +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then(response => (
            this.currentOom = response.data,
            this.value_2_head = response.data.value_2_head
          ))
      );
    },
    changeYear: function(Year) {
      return (
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              Year +
              "/" +
              Year +
              "-ooms-oom-" +
              this.om +
              ".json?randomadd=" +
              new Date().getTime()
          )
          .then(response => {
            if (Object.keys(response.data).length) {
              this.currentOom = response.data,
              console.log("Data is Available")
              this.noData = 'N'
            } else {
              this.noData = 'Y'
              console.log("Data is Not Available")
            }
            this.value_2_head = response.data.value_2_head
          })
      );
    }
  },

  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.Year +
          "/" +
          this.Year +
          "-ooms-oom-" +
          this.om +
          ".json?randomadd=" +
          new Date().getTime()
      )
      .then(response => {
        if (Object.keys(response.data).length) {
          this.currentOom = response.data,
          console.log("Data is Available")
          this.noData = 'N'
        } else {
          this.noData = 'Y'
          console.log("Data is Not Available")
        }
        this.value_2_head = response.data.value_2_head
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            this.year +
            "/" +
            this.year +
            "-ooms-oom-" +
            this.om +
            ".json?randomadd=" +
            new Date().getTime()
        );
      })
      .then(response => {
        this.previousOom = response.data;
        this.value_2_head = response.data.value_2_head
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
          this.Year + '/' +
          "tmticx?field=Y&fields=ooms&fields=stats&randomadd=" +
          new Date().getTime()
        );
      })
      .then(response => {
        this.statCodes = response.data.ooms.ooms_entry;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            "tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
            new Date().getTime()
        );
      })
      .then(response => {
        this.seasons = response.data;
        return axios.get(process.env.VUE_APP_WPAPI_URL + "wp/v2/pages/130");
      })
      .then(response => {
        this.wpPage = response.data;
      });
  }
};
</script>

<style scoped>
h2.noDataAvailable {
  font-size: 2rem;
  color: #95c93d;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}
::v-deep tr:hover {
  background-color: rgba(0,0,0,.03);
  cursor: pointer;
}
::v-deep .carousel-control-prev, .carousel-control-next{
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 1!important;
  transition: opacity 0.15s ease;
  margin-top: 4em;
}
::v-deep .carousel-control-prev, .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 1!important;
  transition: opacity 0.15s ease;
}
::v-deep span.carousel-control-next-icon, ::v-deep span.carousel-control-prev-icon {
  background-image: none;
  border-bottom: 6px solid;
  border-left: 6px solid;
  color: #fff!important;
  width: 30px;
  height: 30px;
}
.schedule-dropdown.year > select {
  background-color: #95c93d;
}
::v-deep .custom-select {
  width: 300px;
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  padding: 10px;
  color: #fff;
  /* text-transform: uppercase; */
  top: -50px;
  position: relative;
  left: -20px;
  text-align: left;
  height: 44px;
  background: #fff url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/DownArrow.png') right 0.75rem center/12px 7px no-repeat;
}
.tableWidth {
  width:100%;
}
::v-deep  a.oomName {
  color: #000;
}
a.oomName:hover {
  text-decoration: none;
  color: #7ba639;
}
::v-deep .iconRight:hover {
  color: #7ba639;
}
::v-deep span.iconRight {
  color: black;
}
::v-deep td.statsLabel.nameLink > a:hover{
  color: #7ba639;
  text-decoration: none;
  cursor: pointer;
}
:v-deep tr:hover {
  background-color: #f6f5f5!important;
  cursor: pointer;
}
.PTCOLOR {
}
.main.PT > h3 {
  background: #3c974c;
}
::v-deep th.statsLabel.PTCOLOR {
  background: white;
}
::v-deep thead.PT > tr {
  background: white !important;
}
td.NameRank > a {
  color: #000;
  text-decoration: underline;
  font-weight: 400;
}
::v-deep thead > tr {
  background-color: #7ba639 !important;
  color: #fff;
}
::v-deep .table th,
.table td {
  border-top: 0px solid #dee2e6 !important;
}
::v-deep thead.PT > tr {
  background-color: #fff !important;
  color: #000;
}
::v-deep thead.nonPT > tr {
  background-color: #7ba639 !important;
  color: #fff;
}
::v-deep tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.03);
}
::v-deep th.statsLabel {
  font-weight: 400;
}
::v-deep td.statsLabel > a {
  color: #000;
}
h2.racetocosta-text {
  text-align: center;
  font-size: 12pt;
  margin-top: 0;
  margin-bottom: 0;
}
.splash {
  width: 100%;
  max-height: 600px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
::v-deep th {
  font-size: 1rem;
  padding: 0.75rem;
  border-top: 0px solid #dee2e6 !important;
  font-weight: 400;
  background-color: #7ba639;
}
::v-deep td {
  font-size: 1rem;
  padding: 0.75rem;
  border-top: 0px solid #dee2e6 !important;
}
::v-deep thead > tr:first-child {
  background-color: white;
}
.main > h3 {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  background-color: #95c93d;
  font-weight: 400;
}
.PT > h2 {
  background-color: #3c974c !important;
}
.PT {
  color: #000 !important;
}
.PT:hover {
  color: #3c974c !important;
  text-decoration: underline;
}
.slider {
  margin-top: -550px;
}
.costaImg {
  margin: auto;
  display: block;
  width: 300px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.topSec {
  padding-top: 100px;
  padding-bottom: 50px;
}
::v-deep .year > .btn {
  width: 300px;
  -webkit-appearance: none;
  background-color: #95c93d;
  border: none;
  border-radius: 0;
  padding: 10px;
  color: #fff;
  /* text-transform: uppercase; */
  top: -50px;
  position: relative;
  left: -20px;
  text-align: left;
}
::v-deep .year > .btn::after {
  float: right;
  margin-top: 12px;
}
.dropdown {
  padding: 0 10px;
}
::v-deep .PTB > .btn {
  background-color: #3c974c;
}
.outerImg {
  height: 210px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.125);
  /* border-radius: 0.25rem; */
}
.miniWrap {
  position: absolute;
  bottom: 0px;
  width: 80%;
}
.homeNews {
  color: white;
  background: rgba(60, 151, 76, 1) !important;
  z-index: 99;
  font-size: 11pt;
  opacity: 0.8;
  height: 82px;

  overflow: hidden;
  text-overflow: ellipsis;
  padding: 14px 14px 15px 17px;
  vertical-align: middle;
  text-transform: capitalize;

  margin-top: -42px;
}
.rolexTop {
  margin-top: -95px;
}
.viewFull {
  width: 300px;
  -webkit-appearance: none;
  background-color: #95c93d;
  border: none;
  border-radius: 0;
  padding: 2px;
  color: #fff;
  /* text-transform: uppercase; */
  top: -93px;
  position: relative;
  left: 630px;
  text-align: left;
  font-size: 1rem;
}
.viewFull > a {
  color: white;
}
li {
  list-style: none;
}
.mobHide {
  display: block;
}
.mobShow {
  display: none;
}
.following-text {
  float: right;
  padding-right: 10px;
}
@media only screen and (max-width: 1024px) {
  .homeNews {
    font-size: 1em;
  }
}
@media only screen and (max-width: 990px) {
  ::v-deep .slider {
    margin-top: -480px;
  }
  ::v-deep .slider {
    margin-top: -480px;
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .custom-select {
    width: 300px;
    left: -10px;
    text-align: left;
    height: 42px;
    margin-bottom: 4px;
    margin-top: 5px;
  }
  .threeDropdown {
    display: block;
  }
  .miniWrap {
    margin-top: -104px;
  }
  .homeNews {
    height: 155px;
  }
  .mobHide {
    display: none;
  }
  ::v-deep .year > .btn {
  }
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
  }
  .following-text {
    float: none;
    padding-right: 0px;
    display: block;
  }
}
@media only screen and (max-width: 520px) {
  ::v-deep td.hideMob {
    display: none;
  }
  ::v-deep th.hideMob {
    display: none;
  }
  ::v-deep .year > .btn {
    width: 100%;
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
    top: -43px;
    position: relative;
    left: 0;
    text-align: left;
  }
  ::v-deep .custom-select {
    width: 95%;
    left: 0;
    text-align: left;
    height: 42px;
    margin-bottom: 4px;
    margin-left: 10px;
  }
  .dropdown {
    display: block;
  }
  ::v-deep th.RolexLable {
    display: none;
  }
  ::v-deep td.RolexLable {
    display: none;
  }
  #mobilePost {
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .miniWrap {
    margin-top: -46px;
  }
  .homeNews {
    height: 52px;
  }
  ::v-deep .year > .btn {
    width: 100%;
  }
  .dropdown {
    display: block;
  }
  ::v-deep th {
    padding: 0.45rem;
  }
  ::v-deep .dropdown {
    padding: 0 10px;
    margin-bottom: 1em;
  }
  ::v-deep .topSec {
    padding-bottom: 0px;
  }
}
</style>
