<template>
  <div class="reports" v-if="showTourn">
    <div
      class="main"
    >
      <ReportTable :data="data" :home="home" :title="title" :report="report" :season='season' :code='course' :takeover='takeover' :config="config" :team='teamEvent' :live='liveOn' :match='match'/>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import ReportTable from "@/components/reportTable.vue";

export default {
  name: "miniLeader",
  props: ["season", "course", "report", "title", 'takeover', 'config'],
  components: {
    ReportTable,

  },
  data() {
    return {
      tm_params: [],
      data: [],
      home: true,
      showTourn: true,
      days_away: 0
    };
  },
  methods: {
    updateStuff: function() {
      axios
        .get(this.reportTitle)
        .then((response) => {
          this.data = response.data;
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              this.tm_params.tm_params.season_code +
              "/" +
              this.tm_params.tm_params.season_code +
              "-" +
              this.tm_params.code +
              "-tmticx.json?randomadd=" +
              new Date().getTime()
          );
        })
        .then((response) => {
          this.tm_params = response.data;
        });
    },
  },
  computed: {
    liveOn: function(message) {
      if (this.live === "Y") message = " - Livescoring is on";
      else message = " - " + this.title;
      return message;
    },
    reportTitle: function(url) {
      if (this.report.indexOf("tmentry") !== -1) {
        url =
          process.env.VUE_APP_TIC_BASE +
          this.season +
          "/" +
          this.season +
          "-" +
          this.course +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.report.indexOf("tmscore") !== -1) {
          var subRep2 = this.report.lastIndexOf("params=") + 7;
          var indNext2 = this.report.indexOf("~", subRep2);
          var repParams = this.report.substr(subRep2, indNext2 - subRep2);

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
          } else {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.report.indexOf("tmlsmon") !== -1) {
          {
            url =
              process.env.VUE_APP_TIC_BASE +
              this.season +
              "/" +
              this.season +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
          }
        } else if (this.report.indexOf("tmdraw") !== -1) {
          var subRep = this.report.lastIndexOf("round=") + 6;
          var indNext = this.report.indexOf("~", subRep);
          var drawRnd = this.report.substr(subRep, indNext - subRep);
          url =
            process.env.VUE_APP_TIC_BASE +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.report.indexOf("tmresult") !== -1) {
          var subRep1 = this.report.lastIndexOf("result=") + 7;
          var indNext1 = this.report.indexOf("~", subRep1);
          var resNum1 = this.report.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            process.env.VUE_APP_TIC_BASE +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            process.env.VUE_APP_TIC_BASE +
            this.season +
            "/" +
            this.season +
            "-" +
            this.course +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
    },
    teamEvent:function(team){
      var array = this.data
      if(array['team_scores'] !== undefined)
      team = 'Y'
      else
      team = 'N'
      return team
    },
  },
  created() {
    setInterval(this.updateStuff, 120000);
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +"tmticx?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.tm_params = response.data;
        this.match = response.data.match_play;
        this.days_away = response.data.days_away;
        if (this.days_away > 3 || this.days_away < -2 || this.tm_params.tt_code == '34') {
          this.showTourn = false
        }
        return axios.get(this.reportTitle);
      })
      .then((response) => {
        this.data = response.data;
      });
  },
};
</script>
<style scoped>
::v-deep tr.homeTd-size.homeTd-sizehover {
  height: 40px;
}
::v-deep .MPRound_Name {
  background-color: #95c93d!important;
  color: #ffffff;
  margin-top: 0;
  text-align: center;
  padding: 6px;
  margin-bottom: 0;
}
::v-deep td.wineurope {
  background-color: rgb(37, 37, 149)!important;
  color: #fff!important;
}
::v-deep td.winusa {
  background-color: rgb(165, 42, 42)!important;
  color: #fff!important;
}
::v-deep .home.Matchwidth {
  width: 458px !important;
}
::v-deep .overallSection {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 0!important;
}
::v-deep th.LeftAligned {
  text-align: left !important;
  width: 143px;
}
::v-deep tbody {
  border-bottom: 0px solid #fff;
}
</style>