<template>
  <div>
    <div class="newSearch">
      <b-container>
        <b-jumbotron :header="'Search Results for:  \'' + filter + '\''">
          <div class="searchBlock">
            <b-form-input
              class="seachInput"
              v-model="text"
              placeholder="Search"
              v-on:keyup.enter="onEnter"
            ></b-form-input>
            <b-button
              @click="onEnter()"
              class="sIcon"
            >
              <span class="searchBtn">Search</span>
            </b-button>
          </div>
        </b-jumbotron>
      </b-container>
    </div>
    <template v-if="loading !== true">
      <div class="loader"></div>
    </template>
    <b-container v-if="loading == true">
        <b-row>
            <b-col col="12" lg="8" md="12" sm="12">
              <b-table
                v-if="filter"
                :items="allposts"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                stacked="md"
                show-empty
                small
                :filter="filter"
              >
                <template #cell(better_featured_image)="row">
                  <b-nav-item :to="row.item.slug">
                    <h2 class="titleSearch" v-html="row.item.title.rendered"></h2>
                  </b-nav-item>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
            <b-col col="12" lg="4" md="12" sm="12" class="hidMob">
                <Newssidebar />
            </b-col>
        </b-row>
        <b-row class="hideDesk">
          <b-col col="12" lg="12" md="12" sm="12">
              <Newssidebar />
          </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
// import apiCall from "@/services/api.js";
import axios from "axios";
import Newssidebar from "@/components/newsSidebar.vue";
export default {
  name: "search",
  components:{
    Newssidebar
  },
  data() {
    return {
      allposts: [],
      filter: this.$route.query.search,
      fields: [{ key: "better_featured_image", label: "" }],
      filterOn: [],
      text: this.$route.query.search,
      loading:'',
      perPage: 10,
      currentPage: 1,
    };
  },
  methods: {
    onEnter: function() {
      window.location.href = "/search?search=" + this.text;
    },
  },
  computed: {
    setDev: function() {
      return process.env.VUE_APP_WPAPI_URL+"wp/v2/posts?search=" + this.$route.query.search + "&per_page=100&_embed";
    },
    rows() {
      return this.allposts.length
    }
  },
  mounted() {
    setTimeout(() => {
    axios
      .get(this.setDev + "?randomadd=" + new Date().getTime())
      .then(response => (
        this.allposts = response.data,
        this.loading = true
      ));},1000)
  },
};
</script>

<style scoped>
::v-deep .btn-secondary {
  color: #fff;
  background-color: #95c93d;
  border-color: #95c93d;
}
::v-deep button.btn.sIcon.btn-secondary {
  background: #95c93d;
  margin-top: 16px;
  height: 38px;
}
::v-deep .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
::v-deep.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
::v-deep .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #95c93d;
  border-color: #95c93d;
}
::v-deep .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #95c93d;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #09a54a; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
}
::v-deep .jumbotron {
  padding: 2rem 1rem;
  margin-bottom: -9rem;
  background-color: transparent;
  border-radius: 0.3rem;
}
::v-deep .table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
  border-top: 0px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  display: none;
}
h2.titleSearch {
  color: #24292e;
  font-size: 24px;
}
.searchBlock {
  display:inline-flex;
  padding-bottom: 2em;
}
a.btn.sIcon.btn-secondary {
  background: #95c93d;
  margin-top: 15px;
  height: 38px;
}
.form-control {
  display: block;
  width: 283px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #95c93d;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
span.searchBtn {
 background-color: #95c93d; 
}
h2 {
  font-size: 30px;
}
::v-deep p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 14px !important;
}
::v-deep .jumbotron {
  padding-left: 0;
}
li {
  list-style-type: none;
  margin-bottom: 2rem;
}
.nav-link {
  padding: 0.5em 0;
}
::v-deep table {
  padding-top: 10em;
  display: block;
}
.newSearch {
  /* background-color: #eee; */
  margin-top: 75px;
  z-index: 9;  
  position: relative;
}
::v-deep .display-3 {
  font-size: 30px;
  font-weight: 500;
}
.seachInput {
  /* width: 25%; */
  width: 100%;
  display: inline-block;
  margin-top: 1em;
}
.sIcon {
  color: white !important;
  background-color: #263056;
  padding: 5px 11px;
  border-radius: 5px;
}
@media only screen and (min-width: 769px) {
  .hideDesk {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .form-control {
    display: block;
    /* width: 283px; */
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2d4d6;
    border-radius: 0rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-right: 10px;
  }
  ::v-deep a.btn.sIcon.btn-secondary {
    background: #95c93d;
    border-radius: 0;
    border-color: #95c93d;
  }
  ::v-deep .custom-select {
    width: 100%;
  }
  ::v-deep .searchBlock {
    display: flex;
    padding-bottom: 2em;
  }
  ::v-deep .sideTitle {
    font-size: 1.2rem;
    color: #95c93d;
    clear: both;
    font-weight: 400;
    margin-top: 24px;
    margin-bottom: 15px;
    margin-left: -15px;
  }
  ::v-deep .recentPosts-block {
    margin-bottom: 15px;
    width: 100%;
    display: contents;
  }
  .hidMob {
    display: none;
  }
  .newSearch {
    /* background-color: #eee; */
    margin-top: 0px;
    z-index: 9;
    position: relative;
  }
  ::v-deep .seachInput {
    width: 100%;
    display: inline-block;
    margin-top: 1em;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  ::v-deep .table.b-table.b-table-stacked-md > tbody > tr > :first-child {
    border-top-width: 1px;
  }
  ::v-deep a.btn.sIcon.btn-secondary {
    background: #95c93d;
    margin-top: 16px;
    height: 38px;
  }
}
</style>
