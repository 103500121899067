<template>
  <div id="oomSlider">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      img-width="1024"
      img-height="420"
      controls
    >
      <b-carousel-slide
        v-for="(ooms, index) in oom.oom.oom_entry.slice(0, 4)"
        :key="index"
        img-blank
        img-src="https://picsum.photos/1024/480/?image=52"
      >
        <div class="oomSlider">
          <b-row>
            <b-col>
              <div class="place">
                <b-link :to="'/letas-order-of-merits?id=' + season + '&oom=LO'">
                  <span
                    class="feedTitle"
                    :class="{ otherPos: oomKey !== 'PT' || season < 2020 }"
                  >
                    <span>
                      <b-img
                        v-if="oomKey === 'PT' && season >= 2020"
                        class="costaLogo"
                        :src="
                          config.VUE_APP_WP_DIGITAL_OCEAN +
                            '2020/05/racetocosta.png'
                        "
                      ></b-img>
                    </span>
                    {{ ooms.pos }}
                  </span>
                </b-link>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="3"
              md="12"
              cols="12"
              class="photoBack"
              :class="{ otherBack: oomKey !== 'PT' || season < 2020 }"
              :style="
                'background-image: url(' +
                  config.VUE_APP_WP_DIGITAL_OCEAN +
                  '2020/05/GeoMesh-02.png)'
              "
            >
              <b-img
                class="photo"
                :class="{ oomPic: oomKey === 'PT' }"
                :src="
                  config.VUE_APP_PLAYER_IMAGE +
                    'media/photos/' +
                    ooms.playerno +
                    '.jpg'
                "
              ></b-img>
            </b-col>
            <b-col class="info" lg="9" md="12">
              <b-row>
                <b-col lg="5">
                  <b-row>
                    <b-col>
                      <b-link :to="'/player-profiles/' + ooms.playerno" class="playerLink">
                        <h3
                          class="playerName"
                          :class="{ otherText: oomKey !== 'PT' || season < 2020 }"
                        >
                          {{ ooms.forename }}
                          {{ ooms.surname }}
                        </h3>
                      </b-link>
                      <p
                        class="yearStats"
                        :class="{ otherText: oomKey !== 'PT' || season < 2020 }"
                      >
                        {{ season }} Tournament Stats
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col v-if="page === 'home'">
                  <h5 class="moreRanking">
                    <b-nav-item
                      :to="{
                        name: 'letas-order-of-merits',
                        query: { id: season, oom: 'LO' },
                      }"
                      class="costaColor"
                    >
                      See Full Rankings List
                      <font-awesome-icon
                        class="icon"
                        :icon="['fa', 'chevron-right']"
                      />
                    </b-nav-item>
                  </h5>
                </b-col>
              </b-row>
              <b-row class="tourStats">
                <b-col>
                  <h3
                    class="oomStat"
                    :class="{ otherText: oomKey !== 'PT' || season < 2020 }"
                  >
                    {{ ooms.played }}
                    <span class="statName">
                      Tournaments Played
                    </span>
                  </h3>
                  <hr class="oomHr" />
                </b-col>
                <b-col>
                  <h3
                    class="oomStat"
                    :class="{ otherText: oomKey !== 'PT' || season < 2020 }"
                  >
                    <span v-if="ooms.wins === '0'"> </span>
                    <span v-else>
                      {{ ooms.wins }}
                    </span>
                    <span v-if="ooms.wins === '0'" class="statName"> </span>
                    <span v-else class="statName">
                      Wins
                    </span>
                  </h3>
                  <hr v-if="ooms.wins !== '0'" class="oomHr" />
                </b-col>
                <b-col>
                  <h3
                    class="oomStat"
                    :class="{ otherText: oomKey !== 'PT' || season < 2020 }"
                  >
                    {{ ooms.top_ten }}
                    <span class="statName">
                      Top Ten Finishes
                    </span>
                  </h3>
                  <hr class="oomHr" />
                </b-col>
                <b-col>
                  <h3
                    class="oomStat"
                    :class="{ otherText: oomKey !== 'PT' || season < 2020 }"
                  >
                    {{ ooms.low_round }}
                    <span class="statName">
                      Lowest Round
                    </span>
                  </h3>
                  <hr class="oomHr" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "oomSlider",
  props: ["oomKey", "oom", "season", "config"],
  data() {
    return {
      slide: 0,
      sliding: null,
      oomPic: "oomPic",
      otherPos: "otherPos",
      page: this.$route.name,
    };
  },
};
</script>

<style scoped>
.playerLink:hover {
  text-decoration: none;
}
::v-deep span.carousel-control-prev-icon {
  background-image: none;
  border-bottom: 6px solid;
  border-left: 6px solid;
  transform: rotate(45deg);
  left: -40px;
  color: #000;
  width: 30px;
  height: 30px;
}
::v-deep span.carousel-control-next-icon {
  background-image: none;
  border-bottom: 6px solid;
  border-left: 6px solid;
  transform: rotate(225deg);
  right: 40px;
  color: #000;
  width: 30px;
  height: 30px;
}
::v-deep .carousel-control-prev {
  left: -123px;
  z-index: 1;
  top: 70px;
}
::v-deep .carousel-control-next {
  right: -123px;
  z-index: 1;
  top: 70px;
}
hr.oomHrNone {
  display: none;
}
.oomSlider {
  color: black;
}
.photoBack {
  background-repeat: no-repeat;
  background-color: #3c974c;
}
.photo {
  width: 100%;
  position: absolute;
  top: -81px;
  left: 0;
}
.playerName {
  font-size: 2rem;
  color: #3c974c;
  font-weight: 400;
  /* text-transform: capitalize; */
}
.place {
  margin-top: 15px;
  text-align: right;
  padding-bottom: 8px;
  margin-right: 10px;
  position: relative;
  right: -25px;
  top: 0;
  z-index: 9999;
}
.feedTitle {
  background: #3c974c;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 11px;
  padding-bottom: 15px;
}
.costaLogo {
  width: 80px;
  margin-top: -10px;
  margin-right: 23px;
  padding-top: 9px;
}
.yearStats {
  margin-bottom: 0;
  color: #3c974c;
  font-size: 10pt;
}
.moreRanking {
  font-size: 12pt;
  margin-left: 5.5em;
  margin-top: 15px;
  text-align: right;
  float: right;
  font-weight: 400;
}
.costaColor > .nav-link {
  color: #3c974c;
}
.oomStat {
  color: #3c974c;
  font-size: 44pt;
  font-weight: 500;
  display: flex;
}
.statName {
  font-size: 9pt;
  color: #a5a5a5;
  font-weight: 400;
  margin-top: 2.5em;
  width: 70px;
  padding-left: 10px;
}
::v-deep .carousel-caption {
  right: 1%;
  left: 1%;
  text-align: left;
}
.info {
  padding-top: 2.5em;
  padding-bottom: 2em;
  background-color: #f9f9f9;
}
.oomHr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.tourStats {
  margin-top: 2em;
  padding-bottom: 1px;
}
.otherPos {
  background: #95c93d !important;
  padding-bottom: 8px !important;
}
.otherBack {
  background-color: #95c93d !important;
}
.otherText {
  color: #95c93d !important;
}
@media only screen and (max-width: 1024px) {
  #oomSlider {
    padding-top: 50px;
  }
  .photo {
    top: -21px;
  }
  ::v-deep span.carousel-control-prev-icon {
    display: none;
  }
  ::v-deep span.carousel-control-next-icon {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .photo {
    width: 185px;
    position: absolute;
    left: 0;
    bottom: 0 !important;
    top: auto;
  }
  .photoBack {
    height: 185px;
  }
  ::v-deep .carousel > .carousel-inner {
    overflow: inherit;
  }
  #oomSlider {
    padding-top: 380px;
  }
}
@media only screen and (max-width: 425px) {
  #oomSlider {
    padding-top: 580px;
  }
  .oomStat {
    color: #3c974c;
    font-size: 21pt;
    font-weight: 500;
    display: inline-grid;
    text-align: center;
  }
  .statName {
    font-size: 7pt;
    color: #a5a5a5;
    font-weight: 400;
    margin-top: 2.5em;
    width: 70px;
    padding-left: 10px;
  }
  .moreRanking {
    font-size: 12pt;
    margin-left: 5.5em;
    margin-top: 15px;
    text-align: center;
    float: none;
    font-weight: 400;
  }
  .playerName {
    text-align: center;
  }
  .yearStats {
    text-align: center;
  }
}
</style>