var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"oomSlider"}},[_c('b-carousel',{attrs:{"id":"carousel-1","img-width":"1024","img-height":"420","controls":""},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.oom.oom.oom_entry.slice(0, 4)),function(ooms,index){return _c('b-carousel-slide',{key:index,attrs:{"img-blank":"","img-src":"https://picsum.photos/1024/480/?image=52"}},[_c('div',{staticClass:"oomSlider"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"place"},[_c('b-link',{attrs:{"to":'/letas-order-of-merits?id=' + _vm.season + '&oom=LO'}},[_c('span',{staticClass:"feedTitle",class:{ otherPos: _vm.oomKey !== 'PT' || _vm.season < 2020 }},[_c('span',[(_vm.oomKey === 'PT' && _vm.season >= 2020)?_c('b-img',{staticClass:"costaLogo",attrs:{"src":_vm.config.VUE_APP_WP_DIGITAL_OCEAN +
                          '2020/05/racetocosta.png'}}):_vm._e()],1),_vm._v(" "+_vm._s(ooms.pos)+" ")])])],1)])],1),_c('b-row',[_c('b-col',{staticClass:"photoBack",class:{ otherBack: _vm.oomKey !== 'PT' || _vm.season < 2020 },style:('background-image: url(' +
                _vm.config.VUE_APP_WP_DIGITAL_OCEAN +
                '2020/05/GeoMesh-02.png)'),attrs:{"lg":"3","md":"12","cols":"12"}},[_c('b-img',{staticClass:"photo",class:{ oomPic: _vm.oomKey === 'PT' },attrs:{"src":_vm.config.VUE_APP_PLAYER_IMAGE +
                  'media/photos/' +
                  ooms.playerno +
                  '.jpg'}})],1),_c('b-col',{staticClass:"info",attrs:{"lg":"9","md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"5"}},[_c('b-row',[_c('b-col',[_c('b-link',{staticClass:"playerLink",attrs:{"to":'/player-profiles/' + ooms.playerno}},[_c('h3',{staticClass:"playerName",class:{ otherText: _vm.oomKey !== 'PT' || _vm.season < 2020 }},[_vm._v(" "+_vm._s(ooms.forename)+" "+_vm._s(ooms.surname)+" ")])]),_c('p',{staticClass:"yearStats",class:{ otherText: _vm.oomKey !== 'PT' || _vm.season < 2020 }},[_vm._v(" "+_vm._s(_vm.season)+" Tournament Stats ")])],1)],1)],1),(_vm.page === 'home')?_c('b-col',[_c('h5',{staticClass:"moreRanking"},[_c('b-nav-item',{staticClass:"costaColor",attrs:{"to":{
                      name: 'letas-order-of-merits',
                      query: { id: _vm.season, oom: 'LO' },
                    }}},[_vm._v(" See Full Rankings List "),_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fa', 'chevron-right']}})],1)],1)]):_vm._e()],1),_c('b-row',{staticClass:"tourStats"},[_c('b-col',[_c('h3',{staticClass:"oomStat",class:{ otherText: _vm.oomKey !== 'PT' || _vm.season < 2020 }},[_vm._v(" "+_vm._s(ooms.played)+" "),_c('span',{staticClass:"statName"},[_vm._v(" Tournaments Played ")])]),_c('hr',{staticClass:"oomHr"})]),_c('b-col',[_c('h3',{staticClass:"oomStat",class:{ otherText: _vm.oomKey !== 'PT' || _vm.season < 2020 }},[(ooms.wins === '0')?_c('span'):_c('span',[_vm._v(" "+_vm._s(ooms.wins)+" ")]),(ooms.wins === '0')?_c('span',{staticClass:"statName"}):_c('span',{staticClass:"statName"},[_vm._v(" Wins ")])]),(ooms.wins !== '0')?_c('hr',{staticClass:"oomHr"}):_vm._e()]),_c('b-col',[_c('h3',{staticClass:"oomStat",class:{ otherText: _vm.oomKey !== 'PT' || _vm.season < 2020 }},[_vm._v(" "+_vm._s(ooms.top_ten)+" "),_c('span',{staticClass:"statName"},[_vm._v(" Top Ten Finishes ")])]),_c('hr',{staticClass:"oomHr"})]),_c('b-col',[_c('h3',{staticClass:"oomStat",class:{ otherText: _vm.oomKey !== 'PT' || _vm.season < 2020 }},[_vm._v(" "+_vm._s(ooms.low_round)+" "),_c('span',{staticClass:"statName"},[_vm._v(" Lowest Round ")])]),_c('hr',{staticClass:"oomHr"})])],1)],1)],1)],1)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }