<template>
  <div id="upComingSlider">
    <b-button v-if="this.$route.name === 'tournaments'" class='allNews'>Upcoming Tournaments</b-button>
      <b-carousel
        id="carousel-1"
        v-model="slide"
        controls
        img-width="1224"
        img-height="300"
      >
        <b-carousel-slide
          v-for="(upC, index) in futureEventFilter.slice(0, 4)"
          :key="index"
          img-blank
          img-src="https://picsum.photos/1024/480/?image=52"
        >
          <b-row class='yellow'>
            <b-col lg='7' md='7' cols='12' class='noPad'>
              <div class='over'>
              <b-img
                v-if="upC.course_image_file_2 !== ''"
                class="courseImg"
                :src="
                  config.VUE_APP_PLAYER_IMAGE+'courses/' +
                    season +
                    '/' +
                    upC.course_code +
                    '/' +
                    upC.course_image_file_2
                "
              ></b-img>
              <b-img v-else class="courseImg" :src="config.VUE_APP_WP_DIGITAL_OCEAN+'2020/05/sliderdummycourse.png'"></b-img>
              </div>
            </b-col>
            <b-col lg='5' md='5'>
              <b-row>
                <b-col class='tourInfo'>
                  <template v-if="upC.sponsor_logo.length">
                    <b-img class='courseLogo' :src='upC.sponsor_logo'></b-img>
                  </template>
                  <template v-else>
                    <h3 class="coursenameFull">{{upC.short_name}}</h3>
                  </template>
                  <b-link :to="'/tournaments-information/' + upC.code + '/' + season">
                    <h3 class='tourDate'>
                      {{upC.dates}}
                    </h3>
                    <h4 class='tourCourse'>
                      {{upC.course}}
                    </h4>
                  </b-link>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-carousel-slide>
      </b-carousel>
  </div>
</template>

<script>
import axios from "axios";
// import teamEurope from '../views/teamEurope.vue';
export default {
  // components: { teamEurope },
  full_name: "upComing",
  props:['config'],
  data() {
    return { season: "", upComing: [] };
  },
  computed: {
    futureEventFilter: function() {
      return this.upComing.filter((upComing) => upComing.days_away > 0);
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE+"tmticx?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.season = response.data.tm_params.season_code;
        return axios.get(
          process.env.VUE_APP_TIC_BASE +
            this.season +
            "/tmticx?field=Y&fields=tournaments.tournaments_entry&randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.upComing = response.data.tournaments.tournaments_entry;
      });
  },
};
</script>

<style scoped>
::v-deep a:hover {
  color: #0056b3;
  text-decoration: none;
  cursor: pointer;
}
h3.coursenameFull {
  color: #03477a;
  text-align: center;
  margin-top: -1em;
}
::v-deep .w-100 {
  width: auto!important;
}
::v-deep .carousel-inner {
  width: 140%;
  margin-left: -20.3%;
}
.carousel-control-prev-icon, .carousel-control-next-icon {
  display: inline-block;
  width: 100px;
  height: 60px;
  background: 50% / 100% 100% no-repeat;
}
#upComingSlider{
  padding-bottom: 50px;
  padding-top: 50px;
}
.courseImg {
  width: 100%;
  height: 100%;
}
.courseLogo{
  max-width: 183px;
  /* margin-top: 70px; */
  width: 140px;
  max-height: 80px;
}
.over{
  overflow: hidden;
  height: 100%;
}
.yellow{
  background-color: #eeb500;
  margin-right: -11px;
}
.noPad{
  padding: 0;
}
.tourDate{
  color: #ffffff;
  font-weight: 400;
  font-size: 16pt;
  margin-top: 15px;
  margin-bottom: 10px;   
}
.tourCourse{
  color: #ffffff;
  font-weight: 400;
  font-size: 15pt;
  color: #ffffff;
  margin-bottom: 50px;
}
.tourInfo{
  margin: auto;
  text-align: center;
  margin-top: 10em;
}
.allNews{
  background: #95c93d;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  float: right;
  border-color: #95c93d;
}
@media only screen and (max-width: 1024px) {
  #upComingSlider {
    margin-top: 225px;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .carousel>.carousel-inner{
    overflow: inherit;
  }
  ::v-deep .carousel-control-prev, .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  h3.coursenameFull {
    margin-top: 1em;
  }
  #upComingSlider {
    padding-top: 0px;
  }
  .courseLogo {
    max-width: 183px;
    margin-top: 36px;
    width: 97px;
    max-height: 80px;
  }
  .tourDate{
    color: #ffffff;
    font-weight: 400;
    font-size: 16pt;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .tourCourse{
    color: #ffffff;
    font-weight: 400;
    font-size: 15pt;
    color: #ffffff;
    margin-bottom: 20px;
  }
  ::v-deep .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: -38px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  .allNews{
    display: none;
  }
}

</style>
