<template>
  <div id="news">
    <b-container>
      <h3 class="sideTitle">
        Recent Posts
      </h3>
      <b-row>
        <b-col
          class="col-md-12 recentPosts-block"
          v-for="(postCard, index) in posts.slice(0, 5)"
          :key="index"
        >
          <div class="outerImg">
            <b-img
              :class="postImg"
              :src="postCard.better_featured_image.source_url"
              fluid
              alt="Responsive image"
            />
          </div>
          <div class="miniWrap">
            <b-nav-item :to="postCard.slug">
            <h3 v-html="postCard.title.rendered" class="homeNews"></h3>
            </b-nav-item>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "sideBar",
  props: ["develop"],
  components: {  },
  data() {
    return {
      posts: [],
      postImg: "postImg",
    };
  },
  computed:{
    url: function (go){
      if(this.$route.path == '/blog/celebrating-the-green')
      go = process.env.VUE_APP_WPAPI_URL+'wp/v2/posts?categories=293&per_page=8'
      else go = process.env.VUE_APP_WPAPI_URL+"wp/v2/posts"
      return go
    }
  },
  mounted() {
    axios //add API Call
      .get(this.url) // Page number changed on each page based on JSON page id you are calling
      .then((response) => (this.posts = response.data));
  },
};
</script>

<style scoped>
.recentPosts-block {
  margin-bottom: 15px;
}
.wholePage {
  padding-bottom: 20%;
}
.logo {
  text-align: center;
  display: block;
  margin: auto;
  float: right;
  width: 120px;
  margin-top: 35px;
  margin-right: 15px;
}

.outerImg {
  height: 218px;
  overflow: hidden;
}
.miniWrap {
  /* position: absolute;
  bottom: 0px; */
  width: 100%;
  margin-top: -37px;
}
::v-deep .nav-link {
  padding: 0;
}
.homeNews {
  color: white;
  background: #95c93d!important;
  font-size: 11pt;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 14px 14px 15px 17px;
  vertical-align: middle;
  text-transform: capitalize;
  margin-top: 0px;
  font-weight: 400;
}
.sideTitle {
  font-size: 1.2rem;
  color: #95c93d;
  clear: both;
  font-weight: 400;
  margin-top: 24px;
  margin-bottom: 15px;
}
</style>
