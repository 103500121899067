<template>
  <div id="schd">
    <Ads class='mobHide' :page="'tour'" :adNum="'top'" :mob='false' :side='false'/>
    <div id="upComing">
      <b-container>
        <UpComingTours :config="config"/>
      </b-container>
    </div>
    <div>
      <b-container class="topSec">
        <b-row>
          <b-col lg='3' md='12' cols='12'>
            <b-form-select v-model="id" class="schedule-dropdown" v-on:change="changeYear(id)">
              <b-form-select-option 
                class="opt-arrow"
                v-for="(value,
                index) in tm_params.seasons.seasons_entry.slice().reverse()"
                :key="index"
                :value="value.code"
              >{{value.desc}}</b-form-select-option>
            </b-form-select>
            <!-- <i class="fa fa-chevron-down"></i> -->
          </b-col>
          <b-col lg='5' md='12' cols='12'>
            <a :href="wppage.acf.artwork_link" target="_blank">
              <img class="bookNowLogo" :src="wppage.acf.image_artwork">
            </a>
          </b-col>
          <b-col lg='4' md='12' cols='12'>
            <b-button
              class="dLoad"
              :href="wppage.acf.schedule_download"
              target="_blank"
              ><font-awesome-icon class="icon" :icon="['fa', 'download']" />  Download {{ id }} LETAS Schedule PDF
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <ScheduleTable :fields="fields" :id="id" :data="data" :code="tm_params.code" :config="config" :year="id"/>
    </b-container>
    <Ads class='mobHide' :page="'tour'" :adNum="'foot'" :mob='false' :side='false'/>
  </div>
</template>

<script>
import Ads from "@/components/ads.vue";
import UpComingTours from "@/components/upComingTours.vue";
import ScheduleTable from "@/components/scheduleTable.vue";
import axios from "axios";
export default {
  name: "schedule",
  props: [ 'config'],
  components: {
    Ads,
    ScheduleTable,
    UpComingTours,
  },
  data() {
    return {
      data: [],
      id: [],
      wppage: [],
      tm_params: [],
      season: [],
      // year: this.season,
      fields: [
        { key: "dates", label: "Date" },
        { key: "full_name", label: "Name", class: "NameLable" },
        { key: "course_country", label: "Location", class: "HideMob" },
        { key: "total_prize_fund", label: "Prize Fund" },
        { key: "winner_name", label: "Winner",  class: "HideMob"},
      ],
    };
  },
  methods: {
    changeYear: function(id) {
      return (
        axios
          .get(
            process.env.VUE_APP_TIC_BASE +
              id +
              "/tmticx?randomadd=" +
              new Date().getTime()
          )
          .then(
            (response) =>
              (this.data = response.data.tournaments.tournaments_entry)
          )
      );
    },
    getSeasonticx() {
      axios //add API Call
        .get(
          process.env.VUE_APP_TIC_BASE+
            this.season +
            "/tmticx?randomadd=" +
            new Date().getTime()
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => {
          (this.tm_params = response.data),
            (this.id = response.data.tm_params.season_code);
          return axios.get(
            process.env.VUE_APP_TIC_BASE +
              this.season +
              "/tmticx?randomadd=" +
              new Date().getTime()
          );
        })
        .then((response) => {
          this.data = response.data.tournaments.tournaments_entry;
        })  
    },
  },
  created() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          "tmticx?randomaddFirst=" +
          new Date().getTime()
      )
      .then(
        response => (
          this.id = response.data.tm_params.season_code, 
          this.season = response.data.tm_params.season_code, 
          console.log("this.season"),
          console.log(this.season),
          this.getSeasonticx()
        )
      );
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/84"
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.wppage = response.data
      })
  },
};
</script>

<style scoped>
::v-deep .custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/DownArrow.png') right 0.75rem center/12px 7px no-repeat;
  border: 0px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
::v-deep #upComingSlider {
  padding-bottom: 0;
  padding-top: 50px;
}
::v-deep thead {
  color: white;
  background-color: #7ba639;
  display: none;
}
::v-deep select.schedule-dropdown {
  color: #fff;
  background-color: #95c93d;
  border-radius: 0;
  width: 100%;
  height: 40px;
  border-radius: 0px!important;
  font-size: 12pt;
}
::v-deep .carousel-control-prev{
  display: none;
}
::v-deep .carousel-control-next{
  display: none;
}
img.bookNowLogo {
  text-align: center;
  margin:auto;
  display: block;
  margin-top: -61px;
}
a.btn.dLoad.btn-secondary {
  width: 100%;
}
::v-deep th.NameLable {
  padding-left: 25px!important;
}
::v-deep .carousel-inner {
  width: 140%;
  margin-left: -20%;
}
#schd {
  background-color: #fff;
  padding-bottom: 5%;
}
::v-deep button {
  color: white;
  background-color: #95c93d;
  border-radius: 0;
}
::v-deep button:hover {
  color: #95c93d;
  background-color: white;
}
.btn {
  color: white;
  background-color: #95c93d;
  border: none;
  color: #fff;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 10pt;
  width: 50%;
  height: 39px;
  line-height: 1;
  float: right;
  border-radius: 0;
}
.btn:hover{
  background-color: #43a7c6;
}
.dropdown {
  width: 100%;
}
::v-deep .dropdown-toggle {
  text-align: left;
}
::v-deep .dropdown-toggle::after {
  margin-top: 5%;
  float: right;
}
.topSec {
  padding-bottom: 2%;
  margin-top: 15px;
}

.title {
  color: #0b3f7e;
}

.allNews {
  float: right;
  background-color: #95c93d;
  border-radius: 0;
  padding: 11px 30px;
  border-color: #95c93d;
}
.mobHide {
  display: block;
}
.mobShow {
  display: none;
}
@media only screen and (max-width: 768px) {
  ::v-deep .btn {
    font-size: 10pt;
    width: 100%;
  }
  .dropdown{
    width: 100%;
    padding-bottom: 10px;
  }
  ::v-deep .dropdown-toggle::after {
    margin-top: 1.5%;
    float: right;
  }
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
  }
  img.bookNowLogo {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  ::v-deep .tourInfo {
    margin-top: 0em;
  }
  ::v-deep td>li {
    margin-top: 0;
  }
  ::v-deep .HideMob {
    display: none;
  }
  .topSecrow {
    padding-left: 15%;
  }
  ::v-deep .dropdown-toggle::after {
    margin-top: 2%;
    float: right;
  }
  div#upComing {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
