<template>
  <div  :class="{ home: home === true, takeOver: takeover == true, Matchwidth :  data.match_play == 'Y'}">

    <template v-if="data.match_play == 'Y'">
      <template v-if="data.match_play_team == 'Y'">

        <b-row class="overallSection">
          <b-col cols='5' class="Left"
          :style="
            'background-color:' +
            data.tmp_teamcolour_1
          ">{{data.tmp_teamname_1}}</b-col>
          <template v-if="data.overall.overall_entry[0].team == 'USA'">
            <b-col cols='2' class="Middle">{{data.overall.overall_entry[1].points}} - {{data.overall.overall_entry[0].points}}</b-col> 
          </template>
          <template v-else>
            <b-col cols='2' class="Middle">{{data.overall.overall_entry[0].points}} - {{data.overall.overall_entry[1].points}}</b-col> 
          </template>
          <b-col cols='5' class="Right" 
          :style="
            'background-color:' +
            data.tmp_teamcolour_2
          "
          >{{data.tmp_teamname_2}}</b-col>
        </b-row>
        <!-- <b-row v-if="data.live_scoring == 'Y'" class="overallSection">
          <b-col class="LiveScoring">
            Live :  {{proj_score.eu}} - {{proj_score.us}}
          </b-col>
        </b-row> -->

        <!-- <div class="MPRound_Name">
          <div v-if="data.pft_round == '1'">
            {{data.stmp_format_name_R1}}
          </div>
          <div v-else-if="data.pft_round == '2'">
            {{data.stmp_format_name_R2}}
          </div>
          <div v-else-if="data.pft_round == '3'">
            {{data.stmp_format_name_R3}}
          </div>
          <div v-else-if="data.pft_round == '4'">
            {{data.stmp_format_name_R4}}
          </div>
          <div v-else-if="data.pft_round == '5'">
            {{data.stmp_format_name_R5}}
          </div>
        </div> -->
      </template>
      <template v-else></template>
    </template>
    <template v-else>
      <div v-if="home === true">
        <b-container>
          <b-row class="homeLeader">
            <b-col>
              <b-img class="courseLogo" :src="data.event_sponsor_logo"></b-img>
              <h3 class="homeHead">{{ data.short_name }} <span v-if="data.live_scoring == 'Y'"> - Live Scoring is on</span></h3>
              <div class="homecourseDates">{{data.course_dates}}</div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-row v-else class="title">
        <b-col>
          <h3>
            {{ data.full_name }} - {{ title
            }}
          </h3>
        </b-col>
        <b-col
          v-if="
            tablePick === 'Score' || tablePick === 'Final' || tablePick === 'Live'
          "
        >
          <!-- <div class="sponLogo">
            <b-img
              class="tLogo"
              :src="
                config.VUE_APP_WP_DIGITAL_OCEAN + '2021/03/titleist_white.png'
              "
            ></b-img
            ><span> Indicates Golf Ball Usage</span>
          </div> -->

          <div class="sponLogo">
            <i v-b-toggle.collapse-1 class="fa-solid fa-circle-info"></i>
          </div>
        </b-col>
      </b-row>
    </template>
    <template v-if="title.includes('Scoreboard')">
        <table v-for="(round, index) in roundsArray.reverse()" :key="index">
        <thead class="MPRound_Name">
            <th colspan="11">
                {{round.round_text}} - {{ data['stmp_format_name_R' + round.round_no],}}
            </th>
        </thead>
        <thead>
            <tr v-if="home === false">
                <th class="LeftAligned">
                Name
                </th>
                <th class="centerAligned">
                Score
                </th>
                <th class="centerAligned">
                Thru
                </th>
                <th class="centerAligned">
                Score
                </th>
                <th class="RightAligned">
                Name
                </th>
            </tr>
        </thead>
        <tbody v-for="(tabledata, index) in Eds" :key="index">
            <template >
            <tr
                @click.stop="rowClicked(index)"
                :key="index"
                v-bind:class="{
                'homeTd-size': home === true,
                }"
                v-if="round.round_no == tabledata.round"
            >
                <template
                v-if="
                    (tablePick === 'Score') ||
                    (tablePick === 'Final')
                "
                >
                <td
                    class="LeftAligned"
                    :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down',
                    wineurope: tabledata.status_1 == 'W',
                    }"
                >
                    <template v-if="data.match_play_team == 'Y'">
                    {{tabledata.name_1}}
                    </template>
                    <template v-else>
                    {{ tabledata.initials_1 }} {{ tabledata.surname_1 }}
                    </template>
                </td>
                <td v-if="tabledata.status_1 === 'W'" class="centerAligned">
                    {{ tabledata.full_score_1 }}
                </td>
                <td v-else class="centerAligned">
                    <template v-if="tabledata.full_score_1.includes('DOWN')">

                    </template>
                    <template v-else>
                    {{ tabledata.full_score_1 }}
                    </template>
                </td>
                <td class="centerAligned">
                    <span v-if="tabledata.holes === ''">
                    {{ tabledata.time }}
                    </span>
                    <span v-else-if="tabledata.holes < 18">
                    {{ tabledata.holes
                    }}<span v-if="tabledata.tee_R1 > 1">*</span>
                    </span>
                    <span v-else>
                    F<span v-if="tabledata.tee_R1 > 1">*</span>
                    </span>
                </td>
                <td v-if="tabledata.status_2 === 'W'" class="centerAligned">
                    {{ tabledata.full_score_2 }}
                </td>
                <td v-else class="centerAligned">
                    <template v-if="tabledata.full_score_2.includes('DOWN')">

                    </template>
                    <template v-else>
                    {{ tabledata.full_score_2 }}
                    </template>
                </td>
                <td
                    class="RightAligned"
                    :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down',
                    winusa: tabledata.status_2 == 'W',
                    }"
                >
                    <template v-if="data.match_play_team == 'Y'">
                    {{tabledata.name_2}}
                    </template>
                    <template v-else>
                    {{ tabledata.initials_2 }} {{ tabledata.surname_2 }}
                    </template>
                </td>
                </template>
            </tr>
            </template>
        </tbody>
        </table>
    </template>
    <template v-else>
        <table v-for="(round, index) in roundsArray" :key="index">
        <thead class="MPRound_Name">
            <th colspan="11">
                {{round.round_text}} - {{ data['stmp_format_name_R' + round.round_no],}}
            </th>
        </thead>
        <thead>
            <tr v-if="home === false">
                <th class="LeftAligned">
                Name
                </th>
                <th class="centerAligned">
                Score
                </th>
                <th class="centerAligned">
                Thru
                </th>
                <th class="centerAligned">
                Score
                </th>
                <th class="RightAligned">
                Name
                </th>
            </tr>
        </thead>
        <tbody v-for="(tabledata, index) in Eds" :key="index">
            <template >
            <tr
                @click.stop="rowClicked(index)"
                :key="index"
                v-bind:class="{
                'homeTd-size': home === true,
                }"
                v-if="round.round_no == tabledata.round"
            >
                <template
                v-if="
                    (tablePick === 'Score') ||
                    (tablePick === 'Final')
                "
                >
                <td
                    class="LeftAligned"
                    :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down',
                    wineurope: tabledata.status_1 == 'W',
                    }"
                >
                    <template v-if="data.match_play_team == 'Y'">
                    {{tabledata.name_1}}
                    </template>
                    <template v-else>
                    {{ tabledata.initials_1 }} {{ tabledata.surname_1 }}
                    </template>
                </td>
                <td v-if="tabledata.status_1 === 'W'" class="centerAligned">
                    {{ tabledata.full_score_1 }}
                </td>
                <td v-else class="centerAligned">
                    <template v-if="tabledata.full_score_1.includes('DOWN')">

                    </template>
                    <template v-else>
                    {{ tabledata.full_score_1 }}
                    </template>
                </td>
                <td class="centerAligned">
                    <span v-if="tabledata.holes === ''">
                    {{ tabledata.time }}
                    </span>
                    <span v-else-if="tabledata.holes < 18">
                    {{ tabledata.holes
                    }}<span v-if="tabledata.tee_R1 > 1">*</span>
                    </span>
                    <span v-else>
                    F<span v-if="tabledata.tee_R1 > 1">*</span>
                    </span>
                </td>
                <td v-if="tabledata.status_2 === 'W'" class="centerAligned">
                    {{ tabledata.full_score_2 }}
                </td>
                <td v-else class="centerAligned">
                    <template v-if="tabledata.full_score_2.includes('DOWN')">

                    </template>
                    <template v-else>
                    {{ tabledata.full_score_2 }}
                    </template>
                </td>
                <td
                    class="RightAligned"
                    :class="{
                    'text-up': tabledata.posChang == 'up',
                    'text-down': tabledata.posChang == 'down',
                    winusa: tabledata.status_2 == 'W',
                    }"
                >
                    <template v-if="data.match_play_team == 'Y'">
                    {{tabledata.name_2}}
                    </template>
                    <template v-else>
                    {{ tabledata.initials_2 }} {{ tabledata.surname_2 }}
                    </template>
                </td>
                </template>
            </tr>
            </template>
        </tbody>
        </table>
    </template>



    <div v-if="home === true">
      <b-container>
        <b-row class="homeLeaderHome FUllLeaderboard">
          <b-col class="DrawCol">
            <b-nav-item
              class="fullNav"
              :to="{
                name: 'reports-page',
                query: {
                  url: 'https://info.letaccess.com/tic/tmdraw.cgi?tourn=' + code + '~season=' + season + '~round=' + data.round + '~',
                  code: code,
                  id: season,
                  title: 'Round ' + data.round + ' Draw',
                },
              }"
            >
              <h3 class="drawtext">Draw</h3>
            </b-nav-item>
          </b-col>
          <b-col class="LeadCol pulse">
            <b-nav-item
              class="fullNav"
              :to="{
                name: 'reports-page',
                query: {
                  url: this.report,
                  id: season,
                  code: code,
                  title: title,
                },
              }"
            >
              <h3 class="FullLeaderboard">Full Leaderboard</h3>
            </b-nav-item>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  name: "MP",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "report",
    "code",
    "season",
    "takeover",
    "config",
    "team",
    "match",
    "live",
    "RTCDS",
    "bbTeamEvent",
    "roundsplayed",
    "showGender",
    "Stableford",
  ],
  components: { 
  },
  data() {
  
    return {
      compare: [],
      up: "up",
      down: "down",
      scores: [],
      clickedRow: -1,
      clickedRowCompare: -1,
      cardID: [],
      playerCardData: [],
      toggle: '',
    };
  },
  methods: {
    get_overall: function(i) {
      this.overall = {};
      this.point_list = [];
      this.proj_score = {};
      this.data.overall.overall_entry.forEach( function(value, i) {
          console.log('IN Overall')
          if (value.nat == 'EUR') {
              console.log('In EUR')
              this.overall.eu = value
              console.log('this.overall.eu')
              console.log(this.overall.eu)
              var eu_score = parseFloat(value.points);
              var float_point = eu_score % 1;
              eu_score = Math.trunc(eu_score)
              for (i = 0; i < eu_score; i++) {
                  this.point_list.push({
                      "class": "eu"
                  });
              }
              if (float_point > 0) {
                  this.point_list.push({
                      "class": "eu-half"
                  });
              }
              var num = parseFloat(this.overall.eu.points) + parseFloat(this.prov_score.eu);
              this.proj_score.eu = Math.round(num * 10) / 10;
          } else if (value.nat == 'USA') {
              this.overall.usa = value;
              var us_score = parseFloat(value.points);
              var float_point1 = us_score % 1;
              us_score = Math.trunc(us_score)
              if (float_point1 > 0) {
                  this.point_list.push({
                      "class": "us-half"
                  });
              }
              for (i = 0; i < us_score; i++) {
                  this.point_list.push({
                      "class": "us"
                  });
              }
              var num1 = parseFloat(this.overall.usa.points) + parseFloat(this.prov_score.us);
              this.proj_score.us = Math.round(num1 * 10) / 10;
          }
      })

      var proj_diff = this.proj_score.eu - this.proj_score.us;
      this.proj_diff = Math.abs(proj_diff);


      console.log("this.proj_score.eu ==== ")
      console.log(this.proj_score.eu)
      var holder = this.proj_score.eu +this.proj_score.us;

      this.proj_list = [];

      console.log(this.proj_score)

      for (i = 0; i < this.proj_score.eu; i++) {
          this.proj_list.push({
              "class": "eu"
          });
      }

      for (i = holder; i < 28; i++) {
          this.proj_list.push({
              "class": "none"
          });
      }

      for (i = 0; i < this.proj_score.us; i++) {
          this.proj_list.push({
              "class": "us"
          });
      }
      console.log(this.proj_list)

      var keep_going = true;
      if (this.point_list.length < 28) {
          var diff = 28 - this.point_list.length;
          this.point_list.forEach(function(value, key) {
              if (value.class != this.point_list[key + 1].class) {
                  if (keep_going) {
                      for (i = 0; i < diff; i++) {
                          this.point_list.splice(key + 1, 0, {
                              "class": ""
                          });
                      }
                      keep_going = false;
                  }
              }
          })
      }
    },
    range: function(start, end) {
      if (this.roundsPlayed === 1) return this.roundsPlayed;
      else
        return Array(end - start + 1)
          .fill()
          .map((_, idx) => start + idx);
    },
    rowClickedCompare: function(index) {
      if (this.home == true) {
        console.log("TRUE");
        this.clickedRowCompare = this.reportTitle;
      } else {
        if (
          this.tablePick === "Score" ||
          (this.tablePick === "Live" && this.clickedRowCompare !== index)
        )
          this.clickedRowCompare = index;
        else this.clickedRowCompare = -1;
      }
    },
    rowClicked: function(index) {
      if (this.home == true) {
        console.log("TRUE");
        this.clickedRow = this.reportTitle;
      } else {
        if (
          this.tablePick === "Score" ||
          (this.tablePick === "Live" && this.clickedRow !== index)
        )
          this.clickedRow = index;
        else this.clickedRow = -1;
      }
    },
    playerCard: function(playerNo) {
      if (this.toggle == true) {
      this.toggle = true;
      console.log("this.toggle")
      console.log(this.toggle)
      var playerData = {};
      // var cardID = event.target.getAttribute("title");
      console.log("playerData");
      console.log(playerData);
      var cardID = playerNo;
      console.log("cardID");
      console.log(cardID);
      for (var i of this.data.scores.scores_entry) {
        console.log("in scores");
        if (i.member_no == playerNo) {
          this.playerData = i;
          console.log("playerData 998");
          console.log(this.playerData);
          break;
        }
      }
      for (var t of this.data.team_scores.team_scores_entry) {
        console.log("in scores");
        if (t.member_no == playerNo) {
          this.playerDataTeam = t;
          console.log("playerData 998");
          console.log(this.playerDataTeam);
          break;
        }
      } 
      } else {
        this.toggle = false;
        console.log("this.toggle")
        console.log(this.toggle)
      }
    }
  },
  computed: {
    tablePick: function(picked) {
      if (
        this.title.includes("Scores") ||
        this.title.includes("Scoreboard")
      )
        picked = "Score";
      else if (this.title.includes("Live")) picked = "Live";
      return picked;
    },
    roundsPlayed() {
      return parseInt(this.data.rounds_played);
    },
    roundsArray(rounds) {
        if (Array.isArray(this.data.rounds.rounds_entry)) {
            console.log('isArray')
            rounds = this.data.rounds.rounds_entry
            return rounds 
        } else {
            console.log('is not Array')
            rounds = [this.data.rounds.rounds_entry];
            return rounds
        } 
    },
    Eds(reportData) {
      if (this.home === false) {
        if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry;
        return reportData;
      } else {
        if (
          this.title.includes("Scores") ||
          this.title.includes("Scoreboard") ||
          this.title.includes("Live")
        )
          reportData = this.data.scores.scores_entry.slice(0, 5);
        return reportData;
      }
    },
  },
  watch: {
    Eds(newValue, oldValue) {
      newValue.forEach((value1, key1) => {
        oldValue.forEach((value2, key2) => {
          if (value1.id === value2.id) {
            if (key1 < key2) {
              value1.posChang = "up";
            } else if (key1 > key2) {
              value1.posChang = "down";
            }
          }
        });
      });

      console.log(newValue);
    },
    immediate: true,
    deep: true,
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
  mounted() {
    this.get_overall();
  }
};
</script>

<style scoped>
tbody:nth-child(even) > tr  {
    background-color: #c8e49a;
}
.MPRound_Name > th {
  background-color: #95c93d!important;
  color: #ffffff;
  margin-top: 1.5em;
  text-align: center;
  padding: 6px;
  margin-bottom: 20px;
}
.centerAligned {
  text-align: center;
}
::v-deep .home.Matchwidth {
  width: 458px!important
}
td.RightAligned {
  text-align: right;
}
th.RightAligned {
  text-align: right;
  padding-right: 15px;
  width: 32%;
}
td.LeftAligned {
  text-align: left!important;
}
th.LeftAligned {
  text-align: left!important;
  width: 32%;
}
td.wineurope {
  background-color: rgb(37, 37, 149)!important;
  color: #fff;
}
td.winusa {
  background-color: rgb(165, 42, 42)!important;
  color: #fff;
}
.LiveScoring {
  background-color: #000;
  padding: 6px;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.overallSection {
  padding-left: 15px;
  padding-right: 15px;
}
.Right {
  text-align: right;
  color: #fff;
  padding: 6px;
  font-size: 16px;
}
.Middle {
  text-align: center;
  background-color: #000;
  color: #fff;
  padding: 6px;
  font-size: 16px;
}
.Left {
  text-align: left;
  color: #fff;
  padding: 6px;
  font-size: 16px;
}
.MPRound_Name {
  background-color: #95c93d!important;
  color: #ffffff;
  margin-top: 1.5em;
  text-align: center;
  padding: 6px;
  margin-bottom: 20px;
}
th.MPCenter {
  text-align: center;
}
td.MPCenter {
  text-align: center;
}
::v-deep .informationCLass {
  margin-top: 0px!important;
}
::v-deep input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  display: none;
}
::v-deep td.wrapper input:checked ~ label:before {
  color: #FFC107;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 19px;
  position: relative;
  top: 5px;
  content: "\f005";
  font-family: FontAwesome;
  display: inline-block;
}
::v-deep td.wrapper label:before {
  content: "\f005";
  font-family: FontAwesome;
  display: inline-block;
  color: darkgray;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 19px;
  position: relative;
  top: 5px;
}
/* Home Styles */
/* ::v-deep .custom-checkbox .custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: transparent;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/05/Star_Deselected.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 19px;
  height: 19px;
}
::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: transparent;
  background-color: transparent;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/05/Star_Selected.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 19px;
  height: 19px;
} */
::v-deep .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  display: none;
}
::v-deep .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
    display: none;
}
.pulse {
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
span.favtextHead {
  font-size: 13pt;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 20px;
  display: block;
  margin-bottom: 20px;
  color: #03477b;
}
tr.favSec >th {
  color: white !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edb503;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
  padding-left: 10px;
}
h3.drawtext {
  font-size: 10pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
  text-align: center;
  color: #fff!important;
  padding-top: 10px;
  font-size: 1rem;
}
.DrawCol.col {
  background-color: #015598;
  padding: 0;
}
.LeadCol {
  padding: 0;
}
td.PlayerName {
  cursor: pointer;
}
td.PlayerName:hover {
  text-decoration: underline;
}
tr.compareBk {
  /* background: #03477b!important;
  color: #fff; */
}
tr.compareBk > td {
  /* color: #fff!important; */
}
tr.compareBk > td > a {
  /* color: #fff!important; */
}
::v-deep .home.takeOver {
  width: 300px;
  position: absolute;
  top: 4.55em;
  right: -0.94em;
}
.custom-control {
  position: relative;
  z-index: 0;
  display: block;
  min-height: 1.5rem;
  padding-left: 0.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
img.favStar {
  width: 19px;
  cursor: pointer;
}
.noPadLeft {
  /* padding-left: 0px; */
}
.hideRepresentsHome {
  display: none;
}
p.homePlayername {
  color: #055498;
}
td.homePlayername {
  color: #055498;
}
a.homePlayername {
  color: #055498;
}
a.homeResult {
  color: #055498 !important;
}
th.representsText {
  text-align: left !important;
}
td.representsText {
  text-align: left !important;
}
tr.homeTd-size {
  background-color: #fff !important;
  color: #055498;
  border-top: 1px solid #055498 !important;
}
tr.drawHome {
  border-top: 0px solid #055498 !important;
}
tr.drawHome > td.drawPos {
  top: 0px;
}
tr.drawHome > td > a {
  color: #055498 !important;
}
tr.homeTd-size > td {
  font-size: 11pt;
  color: #055498;
  padding-top: 0px;
  padding-bottom: 0px;
}
tr.homeTd-size > td.posnum {
  margin-top: 5px;
  margin-bottom: 4px;
}
h3.FullLeaderboard {
  font-size: 14pt;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 4px;
  text-align: center;
  background: #FF5C39;
  color: #fff!important;
  /* padding-bottom: 10px; */
  padding-top: 10px;
  font-size: 1rem;
}
.natNone {
  display: none;
}
.fullNav {
  padding-top: 0 !important;
  display: block !important;
}
.fullNav > .nav-link {
  padding: 0 1rem;
  color: white;
}
.table-responsive {
  margin-bottom: 0;
}
.courseLogo {
  width: 35%;
  padding-top: 25px;
}

.homeLeader {
  text-align: center !important;
  background-color: #035599 !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  font-size: 11pt;
  padding-bottom: 18px!important;
}
.homeLeaderHome {
  background-color: #FF5C39;
  text-align: center !important;
  color: white;
  /* width: 111% !important; */
  padding: 0 !important;
  font-size: 11pt;
}
.female {
  color: #e33;
}
.male {
  color: #037
}
span.multiDot-size-reportgender  {
  font-size: 30px!important;
  line-height: 0px !important;
  position: relative;
  top: 6px;
}
span.multiDot-size-report {
  /* float: right; */
  font-size: 60px!important;
  line-height: 0px !important;
  position: relative;
  top: 12px;
}
.homeHead {
  font-size: 11pt;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  padding-bottom: 0px;
}
.homeHide {
  display: none;
}
tr:nth-child(odd) {
  background-color: white;
}

.home {
  width: 300px;
  position: absolute;
  top: 15px;
  right: 15%;
}
.homeCell {
  padding: 0.75rem;
  font-size: 10pt;
}
.homeCell > a {
  color: #055498;
}
.posCell {
  width: 2%;
}
.scoreCell {
  width: 2%;
}
.sponsorHome {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
/* Main Styles */
td.up.blackTee {
  color: #000;
}
td.down.blackTee {
  color: #000;
}
th.PadLeft {
  padding-left: 10px;
}
img.titliestLogo {
  height: 12px;
  margin-bottom: 1px;
  margin-left: 10px;
}
th.playersName-score {
  padding-left: 10px;
}
td.centerTime {
  text-align: center;
}
th.Timecenter {
  text-align: center;
}
td.drawName > a {
  color: #000;
}
.drawPos {
  max-width: 100% !important;
  text-align: -webkit-center !important;
  margin-top: 0px !important;
  position: relative;
  top: 3px;
}
.title {
  text-align: center;
  background-color: #95c93d;
  color: white;
  margin-left: 0;
  margin-right: 0;
}
.title > .col > h3 {
  color: #fff;
  padding: 15px 0 15px 10px;
  font-size: 13pt;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 24px;
  text-transform: capitalize;
  background-color: #95c93d;
  text-align: left;
  font-weight: 400;
}
.tLogo {
  width: 65px;
  margin-top: -5px;
  margin-right: 15px;
}
td.catD {
  background: #95c93d;
  color: #fff;
}
.sponLogo {
  margin-top: 15px;
  float: right;
  padding-right: 27px;
  color: white;
  font-size: 12pt;
}
.title > p {
  font-size: 16px !important;
  margin-bottom: 0;
}
th {
  color: white !important;
  position: sticky;
  top: 0;
  background-color: #7ba639;
  font-weight: 400;
  font-size: 12pt;
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1;
  padding-left: 10px;
}
table {
  width: 100%;
}
thead > tr {
  color: white;
}
td:first-child {
  text-align: center;
}
th:first-child {
  text-align: center;
}
th:nth-child(3) {
  /* text-align: left; */
}
td:nth-child(3) {
  /* text-align: left; */
}
.odd {
  background-color: white !important;
  padding-bottom: 0;
  padding-top: 0;
}
.even {
  background-color: #c8e49a !important;
  padding-bottom: 0;
  padding-top: 0;
}
tr:nth-child(even) {
  background-color: #c8e49a;
}
.titlest {
  margin-top: -2.2px;
  margin-left: 10px;
  width: 37px;
}
td {
  font-size: 1rem;
  padding: 0.75rem;
}
td > a {
  /* color: #055498; */
  color: #000;
}
tr > :nth-child(2) {
  /* color: #337ab7; */
}
td.posnum {
  padding: 5px;
  padding-top: 4px;
  padding-bottom: 10px;
  display: block;
  max-width: 58px;
  /* margin-left: auto; */
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 0px;
}
td.posnum > span {
  padding: 5px;
  display: block;
  max-width: 58px;
}
.noPos > span {
  color: transparent;
  padding: 5px;
  display: block;
  max-width: 58px;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}
.up {
  color: #a52a2a;
}
.down {
  color: #1e90ff;
}
.text-down {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: glow-red 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-red {
  to {
    text-shadow: 0 0 10px rgba(255, 82, 82, 0.7);
  }
}

.text-up {
  background: transparent;
  text-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: glow-green 2s infinite;
  animation-iteration-count: 6;
}

@keyframes glow-green {
  to {
    text-shadow: 0 0 10px rgba(51, 217, 178, 0.7);
  }
}
.cut {
  padding-top: 6px !important;
  padding-right: 6px !important;
  padding-bottom: 6px !important;
  font-size: 19px !important;
  vertical-align: middle;
  padding-left: 8px !important;
  background: #aa0003;
  color: white;
}

@media only screen and (max-width: 1024px) {
  .home {
    /* width: 350px; */
    width: 100% !important;
    position: inherit !important;
    top: 30px;
    /* top: -80px;  */
    right: 30%;
  }
  .home > div > div {
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) { 
  .Mob {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .Desk {
    display: none;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  .homeLeader {
    margin-left: -40px;
  }
}
@media only screen and (max-width: 480px) {
  span.multiDot-size-reportgender {
    font-size: 30px!important;
    line-height: 0px !important;
    position: relative;
    top: 8px;
  }
  .tableMob {
    width: 275%;
  }
  .home {
    position: inherit;
    width: 100%;
  }
  ::v-deep tr > :nth-child(2) {
    font-size: 10pt;
  }
  ::v-deep th {
    font-size: 10pt;
  }
  ::v-deep td {
    font-size: 10pt;
    padding: 0.75rem;
  }
  ::v-deep .dropdown {
    width: 100%;
    padding-right: 12px;
  }
  ::v-deep .HideMovResult {
    display: none;
  }
}
</style>