<template>
  <div class="table-responsive">
    <div class='bar'>
      <h3 class='seasonRank'>
        {{year}} Tournament Schedule
      </h3>
    </div>
    <b-table
      :items="data"
      :fields="fields"
      :tbody-tr-class="rowClass"
      :key="index"
      class="scheduleTable"
      @row-clicked="onRowClicked"
    >
      <template #cell(full_name)="row">
        <b-link :to="'/tournaments-information/' + row.item.code + '/' + year" class="tournamentName">
          {{ row.item.full_name }}
          <span class="courseText">{{ row.item.course }}</span>
        </b-link>
      </template>
      <template #cell(total_prize_fund)="row">
        <div class="prizeFundBlock">
          {{ row.item.total_prize_fund }}
          <span class="winnerLabel" v-if="row.item.total_prize_fund.length">Prize Fund</span>
        </div>
      </template>
      <template #cell(course_country)="row">
          {{ row.item.course_country }}
          <span v-if="row.item.course_flag" class="flagSpan">
            <img
              class="flag"
              :src="
                (config.VUE_APP_FLAG_URL +
                  row.item.course_flag)
                  | lowercase
              "
            />
          </span>
      </template>
      <template #cell(winner_name)="row">
        <template v-if="row.item.profile == 'N'">
          <span class="playerBlock">
            <template v-if="row.item.image == 'Y'">
              <img class="profileImage" :src="config.VUE_APP_PLAYER_IMAGE + 'media/photos/' + row.item.winner_ref + '.jpg'" onerror="this.style.display='none';this.className='pink';" >
            </template>
            <template v-else-if="row.item.code == '1923' || row.item.code == '1001' || row.item.code == '1914'">
              <img class="profileImage" :src="'https://live-let.ocs-software.com/wp-content/uploads/2020/01/cup.png'" onerror="this.style.display='none';this.className='pink';" >
            </template>
            <template v-else>
              <template v-if="row.item.winner_name == ''">
                <template v-if="row.item.previous_winners == ''">
                  <img class="profileImage" :src="'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/Amateur_2.png'" onerror="this.style.display='none';this.className='pink';" >      
                </template>
                <template v-else>
                  <img class="profileImage" :src="config.VUE_APP_PLAYER_IMAGE + 'media/photos/' + row.item.previous_winners.previous_winners_entry.cc_code_1 + '.jpg'" onerror="this.style.display='none';this.className='pink';" >
                </template>
              </template>
            </template>
            <div class="WinnerBlock">
              <template v-if="row.item.winner_name == ''">
                <template v-if="row.item.previous_winners == ''">
                  
                </template>
                <template v-else>
                  <span class="winnerName">{{ row.item.previous_winners.previous_winners_entry.cc_name_1 }}</span>
                  <span class="winnerLabel" v-if="row.item.previous_winners.previous_winners_entry.cc_name_1.length">Previous Winner</span>
                </template>
              </template>
              <template v-else>
                <span class="winnerName">{{ row.item.winner_name }}</span>
                <span class="winnerLabel" v-if="row.item.winner_name.length">Winner {{id}}</span>
              </template>
            </div>
          </span>
        </template>
        <template v-else>
          <b-link :to="'/player-profiles/' + row.item.winner_ref " class="playerLink" @click.stop="void(0)" target="_blank">
            <span class="playerBlock">
              <template v-if="row.item.image == 'Y'">
                <img class="profileImage" :src="config.VUE_APP_PLAYER_IMAGE + 'media/photos/' + row.item.winner_ref + '.jpg'" onerror="this.style.display='none';this.className='pink';" >
              </template>
              <template v-else-if="row.item.code == '1923' || row.item.code == '1001' || row.item.code == '1914'">
                <img class="profileImage" :src="'https://live-let.ocs-software.com/wp-content/uploads/2020/01/cup.png'" onerror="this.style.display='none';this.className='pink';" >
              </template>
              <template v-else>
                <img class="profileImage" :src="'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2023/10/Amateur_2.png'" onerror="this.style.display='none';this.className='pink';" >
              </template>
              <div class="WinnerBlock">
                <span class="winnerName">{{ row.item.winner_name }}</span>
                <span class="winnerLabel" v-if="row.item.winner_name.length">Winner {{id}}</span>
              </div>
            </span>
            <!-- <img v-if="row.item.winner_name.length > 0" class="cup" src="https://live-let.ocs-software.com/wp-content/uploads/2020/01/cup.png"> -->
          </b-link>
        </template>
      </template>
    </b-table>
    <template v-if="data == undefined">
      <div class="scheduleComing">{{year}} Schedule Coming Soon</div>
    </template>
  </div>
</template>

<script>
export default {
  name: "scheduleTable",
  props: ["id", "fields", "data", "code", "config", "year"],
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.code === item.code) return "live";
    },
    onRowClicked(item, index, event) {
      console.log(item)
      console.log(event)
      this.data.forEach((value, idx) => {
        if (idx == index)
         this.$router.push({ path: '/tournaments-information/' + value.code + '/' + this.year, replace: true })  
      });
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
.scheduleComing {
  text-align: center;
  font-size: 18pt;
  margin-top: 80px;
  color: #7ba639;
}
a.tournamentName {
  color: #212529;
}
a.tournamentName:hover {
  color: #7ba639;
  text-decoration: none;
}
.prizeFundBlock {
  display: grid;
}
.WinnerBlock {
  text-align: left;
  display: inline-grid;
  margin-right: 0;
  position: relative;
  left: 12px;
  top: -11px;
}
span.winnerLabel {
  font-size: 0.9rem;
  font-weight: 300;
}
span.courseText {
  display: block;
  font-size: 0.9rem;
  font-weight: 300;
}
span.winnerName {
  /* position: relative;
  top: -11px; */
}
img.profileImage {
  height: 50px;
}
.flagSpan {
  display: block;
}
.flag {
  /* width: 30px; */
  height: 20px;
  border: 1px solid #212529
}
::v-deep tbody > tr {
  height: 80px;
  cursor: pointer;
}
.cup {
  width: 15px;
  margin-left: 5px;
}
::v-deep tr.live {
  background-color: #c8e49a!important;
}
li.nav-item::marker {
  color: transparent;
}
.nav-link {
  /* margin-top: -5%; */
  margin-top: -7%;
}
li {
  list-style: none;
}
::v-deep th {
  position: sticky;
  top: 0;
  background-color: #7ba639;
  z-index: 111;
  border: none;
  font-size: 10pt;
  font-weight: 400;
}

::v-deep thead {
  color: white;
  background-color: #7ba639;
}

::v-deep .table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0;
}
::v-deep tr:hover {
  background-color: #f6f5f5;
}

::v-deep td>li{
  margin-top: 15px;
}
::v-deep td > li > a {
  color: #212529;
}
::v-deep a.playerLink {
  color: #212529;
}
::v-deep a.playerLink:hover {
  color: #7ba639;
  text-decoration: none;
}
::v-deep td > li > a:hover {
  color: #7ba639;
  /* text-decoration: underline; */
}
::v-deep td{
  font-size: 1rem;
}
.bar{
  width: 100%;
  text-align: left;
  color: white;
  background-color: #7ba639;
}
.seasonRank{
  color: #fff;
  padding: 15px 0 15px 15px;
  font-size: 12pt;
  margin-bottom: 0;
  text-align: center;
  font-weight: 400;
}
</style>
