<template>
  <div class="tour">
    <div class="blackO">
      <b-row class="splashRow">
        <b-img
          v-if="tourinfo.course_images === 'Y'"
          :class="[splash]"
          :src="
            config.VUE_APP_PLAYER_IMAGE + 'courses/' +
              this.id +
              '/' +
              tourinfo.course_code +
              '/' +
              tourinfo.course_image_file_3 
          "
        >
        </b-img>
        <b-img
          v-else
          :src="
            'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/06/LETPlace-1.jpg'
          "
          :class="[splash]"
        >
        </b-img>
      </b-row>
    </div>
    <div>
      <b-container>
        <b-tabs content-class="mt-3" class="tabTourn">
          <b-tab title="Course Info" active>
            <b-row>
              <b-col lg="5" cols="12" class="info line">
                <b-row v-if="tourinfo.tourn_dates">
                  <b-col lg="3" md="6" cols="6">
                    <span>
                      Dates:
                    </span>
                  </b-col>
                  <b-col lg="9" md="6" cols="6">
                    {{ tourinfo.tourn_dates }}
                  </b-col>
                </b-row>
                <b-row v-if="tourinfo.rounds.length > 0">
                  <b-col lg="3" md="6" cols="6">
                    <span>
                      Rounds:
                    </span>
                  </b-col>

                  <b-col lg="9" md="6" cols="6">
                    {{ tourinfo.rounds }}
                  </b-col>
                </b-row>
                <b-row v-if="tourinfo.total_prize_fund.length > 0">
                  <b-col lg="3" md="6" cols="6">
                    <span> Prize Fund:</span>
                  </b-col>

                  <b-col lg="9" md="6" cols="6">
                    {{ tourinfo.total_prize_fund }}
                  </b-col>
                </b-row>
                <b-row v-if="tourinfo.course_name.length > 0">
                  <b-col lg="3" md="6" cols="6">
                    <span>Course Name:</span>
                  </b-col>
                  <b-col lg="9" md="6" cols="6">
                    {{ tourinfo.course_name }}
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col lg="3" md="6" cols="6">
                    <span>Course</span>
                  </b-col>
                  <b-col lg="9" md="6" cols="6">
                    {{ tourinfo.course }}
                  </b-col>
                </b-row> -->
                <b-row v-if="tourinfo.course_website.length > 0">
                  <b-col lg="3" md="6" cols="6">
                    <span>Website:</span>
                  </b-col>
                  <b-col lg="9" md="6" cols="6">
                    <a v-bind:href="'https://' + tourinfo.course_website" target="_blank">{{ tourinfo.course_website }}</a>
                  </b-col>
                </b-row>
                <b-row v-if="tourinfo.course_city.length > 0">
                  <b-col lg="3" md="6" cols="6">
                    <span>City:</span>
                  </b-col>
                  <b-col lg="9" md="6" cols="6">
                    {{ tourinfo.course_city }}
                  </b-col>
                </b-row>
                <b-row v-if="tourinfo.course_country.length > 0">
                  <b-col lg="3" md="6" cols="6">
                    <span>Country:</span>
                  </b-col>
                  <b-col lg="9" md="6" cols="6">
                    {{ tourinfo.course_country }}
                    <!-- <span class="courseFlag" v-if="tourinfo.course_flag !==">
                      <img
                        class="flag"
                        :src="
                          (config.VUE_APP_FLAG_URL +
                            tourinfo.course_flag)
                            | lowercase
                        "
                      />
                    </span> -->
                  </b-col>
                </b-row>
                <b-row v-if="tourinfo.course_total_par.length > 0">
                  <b-col lg="3" md="6" cols="6">
                    <span>Par:</span>
                  </b-col>
                  <b-col lg="9" md="6" cols="6">
                    {{ tourinfo.course_total_par }}
                  </b-col>
                </b-row>
                <div v-if="tourinfo.clubs != ''">
                  <b-row v-if="tourinfo.clubs.clubs_entry.tel.length > 0">
                    <b-col lg="3" md="6" cols="6">
                      <span>Call Venue:</span>
                    </b-col>
                    <b-col lg="9" md="6" cols="6">
                      <a class="mapDirection" :href="'tel:' + tourinfo.clubs.clubs_entry.tel">
                        <b-button class="btnWidth purpleBTN" variant="primary">{{tourinfo.clubs.clubs_entry.tel}} </b-button>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row v-if="tourinfo.clubs.clubs_entry.email.length > 0">
                    <b-col lg="3" md="6" cols="6">
                      <span>Send E-mail:</span>
                    </b-col>
                    <b-col lg="9" md="6" cols="6">
                      <a class="mapDirection" :href="'mailto:' + tourinfo.clubs.clubs_entry.email">
                        <b-button class="btnWidth" variant="success">
                          {{tourinfo.clubs.clubs_entry.email}}
                        </b-button>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row v-if="tourinfo.course_gps_lat.length > 0">
                    <b-col lg="3" md="6" cols="6">
                      <span>Directions</span>
                    </b-col>
                    <b-col lg="9" md="6" cols="6">
                      <div @click="openVenueMap()" class="mapDirection">
                        <b-button class="btnWidth" variant="primary">Map / Directions </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div v-else-if="tourinfo.course_telephone !== '' && tourinfo.course_email !== ''">
                  <b-row v-if="tourinfo.course_telephone.length > 0">
                    <b-col lg="3" md="6" cols="6">
                      <span>Call Venue:</span>
                    </b-col>
                    <b-col lg="9" md="6" cols="6">
                      <a class="mapDirection" :href="'tel:' + tourinfo.course_telephone">
                        <b-button class="btnWidth purpleBTN" variant="primary">{{tourinfo.course_telephone}} </b-button>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row v-if="tourinfo.course_email.length > 0">
                    <b-col lg="3" md="6" cols="6">
                      <span>Send E-mail:</span>
                    </b-col>
                    <b-col lg="9" md="6" cols="6">
                      <a class="mapDirection" :href="'mailto:' + tourinfo.course_email">
                        <b-button class="btnWidth" variant="success">
                          {{tourinfo.course_email}}
                        </b-button>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row v-if="tourinfo.course_gps_lat.length > 0">
                    <b-col lg="3" md="6" cols="6">
                      <span>Directions</span>
                    </b-col>
                    <b-col lg="9" md="6" cols="6">
                      <div @click="openVenueMap()" class="mapDirection">
                        <b-button class="btnWidth" variant="primary">Map / Directions </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col lg="7" col="12">
                <b-row>
                  <b-col lg="6">
                    <template v-if="tourinfo.sponsor_logo.length">
                      <b-img class="logo" :src="tourinfo.sponsor_logo"></b-img>
                    </template>
                    <template v-else>
                      <h3 class="FullName">{{ tourinfo.full_name }}</h3>
                    </template>
                  </b-col>
                </b-row>
                <b-row class="winRow">
                  <template v-if="tourinfo.winner_count == 1">
                    <b-col>
                      <b-link :to="'/player-profiles/' + tourinfo.winner_code" target="_blank" class="winnerText">
                        <b-row>
                          <b-col lg="4" md="7" cols="7" class="photoBack" :style="'background-image: url('+config.VUE_APP_WP_DIGITAL_OCEAN+'2020/05/GeoMesh-02.png);'"> </b-col>
                          <b-col class="winnerCol">
                            <h3 class="winHead">
                              <span v-if="tourinfo.winner_found === 'N'">
                                Previous Winner:
                              </span>
                              <span v-if="tourinfo.winner_found === 'Y'">
                                Winner:
                              </span>
                            </h3>
                            <h3 class="playerName">
                              <span v-if="tourinfo.winner_found === 'N'">
                                {{
                                  tourinfo.previous_winners.previous_winners_entry
                                    .cc_name_1
                                }}
                              </span>
                              <span v-if="tourinfo.winner_found === 'Y'">
                                {{ tourinfo.winner_name.toUpperCase() }}
                              </span>
                            </h3>
                          </b-col>
                        </b-row>
                        <b-row class="photo">
                          <b-col lg="8" md="5">
                            <span v-if="tourinfo.winner_found === 'N'">
                              <b-img
                                class="pic"
                                :src="
                                  config.VUE_APP_PLAYER_IMAGE+'media/photos/' +
                                    tourinfo.previous_winners.previous_winners_entry
                                      .cc_code_1 +
                                    '.jpg'
                                "
                              ></b-img>
                            </span>
                            <span v-if="tourinfo.winner_found === 'Y'">
                              <b-img
                                onerror="javascript:this.src='https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/10/PU1702CAL121AAA0041.jpeg';this.style='width: 150px; margin-top:35px;'"
                                class="pic"
                                :src="
                                  config.VUE_APP_PLAYER_IMAGE+'media/photos/' +
                                    tourinfo.winner_code +
                                    '.jpg'
                                "
                              ></b-img>
                            </span>
                          </b-col>
                        </b-row>
                      </b-link>
                    </b-col>
                  </template>
                  <template v-else-if="tourinfo.winner_count > 2">
                    <div class="winnerCountSection">
                      <h3 class="prev-winner-title">Winner: <span class="captainName"> {{tourinfo.winner_team}}</span></h3>
                      <h3 class="prev-winner-title" style="margin-top: 35px;">Captain:
                        <span class="captainName">
                          <b-link :to="'/player-profiles' + '/' +  tourinfo.winner_code" class="winner_name">
                            {{tourinfo.winner_name}}
                          </b-link>
                        </span>
                      </h3>
                    </div>
                  </template>
                </b-row>
                <b-row class="winnerCountPeople" v-if="tourinfo.winner_count > 2">
                  <b-col v-if="tourinfo.winner_name.length > 0">
                    <b-card
                      :img-src="'https://tms-images.ams3.cdn.digitaloceanspaces.com//let/media/photos/' + tourinfo.winner_code + '.jpg'"
                      img-alt="Image"
                      img-top
                      tag="article"
                      style="max-width: 20rem;"
                      class="mb-2"
                    >
                      <b-card-text>
                        <template v-if="tourinfo.winner_amateur == 'A'">
                          <p class="winnerName_count">
                            {{ tourinfo.winner_name }} <span v-if="tourinfo.winner_amateur == 'A'">(A)</span>
                          </p>
                        </template>
                        <template v-else>
                          <p class="winnerName_count">
                            <b-link class="winnerLink" :to="'/player-profiles' + '/' + tourinfo.winner_code">
                            {{ tourinfo.winner_name }} <span v-if="tourinfo.winner_amateur == 'A'">(A)</span>
                            </b-link>
                          </p>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                  <b-col v-if="tourinfo.winner_name_2.length > 0">
                    <b-card
                      :img-src="'https://tms-images.ams3.cdn.digitaloceanspaces.com//let/media/photos/' + tourinfo.winner_code_2 + '.jpg'"
                      img-alt="Image"
                      img-top
                      tag="article"
                      style="max-width: 20rem;"
                      class="mb-2"
                    >
                      <b-card-text>
                        <template v-if="tourinfo.winner_amateur_2 == 'A'">
                          <p class="winnerName_count">
                            {{ tourinfo.winner_name_2 }} <span v-if="tourinfo.winner_amateur_2 == 'A'">(A)</span>
                          </p>
                        </template>
                        <template v-else>
                          <p class="winnerName_count">
                            <b-link class="winnerLink" :to="'/player-profiles' + '/' + tourinfo.winner_code_2">
                            {{ tourinfo.winner_name_2 }} <span v-if="tourinfo.winner_amateur_2 == 'A'">(A)</span>
                            </b-link>
                          </p>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                  <b-col v-if="tourinfo.winner_name_3.length > 0">
                    <b-card
                      :img-src="'https://tms-images.ams3.cdn.digitaloceanspaces.com//let/media/photos/' + tourinfo.winner_code_3 + '.jpg'"
                      img-alt="Image"
                      img-top
                      tag="article"
                      style="max-width: 20rem;"
                      class="mb-2"
                    >
                      <b-card-text>
                        <template v-if="tourinfo.winner_amateur_3 == 'A'">
                          <p class="winnerName_count">
                            {{ tourinfo.winner_name_3 }} <span v-if="tourinfo.winner_amateur_3 == 'A'">(A)</span>
                          </p>
                        </template>
                        <template v-else>
                          <p class="winnerName_count">
                            <b-link class="winnerLink" :to="'/player-profiles' + '/' + tourinfo.winner_code_3">
                            {{ tourinfo.winner_name_3 }} <span v-if="tourinfo.winner_amateur_3 == 'A'">(A)</span>
                            </b-link>
                          </p>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                  <b-col v-if="tourinfo.winner_name_4.length > 0">
                    <b-card
                      :img-src="'https://tms-images.ams3.cdn.digitaloceanspaces.com//let/media/photos/' + tourinfo.winner_code_4 + '.jpg'"
                      img-alt="Image"
                      img-top
                      tag="article"
                      style="max-width: 20rem;"
                      class="mb-2"
                    >
                      <b-card-text>
                        <template v-if="tourinfo.winner_amateur_4 == 'A'">
                          <p class="winnerName_count">
                            {{ tourinfo.winner_name_4 }} <span v-if="tourinfo.winner_amateur_4 == 'A'">(A)</span>
                          </p>
                        </template>
                        <template v-else>
                          <p class="winnerName_count">
                            <b-link class="winnerLink" :to="'/player-profiles' + '/' + tourinfo.winner_code_4">
                            {{ tourinfo.winner_name_4 }} <span v-if="tourinfo.winner_amateur_4 == 'A'">(A)</span>
                            </b-link>
                          </p>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div class="courseParaDiv">
              <b-row>
                <b-col>
                  <p v-if="tourinfo.course_para1 !== ''" v-html="tourinfo.course_para1" class="coursePara"></p>
                  <p v-if="tourinfo.course_para2 !== ''" v-html="tourinfo.course_para2" class="coursePara"></p>
                  <p v-if="tourinfo.course_para3 !== ''" v-html="tourinfo.course_para3" class="coursePara"></p>
                  <p v-if="tourinfo.course_para4 !== ''" v-html="tourinfo.course_para4" class="coursePara"></p>
                </b-col>
              </b-row>
            </div>
            <b-container class="scoreCard">
              <b-row :class="courseStats">
                <b-col lg="6">
                  <table class="courseinfo">
                    <thead>
                      <tr class="title">
                        <th>
                          Hole
                        </th>
                        <th>
                          1
                        </th>
                        <th>
                          2
                        </th>
                        <th>
                          3
                        </th>
                        <th>
                          4
                        </th>
                        <th>
                          5
                        </th>
                        <th>
                          6
                        </th>
                        <th>
                          7
                        </th>
                        <th>
                          8
                        </th>
                        <th>
                          9
                        </th>
                        <th>
                          Out
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Par
                        </td>
                        <td
                          v-for="(number, index) in tourinfo.course_par
                            .split(',')
                            .slice(0, 9)"
                          :key="index"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ tourinfo.course_out_par.slice(1, 5) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Yards
                        </td>
                        <td
                          v-for="(number,
                          index) in tourinfo.course_length_yards
                            .split(',')
                            .slice(0, 9)"
                          :key="index"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ outVal(tourinfo.course_length_yards) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Meters
                        </td>
                        <td
                          v-for="(number,
                          index) in tourinfo.course_length_meters
                            .split(',')
                            .slice(0, 9)"
                          :key="index"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ outVal(tourinfo.course_length_meters) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col lg="6">
                  <table class="courseinfo">
                    <thead>
                      <tr class="title">
                        <th>
                          10
                        </th>
                        <th>
                          11
                        </th>
                        <th>
                          12
                        </th>
                        <th>
                          13
                        </th>
                        <th>
                          14
                        </th>
                        <th>
                          15
                        </th>
                        <th>
                          16
                        </th>
                        <th>
                          17
                        </th>
                        <th>
                          18
                        </th>
                        <th>
                          In
                        </th>
                        <th>
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          v-for="(number, index) in tourinfo.course_par
                            .split(',')
                            .slice(9, 18)"
                          :key="index"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ tourinfo.course_in_par.slice(1, 5) }}
                        </td>
                        <td>
                          {{ tourinfo.course_total_par }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          v-for="(number,
                          index) in tourinfo.course_length_yards
                            .split(',')
                            .slice(9, 18)"
                          :key="index"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ inVal(tourinfo.course_length_yards) }}
                        </td>
                        <td>
                          {{
                            totalVal(tourinfo.course_length_yards)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          v-for="(number,
                          index) in tourinfo.course_length_meters
                            .split(',')
                            .slice(9, 18)"
                          :key="index"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ inVal(tourinfo.course_length_meters) }}
                        </td>
                        <td>
                          {{ totalVal(tourinfo.course_length_meters) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab title="Images">
            <Flickr :tourName="tourinfo.full_name" :config="config"/>
          </b-tab>
          <b-tab title="News Articles">
            <Post v-if="wptag.length" :tag="wptag[0].id" />
          </b-tab>
          <b-tab title="Overview" v-if="tourinfo.tourn_desc_found == 'Y'">
            <div class="NoPadLeft">
              <b-container class="NoPadLeft">
                <b-row>
                  <b-col>
                    <p v-html="tourinfo.tourn_desc"></p>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </b-tab>            
          <b-tab title="Social Feed">
            <div id="social" class="NoPadLeft">
              <b-container class="NoPadLeft">
                <b-row>
                  <b-col lg="4">
                    <b-button class="social">Twitter</b-button>
                    <Twitter :config="config"/>
                  </b-col>
                  <!-- <b-col lg="4">
                    <b-button class="social">INSTAGRAM</b-button>
                    <InstaFeed />
                  </b-col>
                  <b-col lg="4">
                    <b-button class="social">FACEBOOK</b-button>
                    <FaceBook :config="config"/>
                  </b-col> -->
                </b-row>
              </b-container>
            </div>
          </b-tab>
          <b-tab title="Overview" v-if="tourinfo.tourn_desc_found == 'Y'">
            <div class="NoPadLeft">
              <b-container class="NoPadLeft">
                <b-row>
                  <b-col>
                    <p v-html="tourinfo.tourn_desc"></p>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </b-tab>  
          <!-- <b-tab title="Stats"  v-bind:class="{'statsTourn': this.$route.name === 'Tournament'}">
            <StatsTable :data="tourStats" :config="config"/>
          </b-tab> -->
          <template #tabs-end v-if="tourinfo.reports !== ''">
            <b-nav-item
              v-if="filterEntry.length && filterEntry !== 'none'"
              href="#"
              role="presentation"
              :to="{
                name: 'reports-page',
                query: {
                  url: filterEntry[0].report_url,
                  id: id,
                  code: course_code,
                  title: filterEntry[0].report_title,
                },
              }"
              >Entries</b-nav-item
            >
            <b-nav-item
              v-else
              href="#"
              role="presentation"
              :to="{
                name: 'reports-page',
                query: {
                  url: tourinfo.reports.reports_entry.report_url,
                  id: id,
                  code: course_code,
                  title: tourinfo.reports.reports_entry.report_title,
                },
              }"
              >Entries</b-nav-item
            >
            <b-nav-item
              v-if="filterDraw.length && filterDraw !== 'none'"
              href="#"
              role="presentation"
              :to="{
                name: 'reports-page',
                query: {
                  url: filterDraw[0].report_url,
                  id: id,
                  code: course_code,
                  title: filterDraw[0].report_title,
                },
              }"
              >Draw</b-nav-item
            >
            <b-nav-item
              v-if="filterScore.length && filterScore !== 'none'"
              href="#"
              role="presentation"
              :to="{
                name: 'reports-page',
                query: {
                  url: filterScore[0].report_url,
                  id: id,
                  code: course_code,
                  title: filterScore[0].report_title,
                },
              }"
              >Scores</b-nav-item
            >
            <b-nav-item
              v-if="filterFinal.length && filterFinal !== 'none'"
              href="#"
              role="presentation"
              :to="{
                name: 'reports-page',
                query: {
                  url: filterFinal[0].report_url,
                  id: id,
                  code: course_code,
                  title: filterFinal[0].report_title,
                },
              }"
              >Results</b-nav-item
            >
            <b-nav-item
              v-if="tourinfo.scoring_url !==''"
              :href="tourinfo.scoring_url"
              target="_blank"
              >External Live Scoring</b-nav-item
            >
          </template>
        </b-tabs>
      </b-container>
    </div>
    <div></div>
  </div>
</template>

<script>
import axios from "axios";
import Flickr from "@/components/flickr.vue";
import Post from "@/components/posts.vue";
import Twitter from "@/components/twitterFeed.vue";
// import InstaFeed from "@/components/instaFeed.vue";
// import FaceBook from "@/components/faceBook.vue";
// import StatsTable from "@/components/statsTable.vue";
export default {
  name: "Tournament",
  props: ["course_code", "id", 'config'],
  components: {
    Flickr,
    Post,
    Twitter,
    // InstaFeed,
    // FaceBook,
    // StatsTable,
  },
  data() {
    return {
      tourinfo: [],
      splash: "splash",
      splashText: "splashText",
      logo: "logo",
      overlay: "overlay",
      courseStats: "courseStats",
      wptag: "",
      tourStats: [],
      course_gps_lat: '',
      course_gps_long: ''
    };
  },
  computed: {
    rPlayed: function() {
      return this.tourinfo.rounds_played;
    },
    filterEntry: function(entry) {
      if (Array.isArray(this.tourinfo.reports.reports_entry))
        entry = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Tour")
        );
      else if (this.tourinfo.reports.length) 
      entry = this.tourinfo.reports.reports_entry.report_title;
      else entry = 'none'
      return entry;
    },
    filterDraw: function(draw) {
      if (Array.isArray(this.tourinfo.reports.reports_entry))
      if (this.rPlayed == '0') {
        draw = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) =>
            !tourinfo.report_title.indexOf("Round " + '1' + " Draw")
        );
      } else {
        draw = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) =>
            !tourinfo.report_title.indexOf("Round " + this.rPlayed + " Draw")
        ); 
      }
      else draw = "none";
      return draw;
    },
    filterScore: function(score) {
      if (Array.isArray(this.tourinfo.reports.reports_entry))
        if (this.rPlayed == '0') {
          score = this.tourinfo.reports.reports_entry.filter(
            (tourinfo) =>
              !tourinfo.report_title.indexOf(
                "Round " + '1' + " Scores Only"
              )
          ); 
        } else if(this.rPlayed == '1') {
          score = this.tourinfo.reports.reports_entry.filter(
            (tourinfo) =>
              !tourinfo.report_title.indexOf(
                "Round " + this.rPlayed + " Scores Only"
              )
          );   
        } else {
          score = this.tourinfo.reports.reports_entry.filter(
            (tourinfo) =>
              !tourinfo.report_title.indexOf(
                "Round " + this.rPlayed + " Scoreboard"
              )
          );  
        }
      else score = "none";
      return score;
    },
    filterFinal: function(final) {
      if (Array.isArray(this.tourinfo.reports.reports_entry))
        final = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Final")
        );
      else final = "none";
      return final;
    },
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  methods:{
    openVenueMap() {
      const courseLat = this.course_gps_lat;
      const courseLon = this.course_gps_long;
      // const club = this.tournClubs[clubIdx];

      let mapURL = '';
      let params = '';

      // if (parseFloat(courseLat) != 0 && parseFloat(courseLon) != 0) {
        params = courseLat + '+' + courseLon;
      // }
      // else {
      // 	for (let i = 1; i <= 5; i++) {
      // 		const addrLine = club['addr' + i];

      // 		if (addrLine.length)
      // 			params += addrLine.replace(/\+s/g, '+') + ',';
      // 	}

      // 	params = encodeURIComponent(params.replace(/,\s*$/, ''));
      // }

      mapURL = 'https://www.google.com/maps/@' + params.replaceAll('+', ',') + ',14z';

      window.open(mapURL, '_system');
    },
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
   inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_TIC_BASE +
          this.id +
          "/" +
          this.id +
          "-" +
          this.course_code +
          "-tmticx.json?randomadd=" +
          new Date().getTime()
      )
      .then((response) => {
        this.tourinfo = response.data;
        this.course_gps_lat = response.data.course_gps_lat;
        this.course_gps_long = response.data.course_gps_long;
        return axios.get(
          process.env.VUE_APP_WPAPI_URL+"wp/v2/tags?slug=" +
            this.tourinfo.code
        );
      })
      .then((response) => {
        this.wptag = response.data;
      });
    // return axios
    //   .get(
    //     process.env.VUE_APP_TMTX_BASE+process.env.VUE_APP_PLAYER_IMAGE+"tic/tmoom.cgi?oom=X1~tourn=" +
    //       this.course_code +
    //       "~season=" +
    //       this.id +
    //       "~enhanced=y~jsout=x~xsl=~?randomadd=1622129387910" +
    //       new Date().getTime()
    //   )
    //   .then((response) => {
    //     this.tourStats = response.data;
    //   });
  },
};
</script>

<style scoped>
.courseParaDiv {
  margin-top: 40px;
}
p.coursePara {
  font-size: 1.1rem;
}
button.btn.btnWidth.purpleBTN.btn-primary {
  background-color: #6f42c1;
  border-color: #6f42c1;
}
button.btn.btnWidth.purpleBTN.btn-primary:hover {
  background-color: #311860;
  border-color: #311860;
}
button.btn.btnWidth {
  width: 200px;
}
.mapDirection {
  cursor: pointer;
}
a.mapDirection {
  cursor: pointer;
  color: #212529;
}
a.mapDirection:hover {
  cursor: pointer;
  color: #035699;
  text-decoration: none;
}
.mapDirection:hover {
  color: #035699;
  text-decoration: none;
}
.courseFlag {
  margin-left: 10px;
}
img.flag {
  height: 25px;
  border: 1px solid #212529;
}
.winnerText {
  color: #000;
}
.winnerText:hover {
  text-decoration: none;
}
.NoPadLeft {
  padding-left: 0!important;
}
.card-body {
  height: 100px;
  background-color: #f2f2f2;
}
a.winnerLink {
  color: #95c93d;
}
h3.FullName { 
  text-align: center;
  color: #95c93d;
}
p.winnerName_count {
  font-size: 1rem;
}
.row.winnerCountPeople {
  margin-top: -4em;
  margin-bottom: 6em;
}
.scoreCard {
  padding-left: 0px;
  padding-right: 0px;
}
::v-deep a.winner_name {
  color: #03477b;
}
.captainName {
  color: #95c93d;
  font-size: 1rem;
  font-weight: 400;
}
h3.prev-winner-title {
  font-size: 15pt;
  font-weight: 500;
}
::v-deep #flickr{
  background-color: #fff;
  padding: 50px 0;
}
::v-deep .tabTourn > .mt-3 {
  display: block;
}
.statsTourn {
  width: 100%;
}
::v-deep .nav-tabs {
  margin-top: -48px;
  width: 100%;
  background-color: #95c93d;
  padding-bottom: 1px;
}
::v-deep .nav-tabs > .nav-item > a {
  background-color: #95c93d;
  padding: 12px 28px;
  color: white;
  font-size: 12pt;
}
::v-deep .nav-tabs > .nav-item > a.active {
  background-color: #7ba639;
}
::v-deep .nav-tabs > .nav-item > a:hover {
  background-color: #7ba639;
}
::v-deep .nav-tabs .nav-link {
  border: 0;
  border-radius: 0;
}
::v-deep .tabs > div {
  display: flex;
  padding-bottom: 30px;
}
.splash {
  width: 100%;
  margin-top: -13em;
}
li {
  list-style-type: none;
}
.splashRow {
  overflow: hidden;
  height: 580px;
  max-height: 600px;
}

.courseinfo {
  width: 100%;
  margin-top: 50px;
}
.info {
  font-size: 1rem;
}
.line {
  line-height: 35px;
}
.overlay {
  padding-left: 6%;
  padding-top: 2%;
}
.winnerCol {
  background-color: #f2f2f2;
  padding-left: 30px;
  padding-bottom: 23px;
  padding-top: 23px;
}
.winRow {
  padding-left: 15px;
  padding-right: 5px;
  margin-top: 1em;
  margin-bottom: 4em;
  width: 100%;
}
.winHead {
  margin-bottom: 0;
  margin-top: 30px;
  font-size: 15pt;
}

.playerName {
  margin-top: 3px;
  color: #95c93d;
  font-size: 1rem;
  padding-bottom: 14px;
}
.photoBack {
  background-color: #95c93d;
  margin-left: 11px;
  margin-right: -23px;
}
.photo {
  margin-top: -185px;
  /* margin-left: -19px; */
  margin-left: 0px;
  width: 138px;
}
.pic {
  width: 138px;
}
.title {
  background-color: #95c93d;
  color: white;
}
.logo {
  max-width: 183px;
  width: 200px;
}

table {
  text-align: center;
}
tbody > tr:nth-of-type(odd) {
  background-color: rgba(0,0,0,.03);
}
td {
  padding: 0.75rem;
  font-size: 1rem;
}
th {
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 400;
}
.hbh {
  color: #0b3f7e;
}
.courseStats {
  padding-bottom: 50px;
}
.social {
  background-color: #95c93d;
  border-radius: 0;
  padding: 11px 30px;
}
#social {
  padding: 50px;
}
@media only screen and (max-width: 1024px) {
  .splash {
    height: 300px;
    margin-top: 0em;
  }
  .splashText {
    margin-top: -25%;
  }

  .splashPad[data-v-14fba303] {
    padding-top: 8%;
  }
}
@media only screen and (max-width: 768px) {
  ::v-deep .nav-tabs > li {
    width: 100%;
  }
  .splashRow[data-v-14fba303] {
    height: 400px;
  }
}

@media only screen and (max-width: 480px) {
  ::v-deep .miniWrapTour {
    position: absolute;
    bottom: -8px;
    width: 96%;
  }
  ::v-deep .imgColTourn {
    height: auto!important;
  }
  .splashRow {
    height: 250px!important;
    margin-bottom: 5em;
  }
  .splashText {
    margin-top: 0;
    text-align: center;
  }
  .overlay {
    flex-basis: auto;
    background-color: #263056;
    color: white;
  }
  .winRow {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 3em;
    margin-bottom: 6em;
    width: 100%;
  }
  .courseinfo {
    display: none;
  }
  ::v-deep .photoBack {
    background-color: #95c93d;
    margin-left: 0px;
    margin-right: -23px;
  }
  ::v-deep .winRow {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 3em;
    margin-bottom: 6em;
    width: 100%;
    padding-right: 7px;
    display: contents;
  }
  .logo {
    max-width: 183px;
    width: 200px;
    padding-bottom: 2em;
  }
  .pic {
    width: 139px;
    margin-left: -11px;
  }
}
</style>
