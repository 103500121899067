<template>
  <div>
    <b-img class='splash' :src='pageData.acf.bannerimage'></b-img>
    <b-container>
        <b-row class="qschoolEnter">
            <b-col col="12" lg="12" md="12">
                <a :href="pageData.acf.beneath_banner_image_link" target="_blank">
                    <img :src="pageData.acf.beneath_banner_image">
                </a>
            </b-col>
        </b-row>
		<div class="">
            <div class="q-school-app">
            <div class="row">
                <div class="col-md-6">
                <div class="EnterNow">To enter <span class="qschoolYear"> Q-School</span> download the LET Tour App</div>
                </div>
                <div class="col-md-6">
                <div class="row q-schoolApp-block">
                    <div class="col-md-6">
                    <a href="https://apps.apple.com/us/app/ladies-european-tour/id419334047" target="_blank">
                        <div id="apple"></div>
                    </a>
                    </div>
                    <div class="col-md-6">
                    <a href="https://play.google.com/store/apps/details?id=com.ladies.european.tour&hl=en_GB&gl=US" target="_blank">
                        <div id="google"></div>
                    </a>
                    </div>
                </div>
                </div>
            </div>
            </div>
		</div>
        <div class="paddingBlock">
            <div class="row blockAppPic">
                <div class="blockApp col-md-2">
                <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2022/08/Group-1.png">
                <div class="instructBlock">
                    <span class="textBlock">
                    To enter Q-School download the LET Tour App
                    </span>
                </div> 
                </div>
                <div class="blockApp col-md-2">
                <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2022/08/Group-2.png">
                <div class="instructBlock">
                    <span class="textBlock">
                    If you are new to the LET Tour, register your details otherwise go to step 4
                    </span>
                </div> 
                </div>
                <div class="blockApp col-md-2">
                <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2022/08/Group-3.png">
                <div class="instructBlock">
                    <span class="textBlock">
                    Run through wizard and complete registration
                    </span>
                </div> 
                </div>
                <div class="blockApp col-md-2">
                <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2022/08/Group-4.png">
                <div class="instructBlock">
                    <span class="textBlock">
                    Go to schedule and click Q-School
                    </span>
                </div> 
                </div>
                <div class="blockApp col-md-2">
                <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2022/08/Group-5.png">
                <div class="instructBlock">
                    <span class="textBlock">
                    Select the tournament and choose your exemption
                    </span>
                </div> 
                </div>
                <div class="blockApp col-md-2">
                <img class="imageBack" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2022/08/Group-6.png">
                <div class="instructBlock">
                    <span class="textBlock">
                    Enter and pay
                    </span>
                </div> 
                </div>
            </div>
        </div>
        <b-dropdown
            class="year"
            :text="Year + ' Season'"
        >
            <b-dropdown-item
              v-on:click="changeYear"
              v-for="(seasons,
              index) in seasons.seasons.seasons_entry.slice().reverse()"
              :key="index"
              :title="seasons.code"
              :year="seasons.code"
              v-show="seasons.code > 2017"
              >{{ seasons.desc }}</b-dropdown-item
            >
        </b-dropdown>
        <b-row>
            <!-- This is for schedule -->
            <b-col col="12" lg="12" md="12" v-for="(value, index) in scheduleTable" :key="index">
                <div v-if="value.class_group == 'PRE' || value.class_group == 'QSC'">
                    <div class="playerprofile-blockList">
                        <h3 class="tour-school-tag">{{value.short_name}}</h3>
                    </div>
                    <table class="table table-striped tourschool-table">
                        <thead>
                            <tr class="PlayerProfile-title">
                                <th class="">Venue</th>
                                <th class="">Rounds 1 - {{value.rounds}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="playerslistodd">					
                                <td class="table-pos courseNameTable">
                                    <span class="pos-oom">{{value.course}}</span>
                                </td>
                                <td>
                                    {{value.dates}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-col>
        </b-row>
        <b-row class="qschoolEnter">
            <b-col col="12" lg="4" md="12">
                <a :href="pageData.acf.imageonelink" target="_blank">
                    <img :src="pageData.acf.imageone">
                </a>
            </b-col>
            <b-col col="12" lg="4" md="12">
                <a :href="pageData.acf.imagetwolink" target="_blank">
                    <img :src="pageData.acf.imagetwo">
                </a>
            </b-col>
            <b-col col="12" lg="4" md="12">
                <a :href="pageData.acf.imagethreelink" target="_blank">
                    <img :src="pageData.acf.imagethree">
                </a>
            </b-col>
        </b-row>
        <b-row class="qschoolEnter">
            <b-col col="12" lg="12" md="12">
                <p v-html="pageData.acf.all_comps"></p>
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "q-school",
  props:['config', 'season'],
  data() {
    return {
        Year: this.$route.query.id,
        pageData: [],
        scheduleData: [],
        scheduleTable: [],
        newdata: [],
        seasons: [],
        cache: ''
    };
  },
  mounted() {
    axios //add API Call
      .get(
        process.env.VUE_APP_WPAPI_URL+"wp/v2/pages/" +
          '136' + "?randomadd=" +
              new Date().getTime()
      ) // Page number changed on each page based on JSON page id you are calling
      .then((response) => {
        this.pageData = response.data;
        if (this.Year > '2020') {
            return axios.get("https://api.euro.ocs-software.com/let/cache/qsc/" + this.Year  + "/tmticx?randomadd=" +
                new Date().getTime()
            );   
        } else {
            return axios.get(process.env.VUE_APP_TIC_BASE + this.Year  + "/tmticx?randomadd=" +
                new Date().getTime()
            );  
        }
      })
      .then((response) => {
        this.scheduleData = response.data;
        this.scheduleTable = response.data.tournaments.tournaments_entry;
        console.log("this.scheduleTable")
        console.log(this.scheduleTable)
        return axios.get(
          process.env.VUE_APP_TIC_BASE+"tmticx?field=Y&fields=seasons&fields=tm_params.season_code&randomadd=" +
            new Date().getTime()
        );
      })
      .then((response) => {
        this.seasons = response.data;
        this.newdata = response.data;
      })
  },
  methods: {
    changeYear: function(event) {
      var id = event.target.getAttribute("title");
        (this.Year = id)
        if (this.Year > '2020') {
            this.cache = 'qsc'
            console.log("this.cache")
            console.log(this.cache)
        } else {
            this.cache = 'let'
            console.log("this.cache")
            console.log(this.cache)
        }
      return (
        axios
          .get("https://api.euro.ocs-software.com/let/cache/" + this.cache + "/" + this.Year  + "/tmticx?randomadd=" +
            new Date().getTime()
          )
          .then((response) => {
            this.scheduleTable = response.data.tournaments.tournaments_entry;
          })
      );
    },
  }
};
</script>

<style scoped>
::v-deep .btn-secondary:hover {
    color: #95c93d;
    background-color: transparent;
    border-color: #545b62;
}
.paddingBlock {
    padding-left: 18px;
    padding-right: 18px;
    margin-top: 100px;
    margin-bottom: 50px;
}
span.textBlock {
    text-align: center;
    color: #212529;
    font-size: 14px;
    line-height: 1.4!important;
    display: block;
    margin-top: 20px;
    font-family: arial;
}
::v-deep .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    float: right;
    top: 9px;
    position: relative;
}
::v-deep .dropdown {
    width: 300px;
    /* float: right; */
}
::v-deep button {
    color: white;
    background-color: #95c93d;
    border-radius: 0;
    text-align: left;
    padding: 10px;
}
#apple {
    background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/ASB.png');
    height: 75px;
    z-index: 9999;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#apple:hover {
    background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/ASW.png');
}
#google {
    background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/GPB.png');
    height: 75px;
    z-index: 9999;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
#google:hover {
    background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/alpstourgolf/2022/08/GPW.png');
}
.q-schoolApp-block {
    text-align: center;
    /* padding-right: 25px; */
    padding-right: 40px;
    padding-top: 15px;
}
span.qschoolYear {
    font-weight: bold;
}
.EnterNow {
    color: #fff;
    margin: 20px;
    font-size: 15pt;
    padding-left: 25px;
}
.q-school-app {
    background-color: #0056bb;
    padding-top: 15px;
    padding-bottom: 20px;
}
.topRow {
    margin-bottom: 30px;
    width: 780px;
    margin: auto;
}
::v-deep button#__BVID__113__BV_toggle_ {
    width: 300px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #95c93d;
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
}
::v-deep button#__BVID__1067__BV_toggle_ {
    width: 300px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #95c93d;
    border: none;
    border-radius: 0;
    padding: 10px;
    color: #fff;
    text-transform: uppercase;
}
td.courseNameTable {
    width: 50%;
}
.PlayerProfile-title > th {
    font-weight: 400;
    border-top: 0px solid #dee2e6;
}
span.pos-oom {
    margin-left: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: #ede9f3;
}
table.tourschool-table {
    text-transform: capitalize;
    margin-top: -1px;
    margin-bottom: 0;
}
.PlayerProfile-title {
    background-color: #7ba639;
    color: #fff;
}
.playerprofile-blockList {
    background-color: #95c93d;
    padding: 0 0 0 10px;
    margin-top: 3px;
    color: #fff;
}
h3.tour-school-tag {
    color: #fff;
    padding: 15px 0 15px 0;
    font-size: 13pt;
    margin-top: 0;
    margin-bottom: 0;
}
::v-deep img.size-full.wp-image-175195 {
    height: 300px;
}
::v-deep  img.size-medium.wp-image-174810.alignnone {
    width: 300px;
}
::v-deep  img.wp-image-174805.alignleft {
    width: 300px;
}
::v-deep  img.size-medium.wp-image-174806.alignright {
    width: 300px;
}
::v-deep  img.alignnone.size-medium.wp-image-174803 {
    width: 300px;
}
::v-deep  img.alignnone.size-medium.wp-image-174801 {
    width: 300px;
}
.qschoolEnter {
    padding-top: 50px;
    padding-bottom: 50px;
}
::v-deep img{
  width: 100%;
}
</style>
