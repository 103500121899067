<template>
  <div >
      <b-card>
        <b-row class="infoBlock">
            <b-col>
                <div class="inforBreak">
                <b-img
                    class="tLogo"
                    :src="
                    'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2021/03/titleist_oom-copy.png'
                    "
                ></b-img
                ><span> Indicates Golf Ball Usage</span>
                </div>
            </b-col>
            <b-col>
                <div class="SecondLine">* Player did not start from 1st hole</div>
            </b-col>
            <b-col>
                <div class="SecondLine">(a) = Amateur</div>
            </b-col>
        </b-row>

        <div class="inforBreak">
            <b-row class="inforHoleWidth">
                <b-col>
                    <div class="hbhLink"><span class="multicourseDot vspar-albatross">•</span> Albatross</div>
                </b-col>
                <b-col>
                    <div class="hbhLink"><span class="multicourseDot vspar-eagle">•</span> Eagle</div>
                </b-col>
                <b-col>
                    <div class="hbhLink"><span class="multicourseDot vspar-birdie">•</span> Birdie</div>
                </b-col>
                <b-col>
                    <div class="hbhLink"><span class="multicourseDot vspar-bogey">•</span> Bogey</div>
                </b-col>
                <b-col>
                    <div class="hbhLink"><span class="multicourseDot vspar-dbogey">•</span> D. Bogey+</div>
                </b-col>
            </b-row>
        </div>
        <div class="inforBreak" v-if="showGender == 'Y'">
            <div>
                <div class="mixedEvent">Mixed Event: </div>
                <span class="multiDot-size-reportgender">
                    Female<span class="female">•</span>
                </span>
                <span class="multiDot-size-reportgender">
                    Male <span class="male">•</span>
                </span>
            </div>
        </div>
        <div class="infoBreak" v-if="data.multi_course == 'Y'">
            <span class="course">Courses:</span>
            <span
                class="multiList"
                v-for="(multi, index) in data.courses.courses_entry"
                :key="index"
            >
                <span :class="multi.course_X_colour">•</span>
                <span class="multiName"> 
                    <span class="nameMulti" :class="multi.course_X_colour"> = {{ multi.course_X_id }}</span>
                </span>
            </span>
        </div>
        <div class="infoBreak" v-if="rts == 'S'">
            <span class="strokesColour"> 1,2,3 ect <span class="normalText">Shots played on current hole</span></span>
        </div>
      </b-card>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  name: "Information",
  props: [
    "data",
    "previousData",
    "home",
    "title",
    "report",
    "code",
    "season",
    "takeover",
    "config",
    "team",
    "match",
    "live",
    "RTCDS",
    "bbTeamEvent",
    "roundsplayed",
    "showGender",
    "Stableford",
    "rts"
  ],
  data() {
    return {
    };
  },
};
</script>

<style scoped>
.normalText {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin-left: 20px;
}
.strokesColour {
    color: #3c9933 !important;
    font-weight: bold;
    font-style: italic; 
    font-size: 1rem;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    padding-top: 30px;
}
span.multiName {
    font-size: 13pt !important;
    font-weight: 400;
}
.course {
    font-size: 13pt;
    font-weight: 400;
}
span.nameMulti {
    font-size: 13pt !important;
    margin-top: -4px;
}
.male {
    color: #037;
    font-size: 30px!important;
    position: relative;
    top: 7px;
}
.female {
    color: #e33;
    font-size: 30px!important;
    position: relative;
    top: 7px;
}
.mixedEvent {
    margin-bottom: -10px;
}
.inforHoleWidth {
    width: 50%;
}
.vspar-albatross {
    color: #fbbc04;
}
.vspar-eagle {
    color: #3c9933;
}
.vspar-birdie {
    color: #a52a2a;
}
.vspar-bogey {
    color: #1e90ff;
}
.vspar-dbogey {
    color: #e07020;
}
.multicourseDot {
    font-size: 30px!important;
    position: relative;
    top: 4px;
}
.inforBreak {
    margin-bottom: 15px;
}
.SecondLine {
    margin-bottom: 0px;
}
.tLogo {
    width: 65px;
    margin-top: -5px;
    margin-right: 15px;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: rgba(0,0,0,.03)!important;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
    color: #000;
    font-size: 1rem;
}
@media only screen and (max-width: 480px) {
    .inforHoleWidth {
        width: 100%;
    }
    .hbhLink {
        width: 45px;
    }
}
</style>