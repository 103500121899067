var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.$route.name === 'Tournament' || this.$route.name === 'home' || this.$route.name === 'team-europe')?_c('div',{class:{'flickrTourn': this.$route.name === 'Tournament'},attrs:{"id":"flickr"}},[_c('b-container',[_c('b-row',[_c('b-col',[(this.$route.name === 'Tournament')?_c('b-button',{staticClass:"allNews",class:{'flickrTourn-Only': this.$route.name === 'Tournament'}},[_vm._v("See All Images")]):_vm._e(),_c('b-link',{attrs:{"href":"https://www.flickr.com/photos/letaccessseries/","target":"_blank"}},[(this.$route.name === 'team-europe' || this.$route.name === 'home')?_c('b-button',{staticClass:"euroNews",class:{seePhotosHome : this.$route.name === 'home'}},[_vm._v("See All Photos ")]):_vm._e()],1)],1)],1),(this.$route.name === 'Tournament')?_c('b-row',_vm._l((_vm.flickr.photos.photo.slice().reverse()),function(photo,index){return _c('b-col',{key:index,staticClass:"imgColTourn flickrImage-tourn",attrs:{"lg":"2"}},[_c('a',{attrs:{"href":'https://www.flickr.com/photos/'+_vm.config.VUE_APP_FLICKR_NAME+ '/' + photo.id,"target":"_blank"}},[_c('b-img',{staticClass:"fImg",attrs:{"src":'https://farm' +
                photo.farm +
                '.staticflickr.com/' +
                photo.server +
                '/' +
                photo.id +
                '_' +
                photo.secret +
                '.jpg'}})],1)])}),1):_vm._e(),(this.$route.name === 'home')?_c('b-row',{staticClass:"homeFlicker"},_vm._l((_vm.flickr.photos.photo),function(photo,index){return _c('b-col',{key:index,staticClass:"imgCol",attrs:{"lg":"4"}},[_c('a',{attrs:{"href":'https://www.flickr.com/photos/'+_vm.config.VUE_APP_FLICKR_NAME+'/' + photo.id,"target":"_blank"}},[_c('b-img',{staticClass:"fImg",attrs:{"src":'https://farm' +
                photo.farm +
                '.staticflickr.com/' +
                photo.server +
                '/' +
                photo.id +
                '_' +
                photo.secret +
                '.jpg'}})],1)])}),1):_vm._e(),(this.$route.name === 'team-europe')?_c('b-row',_vm._l((_vm.flickr.photoset.photo),function(photo,index){return _c('b-col',{key:index,staticClass:"imgCol",attrs:{"lg":"4"}},[_c('div',{staticClass:"outerEuro"},[_c('a',{attrs:{"href":'https://www.flickr.com/photos/'+_vm.config.VUE_APP_FLICKR_NAME+'/' + photo.id,"target":"_blank"}},[_c('b-img',{staticClass:"fImg",attrs:{"src":'https://farm' +
                photo.farm +
                '.staticflickr.com/' +
                photo.server +
                '/' +
                photo.id +
                '_' +
                photo.secret +
                '.jpg'}})],1)])])}),1):_vm._e()],1)],1):_vm._e(),(this.$route.name === 'player-profiles')?_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"12"}},[(_vm.flickr.photos.photo[0].url_z.length)?[_c('img',{staticClass:"FlickrMain-image",attrs:{"src":_vm.flickr.photos.photo[0].url_z}})]:[_c('img',{staticClass:"FlickrMain-image",attrs:{"src":'https://ocs-sport.ams3.cdn.digitaloceanspaces.com/let_new/2020/05/largelogo-newsIndiviudel.png'}})]],2),_c('b-col',[_c('b-row',_vm._l((_vm.flickr.photos.photo.slice(0,3)),function(photo,index){return _c('b-col',{key:index,staticClass:"flickrouter",attrs:{"lg":"4"}},[_c('img',{staticClass:"FlickrMini-image playerFlickr",attrs:{"src":photo.url_z},on:{"click":function($event){return _vm.$bvModal.show(photo.url_z)}}}),_c('b-modal',{attrs:{"id":photo.url_z,"title":"BootstrapVue","hide-header":"","centered":"","hide-footer":""}},[_c('img',{staticClass:"FlickrMini-image playerFlickrModal",attrs:{"src":photo.url_z}}),_c('h3',{staticClass:"photoText"},[_vm._v(_vm._s(photo.title))])])],1)}),1),_c('b-row',{staticClass:"firstRow-flickrPlayer"},_vm._l((_vm.flickr.photos.photo.slice(3,6)),function(photo,index){return _c('b-col',{key:index,staticClass:"flickrouter",attrs:{"lg":"4"}},[_c('img',{staticClass:"FlickrMini-image playerFlickr",attrs:{"src":photo.url_z},on:{"click":function($event){return _vm.$bvModal.show(photo.url_z)}}}),_c('b-modal',{attrs:{"id":photo.url_z,"title":"BootstrapVue","hide-header":"","centered":"","hide-footer":""}},[_c('img',{staticClass:"FlickrMini-image playerFlickrModal",attrs:{"src":photo.url_z}}),_c('h3',{staticClass:"photoText"},[_vm._v(_vm._s(photo.title))])])],1)}),1)],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }